/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, Grid, TextField } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const ComoPensionado = ({ setPensionado, clienteInfo }) => {
  const classes = {};

  const handleDatosChange = (e) => {
    setPensionado((anterior) => ({
      ...anterior,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFechaResolucion = (e) => {
    setPensionado((anterior) => ({
      ...anterior,
      fecha_resolucion_pension: e,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Fondo Pensional"
            variant="filled"
            name="fondoPensional"
            value={clienteInfo?.fondoPensional}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Causa de la Pension"
            variant="filled"
            name="causaPension"
            value={clienteInfo?.causaPension}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl className={classes.margin} variant="filled">
          <div className={classes.date}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fecha de Resolución"
                  name="fecha_resolucion_pension"
                  value={clienteInfo?.fecha_resolucion_pension}
                  onChange={handleFechaResolucion}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </LocalizationProvider>
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ComoPensionado;
