import { Grid } from '@mui/material';
import React from 'react';

const Elegirnos = () => (
  <Grid container item xs={12} id="porqueelegirnos" className="contenedor_porqueelegirnos">
    <div>
      <h1 className="titulo-porque-elegirnos">¿Por qué elegirnos?</h1>

      <div className="opciones">
        <Grid container>
          <Grid item xs={12} md={6} className="opcion-item">
            <div>
              <h1>Préstamos Rápidos</h1>
              <p>100%</p>
            </div>
          </Grid>

          <Grid item xs={12} md={6} className="opcion-item">
            <div>
              <h1>Experiencia</h1>
              <p>100%</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className="opcion-item">
            <div>
              <h1>Transparencia en todos los procesos</h1>
              <p>100%</p>
            </div>
          </Grid>

          <Grid item xs={12} md={6} className="opcion-item">
            <div>
              <h1>Plataformas Seguras (Certificado SSL)</h1>
              <p>100%</p>
            </div>
          </Grid>

          <Grid item xs={12} md={6} className="opcion-item">
            <div>
              <h1>Atención Financiera sin larga filas</h1>
              <p>100%</p>
            </div>
          </Grid>

          <Grid item xs={12} md={6} className="opcion-item">
            <div>
              <h1>Rapido y Confiable</h1>
              <p>100%</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  </Grid>
);

export default Elegirnos;
