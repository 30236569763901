import { createTheme } from '@mui/material/styles';

const MuiDatatablesTextLabels = createTheme({
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        '&:nth-child(odd)': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
    MUIDataTableToolbar: {
      root: {
        backgroundColor: '#e0e0e0',
      },
    },
  },
  pagination: {
    next: 'Siguiente',
    previous: 'Anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver columnas',
    filterTable: 'Filtrar tabla',
  },
  filter: {
    all: 'Todos',
    title: 'FILTROS',
    reset: 'RESET',
  },
  viewColumns: {
    title: 'Mostrar columnas',
    titleAria: 'Mostrar/Ocultar columnas',
  },
  selectedRows: {
    text: 'fila(s) seleccionada(s)',
    delete: 'Eliminar',
    deleteAria: 'Eliminar filas seleccionadas',
  },
});

export default MuiDatatablesTextLabels;
