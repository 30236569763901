import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import { IconButton, Tooltip } from '@mui/material';
import { MAIN_PATH, PATH_NAME, TITLES } from '../constants';

import TableList from '../components/table-list';
import { coleccionCreditosActivos } from '../helper/coleccionCreditos';
import Iconify from '../components/iconify/Iconify';
import { cargarCreditoAEditar } from '../store';

export function GestorCreditos() {
  const location = useLocation();
  const isGestorCreditos = location?.pathname.includes(PATH_NAME.gestor_de_creditos);

  const { loading, creditos } = useSelector((state) => state.creditosActivos);

  const data = coleccionCreditosActivos(creditos);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getColor = (value) => {
    if (value === 'Desembolsado') {
      return 'success';
    }

    if (value === 'En Estudio') {
      return 'primary';
    }

    if (value === 'Pendiente Firma') {
      return 'warning';
    }

    if (value === 'Pendiente Desembolso' || value === 'cancelado') {
      return 'error';
    }

    if (value === 'Pendiente por Aprobar') {
      return 'secondary';
    }
    return 'default';
  };

  const redireccionarEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: false,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: false,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: false,
      },
    },
    {
      label: 'Monto Aprobado',
      name: 'valorSolicitado',
      options: {
        filter: false,
      },
    },
    {
      label: 'Estado',
      name: 'estado',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Chip
            label={value}
            color={getColor(value)}
            variant={
              value === 'Desembolsado' || value === 'Cancelado' || value === 'Pendiente Desembolso'
                ? 'filled'
                : 'outlined'
            }
          />
        ),
      },
    },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (_value, tableMeta) => (
          <>
            <Tooltip title="Gestionar" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Copiar y Pegar">
            <IconButton aria-label="Gestionar" onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1])}>
              <Iconify icon="mdi-light:clipboard-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title> {isGestorCreditos ? TITLES.gestor_de_creditos : TITLES.default} </title>
      </Helmet>

      <TableList titulo="Lista de Créditos Activos" columnas={columnas} data={data} loading={loading} />
    </>
  );
}
