import React from 'react';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import DraftsIcon from '@mui/icons-material/Drafts';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Contacto = () => (
  <section className="titulo_InformacióndeContacto" id="contacto">
    <h1>Información de Contacto</h1>
    <ul>
      <li>
        <span>
          <AddLocationIcon className="iconoamarillo" fontSize="large" />
          <strong className="tituloinformacioncontacto">Dirección:</strong>
          Calle 44 # 79-86 Of 207
        </span>
      </li>
      <li>
        <span>
          <PhoneInTalkIcon className="iconoamarillo" fontSize="large" />
          <strong className="tituloinformacioncontacto">Teléfono:</strong>
          317 365 87 87
        </span>
      </li>
      <li>
        <span>
          <DraftsIcon className="iconoamarillo" fontSize="large" />
          <strong className="tituloinformacioncontacto">Correo:</strong>
          ayuda@easycreditcolombia.com
        </span>
      </li>
      <li>
        <span>
          <FacebookIcon className="iconoamarillo" fontSize="large" />
          <strong className="tituloinformacioncontacto">Facebook:</strong>
          <a href="https://www.facebook.com/easycreditcolombia" target="_blank" rel="noreferrer">
            easycreditcolombia
          </a>
        </span>
      </li>
      <li>
        <span>
          <InstagramIcon className="iconoamarillo" fontSize="large" />

          <strong className="tituloinformacioncontacto">Instagram:</strong>
          <a href="https://www.instagram.com/easycreditcolombia/?utm_medium=copy_link" target="_blank" rel="noreferrer">
            @easycreditcolombia
          </a>
        </span>
      </li>
    </ul>
  </section>
);

export default Contacto;
