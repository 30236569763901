/* eslint-disable react/prop-types */
import { FormControl, Grid, TextField } from '@mui/material';
import React from 'react';

const ComoIndependiente = ({ setIndependiente, clienteInfo }) => {
  const classes = {};

  const handleDatosChange = (e) => {
    setIndependiente({
      ...clienteInfo,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Nombres Comercial"
            variant="filled"
            name="nombre_empresa_independiente"
            value={clienteInfo?.nombre_empresa_independiente}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Ciudad de actividad Comercial"
            variant="filled"
            name="ciudad_empresa_independiente"
            value={clienteInfo?.ciudad_empresa_independiente}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Direccion Comercial"
            variant="filled"
            name="direccion_empresa_independiente"
            value={clienteInfo?.direccion_empresa_independiente}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Telefono Comercial"
            variant="filled"
            name="telefono_empresa_independiente"
            value={clienteInfo?.telefono_empresa_independiente}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Actividad Economica"
            variant="filled"
            name="actividad_economica"
            value={clienteInfo?.actividad_economica}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ComoIndependiente;
