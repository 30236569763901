import { Route, Routes } from 'react-router-dom';
// import { PrivateRoute } from './PrivateRoute';
// import AdminRoutes from './AdminRoutes';
import { ClientRoute } from './filtradores/client/ClientRoute';
import ClientRoutes from './rutas/client/ClientRoutes.routes';
import { PublicRoute } from './filtradores/client/PublicRoute';
import ClientPublicRoutes from './rutas/client/ClientPublicRoutes.routes';
import { PrivateRoute } from './filtradores/admin/PrivateRoute';
import AdminRoutes from './rutas/admin/AdminRoutes';
import { PublicAdminRoute } from './filtradores/admin/PublicAdminRoute';
import { PATH_PUBLIC } from '../constants';
import AdminPublicRoutes from './rutas/admin/AdminPublicRoutes';
import Web from '../web/Web';

export const AppRouter = () => (
  <Routes>
    <Route path="/" element={<Web />} />

    <Route
      path={`/${PATH_PUBLIC}/*`}
      element={
        <PublicAdminRoute>
          <AdminPublicRoutes />
        </PublicAdminRoute>
      }
    />

    <Route
      path="/easy-auth/*"
      element={
        <PublicRoute>
          <ClientPublicRoutes />
        </PublicRoute>
      }
    />
    <Route
      path="/admin/*"
      element={
        <PrivateRoute>
          <AdminRoutes />
        </PrivateRoute>
      }
    />
    <Route
      path="/cliente/*"
      element={
        <ClientRoute>
          <ClientRoutes />
        </ClientRoute>
      }
    />
  </Routes>
);
