import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creditos: [],
  creditosVencidos: {
    loading: true,
    vencidos: [],
  },
  creditoEditable: null,
  loading: true,
  compromisosRealizados: {
    compromisos: [],
    loading: true,
  },
  loadingEditar: false,
  facturas: [],
  loadingClarisa: false
};

export const creditosActivosSlice = createSlice({
  name: 'creditosActivos',
  initialState,
  reducers: {
    cargarCreditos: (state, action) => {
      state.creditos = action.payload;
      state.loading = false;
    },
    cargarCreditosDesembolsados: (state, action) => {
      state.creditosVencidos.vencidos = action.payload;
      state.creditosVencidos.loading = false;
    },
    cargarCompromisos: (state, action) => {
      state.compromisosRealizados.compromisos = action.payload;
      state.compromisosRealizados.loading = false;
    },
    cargarFacturas: (state, action) => {
      state.facturas = action.payload;
      state.compromisosRealizados.loading = false;
    },
    cargarCreditoAEditar: (state, action) => {
      state.creditoEditable = action.payload;
    },
    loadingCreditoAEditar: (state, action) => {
      state.loadingEditar = action.payload;
    },
    loadingClarisa: (state, action) => {
      state.loadingClarisa = action.payload;
    },
    actualizarCompromisos: (state, action) => {
      state.creditoEditable.compromisoCreditos = [...state.creditoEditable.compromisoCreditos, action.payload];
    },

    cargarObservacionCredito: (state, action) => {
      state.creditoEditable.notaCreditos = [...state.creditoEditable.notaCreditos, action.payload.nota];
    },
    limpiarCreditoAEditar: (state) => {
      state.creditoEditable = null;
    },
    
  },
});

export const {
  cargarCreditos,
  cargarCreditosDesembolsados,
  cargarCreditoAEditar,
  cargarCompromisos,
  loadingCreditoAEditar,
  limpiarCreditoAEditar,
  cargarObservacionCredito,
  actualizarCompromisos,
  cargarFacturas,
  loadingClarisa
} = creditosActivosSlice.actions;
