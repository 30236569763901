import clienteAxios from '../../helper/axios';
import { cargarFacturas } from './facturasSlice';

export const startCargarFacturas = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get(`/admin/facturas`, {});

    dispatch(cargarFacturas(respuesta.data));
  } catch (error) {
    console.error(error);
  }
};
