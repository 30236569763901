/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import Tooltip from '@mui/material/Tooltip';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GavelIcon from '@mui/icons-material/Gavel';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  ACCESOS_GESTOR_CANCELACION_MANUAL,
  ACCESOS_GESTOR_DATOS,
  ACCESOS_GESTOR_RENOVACIONES,
} from '../../constants/permisos';
import { cargarClienteAEditar } from '../../store/clientes';
import { MAIN_PATH, PATH_NAME } from '../../constants';

export const SideBar = ({ isDesembolsado, showNotas = true, showContrato = true, showRenovarContrato = true }) => {
  const dispatch = useDispatch();
  const { rol } = useSelector((state) => state.userAdmin.userAdmin);
  const cliente = useSelector((state) => state?.creditosActivos?.creditoEditable?.cuentaUsuario);
  const clienteInfo = useSelector((state) => state?.clientes?.clientes) || [];

  React.useEffect(() => {
    const data = clienteInfo?.filter((infoPersonal) => infoPersonal?.id === cliente?.id)?.[0];
    dispatch(cargarClienteAEditar(data));
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={1}
      sx={{
        backgroundColor: 'rgba(25, 118, 209, 0.2)',
        borderRadius: 24,
        height: 400,
        padding: '10px',
        position: 'fixed',
        right: '28px',
        top: 200,
        width: 46,
        zIndex: 99,
      }}
    >
      <Tooltip title="Ver Cliente" arrow placement={'left'}>
        <IconButton
          disabled={!ACCESOS_GESTOR_DATOS.includes(rol)}
          component={Link}
          to={`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_datos_cliente}`}
        >
          <AccountBoxIcon fontSize="inherit" size="large" color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Notas de Usuario" arrow placement={'left'}>
        <IconButton component={Link} to={`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_notas_usuario}`}>
          <NewspaperIcon fontSize="inherit" size="large" color="primary" />
        </IconButton>
      </Tooltip>
      {showNotas && (
        <Tooltip title="Notas de Crédito" arrow placement={'left'}>
          <IconButton component={Link} to={`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_notas_credito}`}>
            <FormatListNumberedIcon fontSize="inherit" size="large" color="primary" />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="WhatsApp" arrow placement={'left'}>
        <IconButton>
          <WhatsAppIcon
            fontSize="inherit"
            size="large"
            color="primary"
            onClick={() => window.open(`https://web.whatsapp.com/send?phone=57${cliente?.celular}`)}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Historial Cliente" arrow placement={'left'}>
        <IconButton component={Link} to={`/admin/${MAIN_PATH}/${PATH_NAME.historico_cliente}`}>
          <LibraryBooksIcon fontSize="inherit" size="large" color="primary" />
        </IconButton>
      </Tooltip>
      {showContrato && (
        <Tooltip title="Contrato del Crédito" arrow placement={'left'}>
          <IconButton component={Link} to={`/admin/${MAIN_PATH}/${PATH_NAME.contrato_credito}`}>
            <GavelIcon fontSize="inherit" size="large" color="primary" />
          </IconButton>
        </Tooltip>
      )}
      {showRenovarContrato && (
        <Tooltip title="Renovar Crédito" arrow placement={'left'}>
          <IconButton
            component={Link}
            to={`/admin/${MAIN_PATH}/${PATH_NAME.renovar_credito}`}
            disabled={!ACCESOS_GESTOR_RENOVACIONES.includes(rol) || isDesembolsado === null}
          >
            <AutorenewIcon fontSize="inherit" size="large" color="primary" />
          </IconButton>
        </Tooltip>
      )}

      {showRenovarContrato && (
        <Tooltip title="Cancelación Manual" arrow placement={'left'}>
          <IconButton
            component={Link}
            to={`/admin/${MAIN_PATH}/${PATH_NAME.cancelar_credito}`}
            disabled={!ACCESOS_GESTOR_CANCELACION_MANUAL.includes(rol) || isDesembolsado === null}
          >
            <AppRegistrationIcon fontSize="inherit" size="large" color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};
