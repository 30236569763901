/* eslint-disable react/jsx-no-bind */
import { Alert, Box, Button, Card, FormControl, Grid, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { calculosDelCredito } from '../../helper/liquidarCredito';
import { formateador } from '../../helper/Formateador';
import { renovarCredito } from '../../store';
import { hoy, sumarDiasAFecha } from '../../helper/funtions';

const DetallePagoRenovacion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClick() {
    navigate(-1);
  }

  const credito = useSelector((state) => state.creditosActivos.creditoEditable);

  const { valorAdministracion, valorPlataforma, valorIva, interesCorriente, valorTotalPagoRenovacion } =
    calculosDelCredito(credito);

  const efectuarPago = (e) => {
    e.preventDefault();

    Swal.fire({
      title: '¿Esta seguro de renovar este crédito?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Renuevalo!',
      cancelButtonText: 'No, Cancelar',
    }).then(() => {
      // editarCredito(credito?.id, {
      //   ...credito,
      //   plataforma: valorPlataforma,
      //   interes_Corriente: interesCorriente,
      //   administracion: valorAdministracion,
      //   iva: valorIva,
      //   solicitud_Renovacion: true,
      //   valor_Cancelado_Capital: null,
      // })
      dispatch(
        renovarCredito({
          credito_renovado: true,
          administracion_renovado: valorAdministracion,
          administracion: null,
          creditoId: credito.id,
          dias_Prestamo_renovado: credito.dias_Prestamo,
          dias_Prestamo: 30,
          fecha_Cancelado: null,
          fecha_Desembolsado_renovado: credito.fecha_Desembolsado,
          fecha_Desembolsado: hoy,
          fecha_Limite_Pago: sumarDiasAFecha(hoy, 30),
          id: credito.id,
          interes_Corriente_renovado: interesCorriente,
          interes_Corriente: null,
          iva_renovado: valorIva,
          iva: null,
          paraRenovar: true,
          plataforma_renovado: valorPlataforma,
          plataforma: null,
          solicitud_Renovacion: null,
          valor_Cancelado_Capital: null,
          cuentaUsuario: {
            usuarioId: credito.cuentaUsuario.id,
          },
        })
      );
    });
  };

 
  return (
    <>
      {credito?.fecha_Cancelado !== null && credito?.cancelado === null && (
        <Alert severity="warning">Tu pago esta siendo verificado.</Alert>
      )}
      <form onSubmit={efectuarPago}>
        <Grid container>
          <FormControl sx={{ mx: 'auto' }}>
            <Grid container sx={{ display: 'flex' }}>
              <Grid sx={{ margin: 2 }}>
                <Box>
                  <Card sx={{ padding: 3, lineHeight: 'normal', fontSize: 12 }}>
                    <h4>Detalle Pago:</h4>

                    <p>Administración: $ {formateador(valorAdministracion)}</p>
                    <p>Plataforma: $ {formateador(valorPlataforma)}</p>
                    <p>IVA: $ {formateador(valorIva)}</p>
                    <p>Intereses: $ {formateador(interesCorriente)}</p>
                    <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                      Total: $ {formateador(valorTotalPagoRenovacion)}
                    </Typography>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: 3,
          }}
        >
          <Button
            variant="contained"
            color="warning"
            sx={{ marginRight: 3, display: 'flex', justifyContent: 'flex-end' }}
            onClick={handleClick}
            startIcon={<ReplyIcon />}
          >
            Atras
          </Button>
          <Button variant="contained" color="primary" sx={{ marginRight: 4 }} type="submit">
            Renovar crédito
          </Button>
        </Box>
      </form>
    </>
  );
};

export default DetallePagoRenovacion;
