import React from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';

import { useSelector } from 'react-redux';

import moment from 'moment/moment';
import { hoy, sumarDiasAFecha } from '../../helper/funtions';

import { formateador } from '../../helper/Formateador';
import logo from '../../components/logo/logoColor.png';
import DetallePagoRenovacion from './DetallePagoRenovacion';

export default function RenovarCreditoDelCliente() {
  const credito = useSelector((state) => state?.creditosActivos?.creditoEditable);
  const infoPersonal = useSelector((state) => state?.creditosActivos?.creditoEditable?.cuentaUsuario);

  return (
    <Grid container>
      <Card className="rootLiquidar">
        <CardContent>
          <div className="Header">
            <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <img src={logo} alt="Logo Easy Credit" className="logo" />
            </Grid>

            <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <div className="subHeader">
                <p className="p">EASY CREDIT COLOMBIA</p>
                <p className="p">Calle 44 # 79-86 of 207</p>
                <p className="p">Nit: 901.011.172 </p>
              </div>
            </Grid>

            <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <div className="subHeader">
                <p className="p"> Medellin, Antioquía, Colombia</p>
                <strong>
                  <p className="p">Credito: C{credito?.id}</p>
                </strong>
              </div>
            </Grid>
          </div>

          <Typography
            sx={{
              backgroundColor: '#3498db',
              borderRadius: 1,
              color: 'white',
              width: '200px',
              height: '40px',
              position: 'absolute',
              top: '-31px',
              right: '-92px',
              transform: 'rotate(45deg)',
              transformOrigin: '0% 0%',
              textAlign: 'center',
              lineHeight: '50px',
              boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.3)',
            }}
          >
            Renovación
          </Typography>

          <Divider
            variant="middle"
            className="espacio"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block', sx: 'block' },
            }}
          />

          <Grid container className="contenedorSubtitulo">
            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">
                  Nombre:
                  {` ${infoPersonal?.primerNombre} ${infoPersonal?.primerApellido}`}
                </p>
                <p className="p"> Cedula: {formateador(infoPersonal?.cedula)}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">Monto: $ {formateador(credito?.valor_Aprobado)}</p>
                <p className="p">
                  Plazo Crédito: {formateador(credito?.dias_Prestamo)} {credito?.dias_Prestamo === 1 ? 'día' : 'Dias'}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">
                  Fecha Desembolso:
                  {` ${moment(credito?.fecha_Desembolsado).format('l')}`}
                </p>
                <p className="p">
                  Fecha Limite de Pago actual:
                  {` ${moment(credito?.fecha_Limite_Pago).format('l')}`}
                </p>
                <p className="p">
                  Nueva Fecha Limite de Pago:
                  {` ${moment(sumarDiasAFecha(hoy, 30)).format('l')}`}
                </p>
              </div>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            className="espacio"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block', sx: 'block' },
            }}
          />

          <Grid container className="contenedor">
            <DetallePagoRenovacion />
          </Grid>
        </CardContent>

        <Divider variant="middle" className="espacio" />
        <Grid container>
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
            <CardContent className="contenedorfooter">
              <div className="subFooter">
                <p className="pPrestamo">IVA Régimen Común:</p>
                <p className="pPrestamoDescripcion">
                  Resolución de Facturación 18764033523899, desde 3,645 hasta 5,000:
                </p>
                <p className="pPrestamoDescripcion">No somos grandes contribuyentes:</p>
                <p className="pPrestamoDescripcion">No somos autorretenedores de IVA:</p>
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
