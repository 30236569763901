/* eslint-disable import/order */
import '../Style/WebStyle.css';

import { Box, Button, Grid, Hidden } from '@mui/material';

import Calculadora from '../../client/components/calculadora/Calculadora';
import { Link } from 'react-router-dom';
import React from 'react';
import slide from '../../assets/images/slide.png';

const Inicio = () => (
  <>
    <Grid item xs={12} md={5}>
      <section className="calculadora" id="inicio">
        <Calculadora showSolicitar={false} />
        <Hidden>
          <Grid item xs={12} sx={{ width: '70%', margin: '0 auto' }}>
            <Box className="buttons">
              <Button
                variant="contained"
                sx={{ width: '40%', textAlign: 'center', padding: 2 }}
                color="primary"
                component={Link}
                className="buttonMU"
                to="/easy-auth/login"
              >
                Iniciar Sesión
              </Button>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                className="buttonMU"
                to="/easy-auth/registro"
                sx={{ width: '40%', padding: 2 }}
              >
                Regístrate
              </Button>
            </Box>
          </Grid>
        </Hidden>
      </section>
    </Grid>
    <Hidden only={['xs']}>
      <Grid item md={7} xs={12} className="modelocalculadora d-block w-100">
        <img
          src={slide}
          alt="Logo"
          style={{
            marginTop: 229,
          }}
        />
      </Grid>
    </Hidden>
  </>
);

export default Inicio;
