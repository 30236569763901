import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//

import { Container, Grid, Typography } from '@mui/material';
import Header from './header';
import Nav from './nav';
import Questionario from '../../pages/Questionario';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardClienteLayout() {
  const { censado } = useSelector((state) => state.usuarioCL);
  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      <>
        {censado !== null && <Nav openNav={open} onCloseNav={() => setOpen(false)} />}

        <Main>
          {censado !== null ? (
            <Outlet />
          ) : (
            <Container maxWidth="xl">
              <Typography variant="h4" sx={{ mb: 5 }}>
                Hola, bienvenido de nuevo,
                <br /> debes diligenciar el siguiente formulario para solicitar tu crédito.
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Questionario />
                </Grid>
              </Grid>
            </Container>
          )}
        </Main>
      </>
    </StyledRoot>
  );
}
