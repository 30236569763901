import '../Style/WebStyle.css';

import { Grid } from '@mui/material';
import React from 'react';

const Pasos = () => (
  <Grid container className="pasos" id="8pasos">
    <div className="cajapasos">
      <h1 id="pasos">8 Pasos para adquirir tu crédito</h1>
    </div>
    <Grid item xs={12} className="lista">
      <div className="contenidopasos">
        <h6 className='numero'>1</h6>
        <div className="circlecontenidopasos1">
          <p>Calcular Crédito</p>
        </div>
      </div>

      <div className="contenidopasos">
        <h6 className='numero'>2</h6>
        <div className="circlecontenidopasos1">
          <p>Solicitar Crédito</p>
        </div>
      </div>
      <div className="contenidopasos">
        <h6 className='numero'>3</h6>
        <div className="circlecontenidopasos1">
          <p>Solicitud de Datos</p>
        </div>
      </div>
      <div className="contenidopasos">
        <h6 className='numero'>4</h6>
        <div className="circlecontenidopasos1">
          <p>Validación de Datos</p>
        </div>
      </div>
      <div className="contenidopasos">
        <h6 className='numero'>5</h6>
        <div className="circlecontenidopasos1">
          <p>Resultado de la Solicitud</p>
        </div>
      </div>
      <div className="contenidopasos">
        <h6 className='numero'>6</h6>
        <div className="circlecontenidopasos1">
          <p>Firma de Contrato</p>
        </div>
      </div>
      <div className="contenidopasos">
        <h6 className='numero'>7</h6>
        <div className="circlecontenidopasos1">
          <p>Desembolso</p>
        </div>
      </div>
      <div className="contenidopasos">
        <h6 className='numero'>8</h6>
        <div className="circlecontenidopasos1">
          <p>Pago</p>
        </div>
      </div>
    </Grid>
  </Grid>
);

export default Pasos;
