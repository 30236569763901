/* eslint-disable react/prop-types */
import {
  Card,
  Box,
  Grid,
  CardContent,
  Typography,
  Button,
  tooltipClasses,
  Zoom,
  IconButton,
  styled,
  Tooltip,
  Popper,
} from '@mui/material';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { calculosDelCredito } from '../../../helper/liquidarCredito';
import { formateador } from '../../../helper/Formateador';
import PrettoSlider from './PrettoSlider';
import { hoy } from '../../../helper/funtions';
import { solicitudCredito } from '../../../store/clientUser/credito/thunk';

let bloqueo = false;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} PopperComponent={Popper} TransitionComponent={Zoom} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const Calculadora = ({ showSolicitar = true }) => {
  const dispatch = useDispatch();
  const [valorSolicitado, setValorSolicitado] = useState(150000);
  const [tiempo, setTiempo] = useState(1);

  const handlePrestamoChange = (_, valor) => {
    setValorSolicitado(Number(valor));
  };

  const handleTiempoPrestamoChange = (_, tiempo) => {
    setTiempo(Number(tiempo));
  };

  const creditoConsultaCliente = {
    fecha_Desembolsado: moment(hoy).subtract(tiempo, 'days'),
    valor_Aprobado: valorSolicitado,
    fecha_Limite_Pago: moment(hoy).add(tiempo, 'days').format('YYYY-MM-DD'),
  };

  const { interesCorriente, valorPlataforma, valorAdministracion, valorIva, valorTotalParaPago } =
    calculosDelCredito(creditoConsultaCliente);

  const submitSolicitudCredito = (e) => {
    e.preventDefault();
    dispatch(solicitudCredito(valorSolicitado, tiempo));
    bloqueo = true;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Card
          sx={{
            minWidth: 275,
            display: 'flex',
            justifyContent: 'center',
            boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.25)',
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              backgroundColor: '#0074bc',
            }}
          >
            <form onSubmit={submitSolicitudCredito}>
              <div>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{
                    padding: '0.5em 2em',
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  Solicita tu crédito
                </Typography>
              </div>

              <Box
                sx={{
                  paddingRight: '48px',
                  paddingLeft: '31px',
                }}
              >
                <PrettoSlider
                  aria-label="pretto slider"
                  defaultValue={150000}
                  min={150000}
                  valueLabelDisplay="on"
                  max={750000}
                  onChange={handlePrestamoChange}
                  step={10000}
                  sx={{
                    display: 'flex',
                    width: '90%',
                    justifyContent: 'center',
                    marginLeft: '15px',
                    marginBottom: '10px',
                  }}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#fff',
                      marginTop: '13px',
                    }}
                  >
                    MIN $150.000
                  </Typography>
                  <Typography
                    sx={{
                      color: '#fff',
                      marginTop: '13px',
                    }}
                  >
                    MAX $750.000
                  </Typography>
                </Box>

                <Typography
                  variant="h5"
                  component="h2"
                  sx={{
                    padding: '0.5em 2em',
                    textAlign: 'center',
                    color: 'white',
                  }}
                >
                  Días
                </Typography>

                <PrettoSlider
                  aria-label="pretto slider"
                  defaultValue={1}
                  valueLabelDisplay="on"
                  min={1}
                  max={30}
                  onChange={handleTiempoPrestamoChange}
                  step={1}
                  sx={{
                    display: 'flex',
                    width: '90%',
                    justifyContent: 'center',
                    marginLeft: '15px',
                    marginBottom: '10px',
                  }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      color: '#fff',
                      fontSize: '16px',
                      marginTop: '13px',
                    }}
                  >
                    1 Día
                  </Typography>
                  {/* <Typography
                    sx={{
                      color: '#fff',
                      fontSize: '16px',
                      marginTop: '13px',
                    }}
                  >
                    30 Días
                  </Typography> */}
                </Box>
              </Box>

              <Box sx={{ marginBottom: '20px' }} />

              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ margin: '0 50px 0 50px' }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography sx={{ marginBottom: '3px', color: '#fff' }}>
                        CANTIDAD SOLICITADA DE PRESTAMO ($)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        sx={{
                          marginBottom: '20px',
                          backgroundColor: '#fff',
                          borderRadius: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          color: 'black',
                          margin: '5px',
                          width: '100%',
                        }}
                      >
                        $ {formateador(valorSolicitado)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography sx={{ color: '#fff' }}>DIAS SOLICITADOS DE PRESTAMO</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        sx={{
                          marginBottom: '20px',
                          backgroundColor: '#fff',
                          borderRadius: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                          color: 'black',
                          margin: '5px',
                          width: '100%',
                        }}
                      >
                        {tiempo} {tiempo === 1 ? 'Día' : 'Dias'}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ margin: '0 50px 0 50px' }}>
                <Typography sx={{ width: '100%', color: '#fff' }}>
                  PLATAFORMA WEB (Opcional)
                  <HtmlTooltip
                    title="El pago del servicio de plataforma te permite monitorear el estado de tu crédito cada día, te permite realizar la firma electrónica, y realizar los pagos por cualquier medio como PSE, Efecty, baloto entre otros. En caso de no tomar esta opción de la plataforma puedes realizar la firma de forma física en nuestras oficinas y realizar los pagos de forma presencial en nuestras oficinas."
                    placement="right"
                    sx={{
                      maxWidth: 500,
                      fontSize: 30,
                    }}
                  >
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        color: 'white',
                        padding: 0,
                        paddingLeft: '5px',
                      }}
                    >
                      <HelpTwoToneIcon />
                    </IconButton>
                  </HtmlTooltip>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'black',
                    margin: '5px',
                    width: '100%',
                  }}
                >
                  $ {formateador(valorPlataforma)}
                </Typography>
              </Box>

              <Box sx={{ margin: '0 50px 0 50px' }}>
                <Typography sx={{ textAlign: 'justify', color: '#fff' }}>INTERÉS ($) TASA 25% EA</Typography>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'black',
                    margin: '5px',
                    width: '100%',
                  }}
                >
                  $ {interesCorriente ? formateador(interesCorriente) : 0}
                </Typography>
              </Box>

              <Box sx={{ margin: '0 50px 0 50px' }}>
                <Typography sx={{ fontSize: '16px', color: '#fff' }}>
                  ADMINISTRACIÓN (Opcional)
                  <HtmlTooltip
                    title="El pago de este servicio se realiza la gestión de crédito como: estudio de crédito a través de centrales de riesgo y parámetros de easycredit, te permite tener un cupo disponible para cuando lo desees, permite tener respuesta y desembolso en 24 horas hábiles. En caso de no tomar esta opción de la administración puedes presentar un fiador con propiedad raíz, puedes buscar un aval a favor de easycredit Colombia SAS quien garantice el pago del crédito. Esta documentación debe ser presentada en forma física. "
                    placement="right"
                  >
                    <IconButton
                      aria-label="upload picture"
                      component="span"
                      sx={{
                        color: 'white',
                        padding: 0,
                        paddingLeft: '5px',
                      }}
                    >
                      <HelpTwoToneIcon />
                    </IconButton>
                  </HtmlTooltip>
                </Typography>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'black',
                    margin: '5px',
                    width: '100%',
                  }}
                >
                  $ {formateador(valorAdministracion)}
                </Typography>
              </Box>

              <Box sx={{ margin: '0 50px 0 50px' }}>
                <Typography sx={{ lineHeight: '24px', color: '#fff' }}>IVA ($)</Typography>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'black',
                    margin: '5px',
                    width: '100%',
                  }}
                >
                  $ {formateador(valorIva)}
                </Typography>
              </Box>

              <Box sx={{ margin: '0 50px 0 50px' }}>
                <Typography
                  sx={{
                    marginBottom: '3px',
                    color: '#fff',
                    width: '100%',
                    textAlign: 'justify',
                    fontSize: '18px',
                  }}
                >
                  TOTAL A PAGAR ($)
                </Typography>
                <Typography
                  sx={{
                    marginBottom: '20px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: 'black',
                    margin: '5px',
                    width: '100%',
                  }}
                >
                  ${formateador(valorTotalParaPago)}
                </Typography>
              </Box>
              {showSolicitar && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: '#FFDE2E',
                    borderRadius: '50px',
                    fontWeight: 'bold',
                    marginTop: '20px',
                    width: '100%',
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#FFFF99',
                    },
                  }}
                  disabled={bloqueo}
                >
                  {bloqueo ? 'Tienes una solicitud en proceso' : `¡SOLICITA YA!`}
                </Button>
              )}
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
};

export default Calculadora;
