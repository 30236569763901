/* eslint-disable react/prop-types */
import React from 'react';
import { MenuItem, Typography, TextField, Grid, Box, Button } from '@mui/material';

import Swal from 'sweetalert2';
import { NumericFormatCustom } from '../../../components/numericForm/NumericForm';

const InformacionFinanciera = ({
  registroInfoFinanciera,
  setInformacionFinanciera,
  setActiveStep,
  activeStep,
  steps,
}) => {
  const handleNext = () => {
    if (
      registroInfoFinanciera.banco.trim() === '' ||
      registroInfoFinanciera.numero_cuenta.trim() === '' ||
      // registroInfoFinanciera.tiene_tarjeta.trim() === '' ||
      registroInfoFinanciera.ingresos_Mensuales.trim() === '' ||
      registroInfoFinanciera.gastos_Mensuales.trim() === ''
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setInformacion = ({ target }) => {
    setInformacionFinanciera({
      ...registroInfoFinanciera,
      [target.name]: target.value,
    });
  };

  return (
    <Box component="form">
      <Typography
        variant="h4"
        component="h2"
        sx={{
          marginY: 3,
        }}
      >
        Información Financiera
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            type="text"
            name="banco"
            label="Banco"
            value={registroInfoFinanciera.banco}
            onChange={setInformacion}
            select
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="40">BANCO AGRARIO</MenuItem>
            <MenuItem value="52">BANCO AV VILLAS</MenuItem>
            <MenuItem value="13">BANCO BBVA COLOMBIA S.A </MenuItem>
            <MenuItem value="32">BANCO CAJA SOCIAL</MenuItem>
            <MenuItem value="67">BANCO COOPERATIVO COOPCENTRAL</MenuItem>
            <MenuItem value="51">BANCO DAVIVIENDA</MenuItem>
            <MenuItem value="01">BANCO DE BOGOTÁ</MenuItem>
            <MenuItem value="23">BANCO DE OCCIDENTE</MenuItem>
            <MenuItem value="12">BANCO DE GNB SUDAMERIS</MenuItem>
            <MenuItem value="06">BANCO ITAU</MenuItem>
            <MenuItem value="02">BANCO POPULAR</MenuItem>
            <MenuItem value="63">BANCO FINANDINA</MenuItem>
            <MenuItem value="66">BANCO SANTANDER COLOMBIA</MenuItem>
            <MenuItem value="69">BANCO SERFINANZA</MenuItem>
            <MenuItem value="07">BANCOLOMBIA</MenuItem>
            <MenuItem value="36">CONFIAR COOPERATIVA FINANCIERA</MenuItem>
            <MenuItem value="19">SCOTIABANK COLPATRIA</MenuItem>
            <MenuItem value="61">BANCOOMEVA</MenuItem>
            <MenuItem value="62">BANCO FALABELLA</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="numero_cuenta"
            value={registroInfoFinanciera.numero_cuenta}
            label="Numero de Cuenta"
            fullWidth
            type="text"
            onChange={setInformacion}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            type="text"
            name="tipo_cuenta"
            value={registroInfoFinanciera.tipo_cuenta}
            label="Tipo de  cuenta"
            onChange={setInformacion}
            select
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="ahorro">Ahorros</MenuItem>
            <MenuItem value="corriente">Corriente</MenuItem>
          </TextField>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <TextField
            type="text"
            name="tiene_tarjeta"
            value={registroInfoFinanciera.tiene_tarjeta}
            onChange={setInformacion}
            label="Tiene Tarjeta de Credito"
            select
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem value="si">Sí</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </TextField>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="ingresos_Mensuales"
            value={registroInfoFinanciera.ingresos_Mensuales}
            onChange={setInformacion}
            label="Ingresos Mensuales"
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Gastos Mensuales"
            onChange={setInformacion}
            fullWidth
            name="gastos_Mensuales"
            value={registroInfoFinanciera.gastos_Mensuales}
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ margin: 2 }}>
        {activeStep !== 0 && (
          <Button sx={{ marginRight: 2 }} onClick={handleBack}>
            Anterior
          </Button>
        )}
        <Button onClick={handleNext} variant="contained" color="primary">
          {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </Box>
    </Box>
  );
};

export default InformacionFinanciera;
