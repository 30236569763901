import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardHeader, Grid, Skeleton, Typography } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { AppConversionRates, AppWidgetSummary } from '../../sections/@dashboard/app';
import { filtroInformeGestion } from '../../helper/funtions';
import { cargarInforme } from '../../store/historicos';

export const Gestion = () => {
  const dispatch = useDispatch();
  let infoInforme;
  const { creditos, loading } = useSelector((state) => state.historicos);
  const [listos, setListos] = useState(false);
  const [desde, setDesde] = useState(null);
  const [hasta, setHasta] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [informacion, setInformacion] = useState({
    solicitados: 0,
    rechazados: 0,
    desembolsados: 0,
    cancelados: 0,
    grafico: [],
    dataSolicitados: [],
    dataRechazados: [],
    dataCancelados: [],
    dataDesembolsados: [],
    dataRenovados: [],
  });

  const {
    totalSolicitados,
    rechazadosData,
    totalRechazados,
    totalCancelados,
    grafico,
    totalDesembolsados,
    canceladosData,
    solicitadosData,
    desembolsadosData,
    renovadosData,
  } = filtroInformeGestion(creditos, desde, hasta);

  useEffect(() => {
    setInformacion({
      ...informacion,
      solicitados: totalSolicitados,
      rechazados: totalRechazados,
      desembolsados: totalDesembolsados,
      cancelados: totalCancelados,
      dataSolicitados: solicitadosData,
      dataCancelados: canceladosData,
      dataRechazados: rechazadosData,
      dataDesembolsados: desembolsadosData,
      dataRenovados: renovadosData,
      grafico,
    });
    infoInforme = {
      data: informacion,
      desde,
      hasta,
    };
  });

  const generarInfo = () => {
    dispatch(cargarInforme(infoInforme));
    setLoadingInfo(true);
    setListos(true);
    setTimeout(() => {
      setLoadingInfo(false);
    }, 2000);
  };

  return loading ? (
    <Grid>
      <Grid display="flex" justifyContent="center" alignItems="end">
        <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
      </Grid>
    </Grid>
  ) : (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card sx={{ marginBottom: 4 }}>
          <Typography variant="h4" sx={{ margin: 4 }}>
            Informes de Gestion:
          </Typography>
          <Box
            sx={{
              maxWidth: 600,
              margin: '50px auto',
            }}
          >
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              <DatePicker label="Desde" value={desde} onChange={(newValue) => setDesde(newValue)} />
              <DatePicker label="Hasta" value={hasta} onChange={(newValue) => setHasta(newValue)} />
            </DemoContainer>
            <Button onClick={generarInfo} sx={{ marginTop: 2 }} disabled={desde === null && hasta === null}>
              Generar Informe
            </Button>
          </Box>
        </Card>
      </LocalizationProvider>

      {loadingInfo && (
        <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 1.7 }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      )}

      {listos && loadingInfo === false && (
        <Card>
          <CardHeader title="Resumen:" />
          <Grid
            container
            spacing={3}
            sx={{
              maxWidth: 1100,
              margin: '30px auto',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Solicitados"
                total={informacion.solicitados}
                icon={'carbon:intent-request-scale-in'}
                data={informacion.dataSolicitados}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Rechazados"
                total={informacion.rechazados}
                color="info"
                icon={'fluent:text-change-reject-20-regular'}
                data={informacion.dataRechazados}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Desembolsados"
                total={informacion.desembolsados}
                color="warning"
                icon={'icon-park-solid:delivery'}
                data={informacion.dataDesembolsados}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Cancelados"
                total={informacion.cancelados}
                color="error"
                icon={'ep:finished'}
                data={informacion.canceladosData}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Renovados"
                total={informacion.dataRenovados.length}
                color="error"
                icon={'material-symbols:autorenew-rounded'}
                data={informacion.dataRenovados}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppConversionRates
              title="Graficos"
              subheader="Datos del rango de fechas seleccionado"
              chartData={informacion?.grafico}
            />
          </Grid>
        </Card>
      )}
    </>
  );
};
