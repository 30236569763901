import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import InformeDaviviendaScreen from '../components/informeDavivienda/InformeDaviviendaScreen';
import { PATH_NAME, TITLES } from '../constants';

export function InformeDavivienda() {
  const location = useLocation();
  const isInformeDavivienda = location?.pathname.includes(PATH_NAME.informe_de_davivienda);
  return (
    <>
      <Helmet>
        <title> {isInformeDavivienda ? TITLES.informe_de_davivienda : TITLES.default} </title>
      </Helmet>

      <InformeDaviviendaScreen />
    </>
  );
}
