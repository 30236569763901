/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PATH_NAME, TITLES } from '../constants';

import { CompromisosVencidos } from '../components/tables/CompromisosVencidos';
import { startConsultarCompromisos } from '../store';

export function ListadoCompromisosVencidos() {
  const location = useLocation();
  const isListadoCompromisosVencidos = location?.pathname.includes(PATH_NAME.listado_de_compromisos_vencidos);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startConsultarCompromisos());
  }, []);

  return (
    <>
      <Helmet>
        <title> {isListadoCompromisosVencidos ? TITLES.listado_de_compromisos_vencidos : TITLES.default} </title>
      </Helmet>

      <CompromisosVencidos />
    </>
  );
}
