import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cuenta: null,
};

export const cuentaSlice = createSlice({
  name: 'cuenta',
  initialState,
  reducers: {
    cargarInformacionCuenta: (state, action) => {
      state.cuenta = action.payload;
    },
  },
});

export const { cargarInformacionCuenta } = cuentaSlice.actions;
