/* eslint-disable import/no-unresolved */

import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ciudadesSelect } from 'src/constants/ciudades';
import { calculosDelCredito, diasDeMora } from 'src/helper/liquidarCredito';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const InformeDataCredito = () => {
  const creditos = useSelector((state) => state?.historicos?.creditos);
  const primerDia = moment('2024-01-01');
  // const primerDia = moment().subtract(6, 'months').startOf('month');
  const ultimoDia = moment().subtract(2, 'months').endOf('month');
  const [cargando, setCargando] = useState(true);
  console.log(ultimoDia);

  const seleccionarDatos = () => {
    const datosSeleccionados = creditos.filter((credito) => {
      const fechaCredito = moment(credito?.fecha_Desembolsado);
      return fechaCredito.isBetween(primerDia, ultimoDia, null, '[]');
    });

    return datosSeleccionados;
  };
  const vencidos = seleccionarDatos();

  const novedadCredito = (fechaLimitePago) => {
    const dias = diasDeMora(fechaLimitePago);
    if (dias >= 30 && dias <= 89) {
      return '07';
    }
    if (dias >= 90 && dias <= 119) {
      return '09';
    }
    if (dias > 120) {
      return '09';
    }
    return '01';
  };

  const comportamientoCredito = (fechaLimitePago, fechaCancelado) => {
    if (fechaCancelado !== null) {
      return '03';
    }

    const dias = diasDeMora(fechaLimitePago);

    if (dias === '01') {
      return '01';
    }

    return '02';
  };

  useEffect(() => {
    if (creditos !== undefined && creditos !== null && creditos.length > 0) {
      setCargando(false);
    }
  }, [creditos]);

  const valorDeuda = (credito) => {
    const { valorTotalParaPago } = calculosDelCredito(credito);

    return valorTotalParaPago;
  };

  const valorDeudaInicial = (credito) => {
    const { montoAprobando, valorAdministracion, valorIva, valorPlataforma } = calculosDelCredito(credito);
    return Number(montoAprobando) + Number(valorAdministracion) + Number(valorIva) + Number(valorPlataforma);
  };

  const ciudadResidencia = (ciudadCliente) => {
    const ciudad = ciudadesSelect.find((ciudad) => ciudad.codigo === ciudadCliente);
    return ciudad?.nombre;
  };

  const formatearDatosInforme1 = (numeroRegistros) => {
    if (numeroRegistros === 0) {
      return 'No hay información para mostrar en informe 1';
    }
    const datos = {
      campo1: 'H',
      campo2: '118070',
      campo3: '11',
      campo4: moment().format('YYYYMMDD'),
      campo5: 'M',
      campo6: ' ',
      campo7: 'T',
      campo8: '00000000',
      campo9: '00000000',
      campo10: 'N',
      campo11: '0',
    };

    let salida = datos.campo1.substring(0, 18).padEnd(18, 'H');
    salida += datos.campo2.substring(0, 6).padEnd(6, ' ');
    salida += datos.campo3.substring(0, 2).padEnd(2, ' ');
    salida += datos.campo4.substring(0, 8).padEnd(8, ' ');
    salida += datos.campo5.substring(0, 1).padEnd(1, ' ');
    salida += datos.campo6.substring(0, 1).padEnd(1, ' ');
    salida += datos.campo7.substring(0, 1).padEnd(1, ' ');
    salida += datos.campo8.substring(0, 8).padEnd(8, '0');
    salida += datos.campo9.substring(0, 8).padEnd(8, '0');
    salida += datos.campo10.substring(0, 1).padEnd(1, '');
    salida += datos.campo11.substring(0, 746).padEnd(746, '0');
    return salida;
  };

  const formatearDatosInforme2 = (datos) => {
    if (datos.length === 0) {
      return 'No hay información para mostrar en informe 2';
    }
    const campo1 = '1';
    const campo2 = String(datos?.cuentaUsuario?.cedula);
    const campo3 = `E-${datos.id}`;
    const campo4 = `${datos?.cuentaUsuario?.primerNombre || ''} ${datos?.cuentaUsuario?.segundoNombre || ''} ${
      datos?.cuentaUsuario?.primerApellido || ''
    } ${datos?.cuentaUsuario?.segundoApellido || ''}`;
    const campo5 = '0';
    const campo6 = moment(datos?.fecha_Desembolsado).format('YYYYMMDD');
    const campo7 = moment(datos?.fecha_Limite_Pago).format('YYYYMMDD');
    const campo8 = '00';
    const campo9 = '0';
    const campo10 = '0';
    const campo11 = '0';
    const campo12 = '1';
    const campo13 = datos?.fecha_Cancelado === null ? '0' : '1';
    const campo14 = '1';
    const campo15 = novedadCredito(datos?.fecha_Limite_Pago);
    const campo16 = '0';
    const campo17 = moment().format('YYYYMMDD');
    const campo18 = comportamientoCredito(datos?.fecha_Limite_Pago, datos?.fecha_Cancelado);
    const campo19 = moment().format('YYYYMMDD');
    const campo20 = '0';
    const campo21 = '0';
    const campo22 = '0';
    const campo23 = '0';
    const campo24 = '0';
    const campo25 = '0';
    const campo26 = ' ';
    const campo27 = '1';
    const campo28 = '0';
    const campo29 = ' ';
    const campo30 = '0';
    const campo31 = `${diasDeMora(datos?.fecha_Limite_Pago)}`;
    const campo32 = `${datos?.valor_Aprobado}`;
    const campo33 = `${valorDeuda(datos)}`;
    const campo34 = `0`;
    const campo35 = `${valorDeudaInicial(datos)}`;
    const campo36 = `${valorDeuda(datos)}`;
    const campo37 = `1`;
    const campo38 = `${datos?.fecha_Cancelado !== null ? '1' : '0'}`;
    const campo39 = `${datos?.fecha_Cancelado === null ? '1' : '0'}`;
    const campo40 = `0`;
    const campo41 = ` `;
    const campo42 = `${moment(datos?.fecha_Limite_Pago).format('YYYYMMDD')}`;
    const campo43 = `${datos?.fecha_Cancelado !== null ? moment(datos?.fecha_Cancelado).format('YYYYMMDD') : ' '} `;
    const campo44 = `DATACREDITO`;
    const campo45 = `Medellin`;
    const campo46 = `05001000`;
    const campo47 = `${ciudadResidencia(datos?.cuentaUsuario?.ciudad_residencia) || 'Bogota'}`;
    const campo48 = `${datos?.cuentaUsuario?.ciudad_residencia || ' '}000`;
    const campo49 = `Bogota D.C.`;
    const campo50 = `${datos?.cuentaUsuario?.direccion_residencia || ' '}`;
    const campo51 = `${datos?.cuentaUsuario?.telefono || ''}`;
    const campo52 = `Bogota D.C.`;
    const campo53 = `${datos?.cuentaUsuario?.ciudad_residencia || ' '}000`;
    const campo54 = `Bogota D.C.`;
    const campo55 = `${datos?.cuentaUsuario?.direccion_trabajo || ' '}`;
    const campo56 = `${datos?.cuentaUsuario?.telefono_trabajo || ' '}`;
    const campo57 = `${ciudadResidencia(datos?.cuentaUsuario?.ciudad_residencia) || ' '}`;
    const campo58 = `${datos?.cuentaUsuario?.ciudad_residencia || ' '}000`;
    const campo59 = `Bogota D.C.`;
    const campo60 = `${datos?.cuentaUsuario?.direccion_residencia || ' '}`;
    const campo61 = `${datos?.cuentaUsuario?.usuario?.email}`;
    const campo62 = `${datos?.cuentaUsuario?.celular}`;
    const campo63 = `0`;
    const campo64 = `E-${datos.id}`;
    const campo65 = `0`;
    const campo66 = `0`;
    const campo67 = `${moment(datos?.cuentaUsuario?.fecha_de_expedicion_cedula).format('YYYYMMDD')}`;
    const campo68 = `${moment(datos?.cuentaUsuario?.ciudad_expedicion_cedula).format('YYYYMMDD')}000`;
    const campo69 = ` `;

    let salida = campo1.substring(0, 1).padEnd(1, ' ');
    salida += campo2.substring(0, 11).padStart(11, '0');
    salida += campo3.substring(0, 18).padStart(18, '0');
    salida += campo4.substring(0, 45).padEnd(45, ' ');
    salida += campo5.substring(0, 1).padEnd(1, ' ');
    salida += campo6.substring(0, 8).padEnd(8, ' ');
    salida += campo7.substring(0, 8).padEnd(8, ' ');
    salida += campo8.substring(0, 2).padEnd(2, '0');
    salida += campo9.substring(0, 1).padEnd(1, ' ');
    salida += campo10.substring(0, 1).padEnd(1, ' ');
    salida += campo11.substring(0, 8).padEnd(8, '0');
    salida += campo12.substring(0, 1).padEnd(1, ' ');
    salida += campo13.substring(0, 1).padEnd(1, ' ');
    salida += campo14.substring(0, 1).padEnd(1, ' ');
    salida += campo15.substring(0, 2).padEnd(2, ' ');
    salida += campo16.substring(0, 1).padEnd(1, ' ');
    salida += campo17.substring(0, 8).padEnd(8, ' ');
    salida += campo18.substring(0, 2).padEnd(2, ' ');
    salida += campo19.substring(0, 8).padEnd(8, ' ');
    salida += campo20.substring(0, 1).padEnd(1, '0');
    salida += campo21.substring(0, 8).padEnd(8, ' ');
    salida += campo22.substring(0, 1).padEnd(1, ' ');
    salida += campo23.substring(0, 8).padEnd(8, ' ');
    salida += campo24.substring(0, 1).padEnd(1, ' ');
    salida += campo25.substring(0, 1).padEnd(1, ' ');
    salida += campo26.substring(0, 30).padEnd(30, ' ');
    salida += campo27.substring(0, 1).padEnd(1, ' ');
    salida += campo28.substring(0, 1).padEnd(1, ' ');
    salida += campo29.substring(0, 2).padEnd(2, ' ');
    salida += campo30.substring(0, 3).padEnd(3, '0');
    salida += campo31.substring(0, 3).padStart(3, ' ');
    salida += campo32.substring(0, 11).padEnd(11, ' ');
    salida += campo33.substring(0, 11).padEnd(11, ' ');
    salida += campo34.substring(0, 11).padStart(11, '0');
    salida += campo35.substring(0, 11).padEnd(11, ' ');
    salida += campo36.substring(0, 11).padStart(11, ' ');
    salida += campo37.substring(0, 3).padEnd(3, ' ');
    salida += campo38.substring(0, 3).padStart(3, ' ');
    salida += campo39.substring(0, 3).padStart(3, ' ');
    salida += campo40.substring(0, 3).padStart(3, '0');
    salida += campo41.substring(0, 8).padEnd(8, ' ');
    salida += campo42.substring(0, 8).padEnd(8, ' ');
    salida += campo43.substring(0, 8).padEnd(8, ' ');
    salida += campo44.substring(0, 30).padEnd(30, ' ');
    salida += campo45.substring(0, 20).padEnd(20, ' ');
    salida += campo46.substring(0, 8).padEnd(8, ' ');
    salida += campo47.substring(0, 20).padEnd(20, ' ');
    salida += campo48.substring(0, 8).padEnd(8, '');
    salida += campo49.substring(0, 20).padEnd(20, ' ');
    salida += campo50.substring(0, 60).padEnd(60, ' ');
    salida += campo51.substring(0, 12).padStart(12, '0');
    salida += campo52.substring(0, 20).padEnd(20, ' ');
    salida += campo53.substring(0, 8).padEnd(8, ' ');
    salida += campo54.substring(0, 20).padEnd(20, ' ');
    salida += campo55.substring(0, 60).padEnd(60, ' ');
    salida += campo56.substring(0, 12).padStart(12, ' ');
    salida += campo57.substring(0, 20).padEnd(20, ' ');
    salida += campo58.substring(0, 8).padEnd(8, ' ');
    salida += campo59.substring(0, 20).padEnd(20, ' ');
    salida += campo60.substring(0, 69).padEnd(60, ' ');
    salida += campo61.substring(0, 60).padEnd(60, ' ');
    salida += campo62.substring(0, 12).padStart(12, '0');
    salida += campo63.substring(0, 6).padEnd(6, '0');
    salida += campo64.substring(0, 18).padEnd(18, '0');
    salida += campo65.substring(0, 1).padEnd(1, ' ');
    salida += campo66.substring(0, 1).padEnd(1, '0');
    salida += campo67.substring(0, 8).padEnd(8, ' ');
    salida += campo68.substring(0, 8).padEnd(8, ' ');
    salida += campo69.substring(0, 1).padEnd(1, '');

    return salida;
  };

  const formatearDatosInforme3 = (numeroRegistros) => {
    if (numeroRegistros === 0) {
      return 'No hay información para mostrar en informe 3';
    }
    const datos = {
      campo1: 'Z',
      campo2: moment().format('YYYYMMDD'),
      campo3: `${numeroRegistros + 2}`,
      campo4: `${numeroRegistros}`,
      campo5: ' ',
    };

    let salida = datos.campo1.substring(0, 18).padEnd(18, 'H');
    salida += datos.campo2.substring(0, 8).padEnd(8, ' ');
    salida += datos.campo3.substring(0, 8).padEnd(8, ' ');
    salida += datos.campo4.substring(0, 8).padEnd(8, ' ');
    salida += datos.campo5.substring(0, 1).padEnd(1, ' ');

    return salida;
  };

  const descargarInforme = () => {
    // La función formatearDatosInforme1 devuelve una cadena, así que no necesitamos usar .join()
    let salida = formatearDatosInforme1(vencidos.length);
    // La variable dato es un array, así que podemos usar .join('\n')
    const dato = vencidos.map((credito) => formatearDatosInforme2(credito));
    salida += `\n${dato.join('\n')}`;
    salida += `\n${formatearDatosInforme3(vencidos.length)}`;

    const blob = new Blob([salida], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'informe-datacredito.txt';
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);
  };

  const descargarNombres = () => {
    const datosSeleccionados = vencidos;

    const nombres = datosSeleccionados.map(
      (credito) =>
        `- ${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.primerApellido}, credito: E-${credito?.id}`
    );
    const nombresFormateados = nombres.join('\n');

    const blob = new Blob([nombresFormateados], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'informe-control.txt';
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }, 0);
  };

  return cargando ? (
    <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 1.7 }}>
      <div style={{ paddingTop: '57%' }} />
    </Skeleton>
  ) : (
    <Card sx={{ marginBottom: 4 }}>
      <Typography variant="h4" sx={{ margin: 4 }}>
        Informe para Datacredito:
      </Typography>
      <Typography variant="body2" align="left" style={{ fontStyle: 'italic', marginTop: -20 }} sx={{ margin: 4 }}>
        Desde: {primerDia.format('DD-MM-YYYY')} Hasta: {ultimoDia.format('DD-MM-YYYY')}
      </Typography>

      <Box
        sx={{
          maxWidth: 600,
          margin: '50px auto',
        }}
      >
        <LoadingButton onClick={descargarInforme} sx={{ marginTop: 2, marginRight: 2 }}>
          Generar Archivo Plano
        </LoadingButton>

        <LoadingButton onClick={descargarNombres} sx={{ marginTop: 2, marginLeft: 2 }}>
          Generar Archivo Plano de Control
        </LoadingButton>
      </Box>
    </Card>
  );
};

export default InformeDataCredito;
