import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { startCerrarSesion } from '../../../../store/clientUser/auth';
import { MAIN_PATH_CLIENTE, PATH_NAME_CLIENTE } from '../../../../constants';
import account from '../../../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Cuenta',
    icon: 'eva:home-fill',
    url: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.perfil_cliente}`,
  },
  {
    label: 'Actualizar Perfil',
    icon: 'eva:home-fill',
    url: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.editar_perfil_cliente}`,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const cuenta = useSelector((state) => state?.cuentaCL?.cuenta);
  const creditoActivo = useSelector((state) => state?.creditoCL?.creditoActivo);
  const email = useSelector((state) => state?.usuarioCL?.email);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const dispatch = useDispatch();
  const handleSalir = () => {
    dispatch(startCerrarSesion());
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${cuenta?.primerNombre} ${cuenta?.primerApellido}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={handleClose}
              component={Link}
              to={option.url}
              disabled={option.label === 'Actualizar Perfil' && creditoActivo !== null}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleSalir} sx={{ m: 1 }}>
          Salir
        </MenuItem>
      </Popover>
    </>
  );
}
