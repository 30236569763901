import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Reproductor from './Reproductor';

export const Cursos = () => {
  const videoUrl =
    'https://easycreditvideos.nyc3.cdn.digitaloceanspaces.com/AJUSTES%20DE%20HOJA%20DE%20CALCULO%20Y%20FORMATO%20DE%20DATOS.mp4';

  const videoUrl2 = 'https://easycreditvideos.nyc3.cdn.digitaloceanspaces.com/GRAFICAS,OPERACIONES,MULTIPLES.mp4';
  const videoUrl3 =
    'https://easycreditvideos.nyc3.cdn.digitaloceanspaces.com/AJUSTES%20DE%20HOJA%20DE%20CALCULO%20Y%20FORMATO%20DE%20DATOS.mp4';
  const videoUrl4 = 'https://easycreditvideos.nyc3.cdn.digitaloceanspaces.com/FUNCIONES%20Y%20SEGURIDAD.mp4';
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          Capacitación: Crece con Easy Credit
        </Typography>
        <Typography variant="body2" color="text.secondary">
          En Easy Credit estamos comprometidos con tu crecimiento, tanto financiero como educativo. Por eso, te
          ofrecemos estos cursos de capacitación financiera, diseñados para brindarte las herramientas y el conocimiento
          necesario para manejar tus finanzas de manera efectiva y alcanzar tus metas. Nos enorgullece ser parte de tu
          camino hacia un futuro más seguro y próspero.
        </Typography>
        <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <div style={{ padding: '20px' }}>
              <Reproductor videoUrl={videoUrl} titulo={'Ajuste a la hoja de Cálculo.'} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: '20px' }}>
              <Reproductor videoUrl={videoUrl2} titulo={'Gráficas y operaciones multiples.'} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ padding: '20px' }}>
              <Reproductor videoUrl={videoUrl3} titulo={'Ajuste de hojas de calculo y formatos.'} />
            </div>
          </Grid>
          <Grid item xs={12}>
            1
            <div style={{ padding: '20px' }}>
              <Reproductor videoUrl={videoUrl4} titulo={'Funciones y Seguridad.'} />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
