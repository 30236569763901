/* eslint-disable react/prop-types */
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import Scrollbar from '../scrollbar/Scrollbar';

function CardSecundario({ nota, createdAt }) {
  return (
    <>
      <Card variant="outlined" sx={{ m: 1 }}>
        <CardContent>{nota}</CardContent>
      </Card>
      <Typography component="legend" sx={{ fontSize: 12, marginLeft: 2, color: '#CCCCCC' }}>
        {moment(createdAt).format('ll')}
      </Typography>
    </>
  );
}

export function ListaNotasUsuario() {
  const notas = useSelector((state) => state.clientes.clienteEditable?.notaUsuarios);
  const nombre = useSelector((state) => state.clientes.clienteEditable);

  return (
    <Grid item xs={12} md={8} lg={8}>
      <Card sx={{ height: '424px' }}>
        <Scrollbar>
          <CardHeader
            title={'Notas de usuario'}
            subheader={`Historial de observaciones del cliente ${nombre?.primerNombre} ${nombre?.primerApellido}.`}
          />
          {notas?.length === 0 ? (
            <CardContent>
              <Typography component="legend" sx={{ display: 'flex', margin: 3, color: '#CCCCCC' }}>
                No hay observaciones del credito.
              </Typography>
            </CardContent>
          ) : (
            notas?.map((nota) => (
              <CardContent key={nota.createdAt}>
                <CardSecundario {...nota} />
              </CardContent>
            ))
          )}
        </Scrollbar>
      </Card>
    </Grid>
  );
}
