/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function CalificacionUsuario({ puntuacion, handleCalificacion }) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="puntuacion">Puntuación</InputLabel>
        <Select
          label="Puntuación"
          labelId="puntuacion"
          name="calificacion"
          onChange={handleCalificacion}
          value={puntuacion.calificacion}
        >
          <MenuItem value={1}>Deficiente</MenuItem>
          <MenuItem value={2}>Riesgoso</MenuItem>
          <MenuItem value={3}>Bueno</MenuItem>
          <MenuItem value={4}>Muy Bueno</MenuItem>
          <MenuItem value={5}>Excelente</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
