import { Button, Grid, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { cambioPassword } from '../../store/clientUser/auth';

const CambioPassword = () => {
  const dispatch = useDispatch();
  const classes = {};
  const [email, setEmail] = React.useState();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleChange = (e) => {
    e.preventDefault();
    dispatch(cambioPassword(email));
    setEmail('');
  };

  return (
    <Stack
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{
        width: '35ch',
      }}
    >
      <Grid container component="main">
        <form className={classes.form} noValidate onSubmit={handleChange}>
          <TextField
            variant="outlined"
            margin="normal"
            type="email"
            required
            fullWidth
            id="email"
            label="Correo Electrónico"
            name="email"
            onChange={handleEmailChange}
            autoComplete="email"
            autoFocus
            value={email}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Recuperar
          </Button>
        </form>
      </Grid>
    </Stack>
  );
};

export default CambioPassword;
