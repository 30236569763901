/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { coleccionInformeBienestar } from '../helper/coleccionCreditos';

import TableList from '../components/table-list';
import { MAIN_PATH, PATH_NAME } from '../constants';
import Iconify from '../components/iconify/Iconify';
import { cargarInformeAEditar, cargarInformeAImprimir, startInformesDone } from '../store';

export function BienestarFinancieroGeneralDone() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [solicitudes, setSolicitudes] = useState({
    loading: true,
    data: [],
  });

  const { informesDone } = useSelector((state) => state.contadorInfor);

  useEffect(() => {
    dispatch(startInformesDone());
    setSolicitudes({
      loading: false,
      data: coleccionInformeBienestar(informesDone),
    });
  }, []);

  const redireccionarEdicion = (idInforme) => {
    const informe = informesDone.filter((informe) => informe.id === idInforme)[0];
    console.log(informe);

    dispatch(cargarInformeAImprimir(informe));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.imprimir_bienestar_financiero}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cédula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    // {
    //   label: 'Monto Aprobado',
    //   name: 'valorSolicitado',
    //   options: {
    //     filter: true,
    //   },
    // },
    {
      name: 'Ver',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Gestionar" aria-label="Gestionar">
            <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
              <Iconify icon="material-symbols:edit-square-outline" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Copiar Cédula">
            <IconButton
              aria-label="Gestionar"
              onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1].replaceAll('.', ''))}
            >
              <Iconify icon="mdi-light:download-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];
  return (
    <>
      <Helmet>
        <title> Informes de Bienestar Financiero General</title>
      </Helmet>

      <TableList
        titulo="Informe Realizados por Bienestar Financiero"
        columnas={columnas}
        loading={solicitudes.loading}
        data={solicitudes.data}
      />
    </>
  );
}
