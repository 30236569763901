import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { PATH_NAME, TITLES } from '../constants';

import { Historico } from '../components/historico/Historico';

export function InformeHistorico() {
  const location = useLocation();
  const isInformeHistorico = location?.pathname.includes(PATH_NAME.informe_historico);
  return (
    <>
      <Helmet>
        <title> {isInformeHistorico ? TITLES.informe_historico : TITLES.default} </title>
      </Helmet>

      <Historico />
    </>
  );
}
