/* eslint-disable no-plusplus */
/* eslint-disable no-self-assign */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
export function formateador(newValue) {
  let cadena = '';
  let aux;
  let cont = 1;
  let m;
  let k;
  if (newValue < 0) aux = 1;
  else aux = 0;
  newValue = newValue?.toString() || '';
  for (m = newValue.length - 1; m >= 0; m--) {
    cadena = newValue.charAt(m) + cadena;
    if (cont % 3 == 0 && m > aux) cadena = `.${cadena}`;
    else cadena = cadena;
    if (cont == 3) cont = 1;
    else cont++;
  }
  cadena = cadena.replace(/.,/, ',');
  return cadena;
}
