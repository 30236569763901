/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './InformeCrediticio.css';
import { useSelector } from 'react-redux';

const InformeCrediticio = ({ informeAImprimir }) => {
  const contentRef = useRef();
  console.log(informeAImprimir);
  const {
    cuentaUsuario: { primerNombre },

    score,
    num_ctas,

    num_tcs,
    time_negative_tcs,
    has_negative_report_tcs,
    num_credit_banks,
    has_negative_report_credit_banks,
    time_negative_credit_banks,
    num_credit_coo,
    num_credit_real,
    num_emails,
    time_cellphone,
    time_addresses,
    time_ctas,
  } = informeAImprimir;

  // informesDone;

  const handleDownloadPDF = () => {
    const input = contentRef.current;
    html2canvas(input, { scale: 5 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Check if the content height is greater than the page height
      let heightLeft = pdfHeight;
      let position = 0;

      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();

      // If there is still content left to add, add new pages and keep adding the remaining content
      while (heightLeft >= 0) {
        position = heightLeft - pdfHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
      }

      pdf.save('informe-crediticio.pdf');
    });
  };

  return (
    <div className="body">
      <div className="container">
        <header className="header">
          <h1>¡Bienvenido, {primerNombre}!</h1>
        </header>
        <div ref={contentRef}>
          <section className="section">
            <h2 className="section-title">Informe Personalizado</h2>
            <p>
              Estamos encantados de presentarte nuestro informe personalizado sobre tu historial crediticio y cómo
              puedes mejorarlo.
            </p>
            <div className="info">
              <strong>Tu puntaje actual en data crédito es: {score}</strong>
            </div>
            <p>
              Recuerda, es fundamental entender que las entidades financieras no evalúan solo tu puntaje crediticio. Por
              eso, es crucial tener un manejo responsable de tus datos y créditos, para asegurar un historial financiero
              sólido y confiable.
            </p>
          </section>

          <section className="section">
            <h2 className="section-title">Sección 1: Cuentas Bancarias</h2>
            <ul className="ul">
              {num_ctas > 0 && num_ctas < 3 && (
                <li>
                  Tienes un manejo ejemplar de tus cuentas bancarias. Es recomendable mantener entre 1 y 2 cuentas de
                  ahorro para gestionar tus productos financieros de manera efectiva. Esta práctica aumentará la
                  confianza y facilitará la revisión de tus extractos.
                </li>
              )}
              <li>
                Tu cuenta actual tiene una antigüedad de {time_ctas} {time_ctas === 1 ? 'año' : 'años'},
                <span className="highlight">
                  {time_ctas > 2
                    ? ` lo que demuestra un manejo sólido y genera
              una mayor confianza al solicitar créditos.`
                    : ` debes mejorar y mantener una misma cuenta el mayor tiempo que te sea posible y generar una mayor confianza al solicitar créditos.`}
                </span>
              </li>
              {num_ctas >= 3 && num_ctas <= 4 && (
                <li>
                  Tienes un número de cuentas que podría dificultar su administración. Lo más aconsejable es mantener un
                  máximo de 2 cuentas bancarias para llevar un registro ordenado de tus ingresos y gastos. Si no estás
                  al tanto de tener este número de cuentas, te sugerimos revisar y considerar la cancelación de aquellas
                  que ya no utilices.
                </li>
              )}
              {num_ctas <= 5 && (
                <li>
                  Más de 5 Cuentas Bancarias: Tienes un número significativamente alto de cuentas bancarias. Te
                  recomendamos cerrar aquellas cuentas que no utilices o que tengan un movimiento mínimo. Las entidades
                  crediticias evalúan de manera minuciosa el manejo y movimiento de las cuentas bancarias. Cuantas más
                  cuentas tengas, más complicado podría ser presentar un historial crediticio sólido.
                </li>
              )}
            </ul>
          </section>

          <section className="section">
            <h2 className="section-title">Sección 2: Créditos Vigentes</h2>
            <p>
              El nivel de endeudamiento adecuado para una persona puede variar según su situación financiera, ingresos y
              gastos. Los créditos se dividen en 4 sectores: bancario, real, cooperativa, comunicaciones.
            </p>
            <h3>2.1 Tarjetas de Crédito</h3>
            <ul className="ul">
              {num_tcs >= 1 && num_tcs <= 2 && (
                <li>
                  Tienes el número correcto de tarjetas. Ahora revisa cómo las utilizas. Lo más recomendable es tener
                  máximo 2 tarjetas de crédito, preferiblemente de diferentes franquicias para recibir los diferentes
                  beneficios de las diferentes marcas. El uso correcto de la tarjeta de crédito es a 1 solo mes para que
                  no te genere intereses y eso generará un buen historial en tu puntaje crediticio. Igual manera revisa
                  muy bien tu tarjeta para que recibas los diferentes beneficios.
                  <ul className="benefits-list">
                    <li>
                      <strong>Cashback:</strong> Algunas tarjetas ofrecen un porcentaje de reembolso en efectivo por
                      cada compra. Esto es como ganar dinero directamente en tus compras.
                    </li>
                    <li>
                      <strong>Puntos y Millas:</strong> Las tarjetas de crédito de recompensas acumulan puntos o millas
                      por cada compra, que pueden canjearse por vuelos, hoteles, tarjetas de regalo y más.
                    </li>
                    <li>
                      <strong>Seguros de Viaje y Compra:</strong> Algunas tarjetas ofrecen seguros de viaje gratuito y
                      protección contra robo o daño para las compras hechas con la tarjeta.
                    </li>
                    <li>
                      <strong>Garantía Extendida:</strong> Extiende la garantía de ciertos productos comprados con la
                      tarjeta, lo que puede ahorrarte dinero en reparaciones futuras.
                    </li>
                    <li>
                      <strong>Seguro alquiler de vehículo:</strong> El seguro de auto proporcionado por las tarjetas de
                      crédito es un beneficio común ofrecido por muchas compañías de tarjetas de crédito. Sin embargo,
                      las coberturas y las condiciones varían según la emisora de la tarjeta.
                    </li>
                  </ul>
                </li>
              )}
              {num_tcs >= 3 && (
                <li>
                  Tienes un número significativamente alto de tarjetas de crédito. Ahora revisa cómo las utilizas. Lo
                  más recomendable es tener máximo 2 tarjetas de crédito, preferiblemente de diferentes franquicias para
                  recibir los diferentes beneficios de las diferentes marcas. El uso correcto de la tarjeta de crédito
                  es a 1 solo mes para que no te genere intereses y eso generará un buen historial en tu puntaje
                  crediticio. Igual manera revisa muy bien tu tarjeta para que recibas los diferentes beneficios.
                  <ul className="benefits-list">
                    <li>
                      <strong>Cashback:</strong> Algunas tarjetas ofrecen un porcentaje de reembolso en efectivo por
                      cada compra. Esto es como ganar dinero directamente en tus compras.
                    </li>
                    <li>
                      <strong>Puntos y Millas:</strong> Las tarjetas de crédito de recompensas acumulan puntos o millas
                      por cada compra, que pueden canjearse por vuelos, hoteles, tarjetas de regalo y más.
                    </li>
                    <li>
                      <strong>Seguros de Viaje y Compra:</strong> Algunas tarjetas ofrecen seguros de viaje gratuito y
                      protección contra robo o daño para las compras hechas con la tarjeta.
                    </li>
                    <li>
                      <strong>Garantía Extendida:</strong> Extiende la garantía de ciertos productos comprados con la
                      tarjeta, lo que puede ahorrarte dinero en reparaciones futuras.
                    </li>
                    <li>
                      <strong>Seguro alquiler de vehículo:</strong> El seguro de auto proporcionado por las tarjetas de
                      crédito es un beneficio común ofrecido por muchas compañías de tarjetas de crédito. Sin embargo,
                      las coberturas y las condiciones varían según la emisora de la tarjeta.
                    </li>
                  </ul>
                </li>
              )}
              {has_negative_report_tcs === true ||
                (time_negative_tcs >= 1 && time_negative_tcs <= 4 && (
                  <li className="highlight">
                    Tienes tarjeta en mora desde entre 3 meses y 4 años. Puedes llegar a una negociación por un monto
                    mucho menor de la deuda con la casa de cobranzas de la tarjeta.
                  </li>
                ))}
              {time_negative_tcs <= 5 && (
                <li className="highlight">
                  Tienes tarjeta en mora entre 5 o más años. Deudas de tarjetas de crédito en Colombia. Según la
                  legislación colombiana, las deudas generadas por tarjetas de crédito prescriben después de 5 años
                  desde la última vez que se realizó un pago, según lo establecido en el Código Civil y la Ley 1564 de
                  2012, también conocida como el Código General del Proceso. Esta prescripción implica que, después de
                  transcurrido el período de 5 años sin haber efectuado un pago o haber reconocido la deuda, el
                  prestamista pierde el derecho legal de exigir el pago de la deuda a través de acciones legales. Es
                  importante destacar que este plazo de prescripción aplica a partir del último pago o reconocimiento de
                  la deuda. Si durante este período no se realiza ningún pago ni se reconocen los saldos pendientes, la
                  deuda queda sujeta a la prescripción y no puede ser legalmente exigida. Si tienes alguna pregunta
                  adicional o necesitas más información sobre este tema, estaré encantado de proporcionar detalles
                  adicionales o referencias legales específicas.
                </li>
              )}
            </ul>
            <h3>2.2 Créditos bancarios libre inversión, vivienda, vehículo Crédito bancario</h3>
            <ul className="ul">
              {num_credit_banks >= 1 && num_credit_banks <= 2 && (
                <li>
                  Tienes un numero correcto de créditos. Lo óptimo es tener un crédito de vivienda o vehículo y uno de
                  libre inversión para un manejo financiero equilibrado y estable.
                </li>
              )}
              {num_credit_banks >= 3 && (
                <li>
                  Tienes un numero alto de créditos. Tener un alto número de créditos podría implicar una carga
                  financiera significativa. Es fundamental evaluar y consolidar deudas para evitar complicaciones y
                  mantener una salud financiera sólida y sostenible a largo plazo. La consolidación podría ser una
                  estrategia efectiva para simplificar tus pagos y mejorar tu capacidad crediticia.
                </li>
              )}
              {has_negative_report_credit_banks === true ||
                (time_negative_credit_banks >= 1 && time_negative_credit_banks <= 9 && (
                  <li>
                    Tienes crédito en mora entre 3 meses y 9 años puedes llegar a una negociación por un monto mucho
                    menor de la deuda con la casa de cobranzas del banco. Mas adelante te podemos ayudar.
                  </li>
                ))}
              {time_negative_credit_banks >= 10 && (
                <li>
                  Tienes crédito en mora desde entre 10 o más años las deudas de carácter civil y comerciales prescriben
                  en un plazo de 10 años a partir de la fecha en la cual se exige el pago. Esta prescripción implica
                  que, después de transcurrido el período de 10 años sin haber efectuado un pago o haber reconocido la
                  deuda, el prestamista pierde el derecho legal de exigir el pago de la deuda a través de acciones
                  legales. Es importante destacar que este plazo de prescripción aplica a partir del último pago o
                  reconocimiento de la deuda. Si durante este período no se realiza ningún pago ni se reconocen los
                  saldos pendientes, la deuda queda sujeta a la prescripción y no puede ser legalmente exigida. Si
                  tienen alguna pregunta adicional o necesitan más información sobre este tema, estaré encantado de
                  proporcionar detalles adicionales o referencias legales específicas
                </li>
              )}
              <h3>2.3 Crédito Sector Cooperativa.</h3>
              <p>
                Una cooperativa es la elección ideal para obtener los primeros créditos, financiar un emprendimiento,
                impulsar una pequeña empresa o iniciar tu historial crediticio de manera segura y apoyada. sí tiene
                créditos bancarios lo ideal es no tener ningún crédito en cooperativas o máximo uno.
              </p>
              {num_credit_coo === 1 && (
                <li>
                  Tienes el numero correcto de créditos, es más favorable optar por un solo crédito en una cooperativa.
                  Tener múltiples créditos bancarios puede perjudicar tu capacidad crediticia, por lo que es crucial
                  manejar tus préstamos con prudencia. Si no tiene créditos bancarios lo ideal es tener un máximo de 2
                  créditos
                </li>
              )}
              {num_credit_coo === 2 && (
                <li>
                  Tienes el numero correcto de créditos, Recuerda gestionar tus recursos sabiamente, asignándolos con
                  cuidado y siendo puntual en los pagos para mantener un historial crediticio sólido y evitar problemas
                  financieros en el futuro
                </li>
              )}
              {num_credit_coo >= 3 && (
                <li>
                  Tienes un numero alto de créditos. Tener un alto número de créditos podría implicar una carga
                  financiera significativa. Es fundamental evaluar y consolidar deudas para evitar complicaciones y
                  mantener una salud financiera sólida y sostenible a largo plazo. La consolidación podría ser una
                  estrategia efectiva para simplificar tus pagos y mejorar tu capacidad crediticia.
                </li>
              )}
            </ul>
            <h3>2.3 Créditos Sector Real</h3>
            <p>
              En el sector real, los créditos se emiten a través de comercios para la adquisición de productos como
              ropa, tenis, electrodomésticos y tecnología. Asimismo, las Fintech como EasyCredit Colombia facilitan
              préstamos en línea, ofreciendo a los consumidores opciones digitales para satisfacer sus necesidades
              financieras de manera eficiente y sin complicaciones.
            </p>
            <ul className="ul">
              {num_credit_real >= 1 && num_credit_real <= 2 && (
                <li>
                  Tienes el numero correcto de créditos. Aunque lo óptimo es prescindir de estos créditos, en ciertas
                  circunstancias surgen urgencias financieras o la necesidad de financiar compras como ropa o
                  electrodomésticos. En caso necesario, limita tus créditos a un máximo de 2. Recuerda ser puntual en
                  los pagos para evitar dificultades financieras y proteger tu historial crediticio.
                </li>
              )}
              {num_credit_real >= 3 && (
                <li>
                  Tienes un numero alto de créditos. Tener un alto número de créditos podría implicar una carga
                  financiera significativa. Es fundamental evaluar y consolidar deudas para evitar complicaciones y
                  mantener una salud financiera sólida y sostenible a largo plazo. La consolidación podría ser una
                  estrategia efectiva para simplificar tus pagos y mejorar tu capacidad crediticia.
                </li>
              )}
            </ul>
          </section>
          <section className="section">
            <h2 className="section-title">Sección 3: Información Personal</h2>
            <ul className="ul">
              {num_emails <= 2 && (
                <li>
                  Tienes número óptimo de direcciones Mantener una dirección estable y constante a lo largo del tiempo
                  es fundamental al solicitar créditos. Si cuentas con una dirección que se ha mantenido sin cambios
                  durante al menos cinco años y está registrada en varias entidades, esto genera una sensación de
                  confianza para las entidades de crédito. La consistencia en tu historial de residencia demuestra
                  estabilidad financiera y fiabilidad, lo que mejora significativamente tus posibilidades de ser
                  aprobado para un crédito. Al tener una dirección que ha perdurado en el tiempo y ha sido reportada en
                  múltiples fuentes, estableces una base sólida y confiable para cualquier solicitud crediticia que
                  realices en el futuro.
                </li>
              )}
              {time_cellphone <= 5 && (
                <li>
                  Tienes número óptimo de números de celular Mantener un numero de celular estable y constante a lo
                  largo del tiempo es fundamental al solicitar créditos. Si cuentas con un numero de celular que se ha
                  mantenido sin cambios durante al menos cinco años y está registrada en varias entidades, esto genera
                  una sensación de confianza para las entidades crediticias. La consistencia en tu historial de numero
                  de celular demuestra estabilidad financiera y fiabilidad, lo que mejora significativamente tus
                  posibilidades de ser aprobado para un crédito. Al tener un numero de celular que ha perdurado en el
                  tiempo y ha sido reportada en múltiples fuentes, estableces una base sólida y confiable para cualquier
                  solicitud crediticia que realices en el futuro
                </li>
              )}
              {time_addresses <= 4 && (
                <li>
                  Tienes número óptimo de números de correo electrónico Mantener un correo electrónico estable y
                  constante a lo largo del tiempo es fundamental al solicitar créditos. Si cuentas con un correo
                  electrónico que se ha mantenido sin cambios durante al menos cinco años y está registrada en varias
                  entidades, esto genera una sensación de confianza para las entidades crediticias. La consistencia en
                  tu historial de correo electrónico demuestra estabilidad financiera y fiabilidad, lo que mejora
                  significativamente tus posibilidades de ser aprobado para un crédito. Al tener un correo electrónico
                  que ha perdurado en el tiempo y ha sido reportada en múltiples fuentes, estableces una base sólida y
                  confiable para cualquier solicitud crediticia que realices en el futuro
                </li>
              )}
              {time_addresses >= 3 ||
                (time_cellphone >= 3 && (
                  <li>
                    Tienes un {time_addresses >= 3 && 'correo electrónico'} {time_cellphone >= 3 && 'numero de celular'}
                    nuevo lo que no genera confianza. Tener esta información relativamente nueva puede plantear dudas a
                    las entidades crediticias. Es fundamental mantener la misma información de contacto durante más de
                    cinco años para generar confianza. La continuidad en tus datos personales establece una base sólida,
                    mostrando estabilidad y fiabilidad financiera, lo que aumenta tu credibilidad y posibilidades de
                    aprobación crediticia. Al conservar tus datos a largo plazo, creas un historial confiable, vital al
                    solicitar créditos, construyendo así una reputación financiera sólida y duradera.
                  </li>
                ))}
            </ul>
          </section>
          <section className="section">
            <h2 className="section-title">Sección 4: Concejos</h2>
            <h3>Así subes tu puntaje en datacredito</h3>
            <ul className="ul">
              <li>Establecer una planificación financiera sólida.</li>
              <li>Cumplir con puntualidad todas las obligaciones económicas.</li>
              <li>Evitar el sobreendeudamiento, no excediendo la capacidad de pago.</li>
              <li>Utilizar la tarjeta de crédito de manera responsable y cautelosa.</li>
              <li>No utilizar el límite máximo de tus productos financieros.</li>
              <li>Ajustar los gastos de acuerdo con tus ingresos disponibles.</li>
              <li>Construir un historial crediticio positivo a lo largo del tiempo.</li>
              <li>Mantener el límite de las tarjetas de crédito por debajo del 70%.</li>
              <li>Optar por pagos diferidos en cuotas cuando sea posible.</li>
              <li>
                Mostrar un comportamiento financiero estable, cumpliendo con todas las responsabilidades financieras de
                manera honorable.
              </li>
            </ul>

            <h3>Así se pierde puntaje en datacredito</h3>
            <ul className="ul">
              <li>Ignorar los compromisos financieros establecidos.</li>
              <li>Experimentar retrasos en los pagos que superan los 30 días, incurriendo en mora.</li>
              <li>Tener tus créditos y tarjetas al tope máximo.</li>
              <li>Ser codeudor y enfrentar demoras en los pagos debido al incumplimiento del deudor principal.</li>
            </ul>
          </section>
        </div>
        <button onClick={handleDownloadPDF}>Descargar PDF</button>
      </div>
    </div>
  );
};

export default InformeCrediticio;
