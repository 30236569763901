/* eslint-disable no-plusplus */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { AppCurrentVisits } from '../../sections/@dashboard/app';

export const GraficoTotal = () => {
  const theme = useTheme();

  const { creditos } = useSelector((state) => state.historicos);
  const fechaActual = moment();
  const creditosFiltradoPorAño = creditos?.filter(
    (objeto) => moment(objeto.fecha_Solicitado).year() === fechaActual.year()
  );

  const desembolsados = creditosFiltradoPorAño.reduce((acc, obj) => acc + obj.desembolsado, 0);
  const rechazados = creditosFiltradoPorAño.reduce((acc, obj) => acc + obj.rechazado, 0);
  const aprobados = creditosFiltradoPorAño.reduce((acc, obj) => acc + obj.aprobado, 0);
  const pendientes = creditosFiltradoPorAño
    .filter((element) => element.desembolsado === null && element.adquirido === true)
    .reduce((acc, obj) => acc + obj.adquirido, 0);
  const solicitados = creditosFiltradoPorAño.reduce((acc, obj) => acc + obj.solicitud_Credito, 0);

  return (
    <AppCurrentVisits
      title="Créditos"
      chartData={[
        { label: 'Aprobados', value: aprobados },
        { label: 'por Adquirir', value: pendientes },
        { label: 'Solicitados', value: solicitados },
        { label: 'Rechazados', value: rechazados },
        { label: 'Desembolsado', value: desembolsados },
      ]}
      chartColors={[
        theme.palette.primary.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
      ]}
    />
  );
};
