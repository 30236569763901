import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  facturas: [],
};

export const facturasSlice = createSlice({
  name: 'facturas',
  initialState,
  reducers: {
    cargarFacturas: (state, action) => {
      state.facturas = action.payload;
    },
  },
});

export const { cargarFacturas } = facturasSlice.actions;
