import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  creditos: [],
};

export const contadorCreditosSlice = createSlice({
  name: 'contadorCreditos',
  initialState,
  reducers: {
    creditosActivos: (state, action) => {
      state.creditos = action.payload;
      state.loading = false;
    },
  },
});

export const { creditosActivos } = contadorCreditosSlice.actions;
