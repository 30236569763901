import { Navigate, useRoutes } from 'react-router-dom';
import Reset from '../../../client/components/Reset';
import RecuperarClave from '../../../client/pages/RecuperarClave';

import LoginPage from '../../../pages/LoginPage';

import RegisterPage from '../../../pages/RegisterPage';

// ----------------------------------------------------------------------

export default function ClientPublicRoutes() {
  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'registro',
      element: <RegisterPage />,
    },
    {
      path: 'recuperar-cta',
      element: <RecuperarClave />,
    },
    {
      path: '/recuperar-clave/:token',
      element: <Reset />,
    },
  ]);

  return routes;
}
