/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { consultarHistoricos } from '../../../store/clientUser/credito/thunk';

import { DatosHistorico } from './DatosHistorico';
import GenerarDocumento from './PazySalvo';

export const Historico = () => {
  const { historicos: historico } = useSelector((state) => state.creditoCL);

  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.cuentaCL.cuenta);

  React.useEffect(() => {
    dispatch(consultarHistoricos());
  }, []);

  const fecha = moment();

  // Formatear la fecha según el formato deseado
  const fechaFormateada = fecha.format('Do [del mes de] MMMM [de] YYYY');
  const hoy = fechaFormateada.replace(/\bº/g, '');

  const resultado = historico && !historico.some((obj) => obj.cancelado !== true);

  return (
    <Box
      sx={{
        mx: 'auto',
      }}
    >
      {resultado && (
        <GenerarDocumento
          nombre={`${usuario.primerNombre} ${usuario.primerApellido}`}
          cedula={usuario.cedula}
          fecha={hoy}
        />
      )}
      <Paper className="container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Crédito N.</TableCell>
              <TableCell>Fecha de Solicitud</TableCell>
              <TableCell>Monto Solicitado</TableCell>
              <TableCell>Monto Aprobado</TableCell>
              <TableCell>Plazo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historico?.length === 0 || historico === undefined
              ? 'No hay datos'
              : historico?.map((credito, index) => (
                  <DatosHistorico credito={credito} key={credito?.id} index={index} />
                ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};
