import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userAdmin: '',
  logueado: false,
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    cargarAdmin: (state, action) => {
      state.userAdmin = action.payload;
      state.logueado = true;
    },

    cerrarSesionAmd: (state) => {
      state.userAdmin = null;
      state.logueado = false;
    },
  },
});

export const { cargarAdmin, cerrarSesionAmd } = adminSlice.actions;
