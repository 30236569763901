/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { useSelector } from 'react-redux';
import {
  ACCESOS_CONSULTAS_COMPROMISOS_GENERAL,
  ACCESOS_CONSULTAS_COMPROMISOS_POR_VENCER,
  ACCESOS_CONSULTAS_COMPROMISOS_VENCIDOS,
  ACCESOS_GESTOR_ATRAZADOS,
  ACCESOS_GESTOR_CLAVES,
  ACCESOS_GESTOR_CREDITO,
  ACCESOS_GESTOR_DATOS,
  ACCESOS_GESTOR_PAGOS_COMPROMISOS,
  ACCESOS_GESTOR_PAGOS_CREDITOS,
  ACCESOS_INFORMES_DATACREDITO,
  ACCESOS_HOME,
  ACCESOS_INFORMES_DAVIVIENDA,
  ACCESOS_INFORMES_FACTURACION,
  ACCESOS_INFORMES_GESTION,
  ACCESOS_INFORMES_HISTORICOS,
} from '../../constants/permisos';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

const hasAccess = (title, rol) => {
  if (title === 'Informe Davivienda' && ACCESOS_INFORMES_DAVIVIENDA.includes(rol)) return true;
  if (title === 'Informe Histórico' && ACCESOS_INFORMES_HISTORICOS.includes(rol)) return true;
  if (title === 'Informe de Gestión' && ACCESOS_INFORMES_GESTION.includes(rol)) return true;
  if (title === 'Gestor Bienestar Financiero' && ACCESOS_INFORMES_GESTION.includes(rol)) return true;
  if (title === 'Listado Informe Bienestar Financiero' && ACCESOS_INFORMES_GESTION.includes(rol)) return true;
  if (title === 'Informe de Facturación' && ACCESOS_INFORMES_FACTURACION.includes(rol)) return true;
  if (title === 'Listado de compromisos vencidos' && ACCESOS_CONSULTAS_COMPROMISOS_VENCIDOS.includes(rol)) return true;
  if (title === 'Listado de compromisos por vencer' && ACCESOS_CONSULTAS_COMPROMISOS_POR_VENCER.includes(rol))
    return true;
  if (title === 'Listado de compromisos' && ACCESOS_CONSULTAS_COMPROMISOS_GENERAL.includes(rol)) return true;
  if (title === 'Gestor de Créditos Atrasados' && ACCESOS_GESTOR_ATRAZADOS.includes(rol)) return true;
  if (title === 'Gestor de Créditos' && ACCESOS_GESTOR_CREDITO.includes(rol)) return true;
  if (title === 'Gestor de datos' && ACCESOS_GESTOR_DATOS.includes(rol)) return true;
  if (title === 'Gestor de Claves' && ACCESOS_GESTOR_CLAVES.includes(rol)) return true;
  if (title === 'Gestor de Compromisos' && ACCESOS_GESTOR_PAGOS_COMPROMISOS.includes(rol)) return true;
  if (title === 'Inicio' && ACCESOS_HOME.includes(rol)) return true;
  if (title === 'Gestor de Pagos' && ACCESOS_GESTOR_PAGOS_CREDITOS.includes(rol)) return true;
  if (title === 'Informe Datacredito' && ACCESOS_INFORMES_DATACREDITO.includes(rol)) return true;

  return false;
};
export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} disabled />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const { rol } = useSelector((state) => state.userAdmin.userAdmin);
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      disabled={!hasAccess(title, rol)}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
