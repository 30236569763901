/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Button, Grid, Icon, Paper } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { formateador } from '../../helper/Formateador';

function createData(creditos = []) {
  return creditos?.map((credito) => ({
    id: credito?.id,
    montoSolicitado: credito?.valor_Solicitado,
    montoAprobado: credito?.valor_Aprobado,
    fechaSolicitado: credito?.fecha_Solicitado,
    fechaDesembolsado: credito?.fecha_Desembolsado,
    fechaCancelado: credito?.fecha_Cancelado,
    dias_Prestamo: credito?.dias_Prestamo,
    history: credito?.renovacions?.map((renovacion) => ({
      id: renovacion?.id,
      dias: renovacion?.dias_Prestamo_renovado,
      administracion: renovacion?.administracion_renovado,
      plataforma: renovacion?.plataforma_renovado,
      iva: renovacion?.iva_renovado,
      interes: renovacion?.interes_Corriente_renovado,
      fechaRenovado: renovacion?.fecha_Desembolsado_renovado,
    })),
  }));
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ width: '100%', '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          className={'classes.none'}
          sx={{
            visibility: row?.history?.length === 0 && 'hidden',
          }}
        >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          C{row.id}
        </TableCell>
        <TableCell align="center">$ {formateador(row.montoSolicitado)}</TableCell>
        <TableCell align="center">$ {formateador(row.montoAprobado)}</TableCell>
        <TableCell align="center">{moment(row.fechaSolicitado).format('ll')}</TableCell>
        <TableCell align="center">
          {row.fechaDesembolsado === null ? '-' : moment(row.fechaDesembolsado).format('ll')}
        </TableCell>
        <TableCell align="center">
          {row.fechaCancelado === null ? '-' : moment(row.fechaCancelado).format('ll')}
        </TableCell>
        <TableCell align="center">
          {row?.dias_Prestamo} {row.dias_Prestamo === 1 ? 'día' : 'Dias'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Historial Renovaciones
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Dias Inicialmente</TableCell>
                    <TableCell align="center">Desembolso / Renovado</TableCell>
                    <TableCell align="center">Administración</TableCell>
                    <TableCell align="center">Plataforma</TableCell>
                    <TableCell align="center">Iva</TableCell>
                    <TableCell align="center">Intereses</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.history?.map((historyRow) => (
                    <TableRow key={historyRow.id}>
                      <TableCell component="th" scope="row" align="center">
                        {historyRow.dias} {historyRow.dias_Prestamo === 1 ? 'día' : 'Dias'}
                      </TableCell>
                      <TableCell align="center">
                        {historyRow.fechaRenovado === null ? '-' : moment(historyRow.fechaRenovado).format('ll')}
                      </TableCell>
                      <TableCell align="center">$ {formateador(historyRow.administracion)}</TableCell>
                      <TableCell align="center">$ {formateador(historyRow.plataforma)}</TableCell>
                      <TableCell align="center">$ {formateador(historyRow.iva)}</TableCell>
                      <TableCell align="center">$ {formateador(historyRow.interes)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function HistoricoCliente() {
  const navigate = useNavigate();
  const { historicoClienteEditable, loadingHistoricoClienteEditable } = useSelector((state) => state.historicos);
  const rows = createData(historicoClienteEditable);
  function handleClick() {
    navigate(-1);
  }
  return loadingHistoricoClienteEditable ? (
    <Grid>
      <Grid display="flex" justifyContent="center" alignItems="end">
        <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
      </Grid>
    </Grid>
  ) : (
    <>
      <TableContainer component={Paper} sx={{ width: '90%', margin: '0 auto' }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Credito N.</TableCell>
              <TableCell align="center">Monto Solicitado</TableCell>
              <TableCell align="center">Monto Aprobado</TableCell>
              <TableCell align="center">Fecha Solicitado</TableCell>
              <TableCell align="center">Fecha Desembolsado</TableCell>
              <TableCell align="center">Fecha Cancelado</TableCell>
              <TableCell align="center">Tiempo Solicitado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        sx={{
          margin: 3,
          marginLeft: '63px',
        }}
        color="warning"
        component={Link}
        onClick={handleClick}
      >
        Regresar
      </Button>
    </>
  );
}
