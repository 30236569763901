/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import { CssBaseline, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { actualizarUsuario } from '../../store';
import InformacionPersonal from '../components/Editarquestionario/InformacionPersonal';
import InformacionLaboral from '../components/Editarquestionario/InformacionLaboral';
import InformacionReferencias from '../components/Editarquestionario/InformacionReferencias';
import { hoy } from '../../helper/funtions';
import InformacionResidencial from '../components/Editarquestionario/InformacionResidencial';

const steps = ['Personal - 1', 'Personal - 2', 'Laboral', 'Referencias'];

export default function EditarQuestionario() {
  const cuenta = useSelector((state) => state.cuentaCL.cuenta);

  const [informacionPersonal, setInformacionPersonal] = useState({
    primerNombre: cuenta.primerNombre,
    segundoNombre: cuenta.segundoNombre,
    primerApellido: cuenta.primerApellido,
    segundoApellido: cuenta.segundoApellido,
    cedula: cuenta.cedula,
    celular: cuenta.celular,
    genero: cuenta.genero,
    nivel_de_estudios: cuenta.nivel_de_estudios,
    ciudad_expedicion_cedula: cuenta.ciudad_expedicion_cedula,
    fecha_de_nacimiento: cuenta.fecha_de_nacimiento,
    fecha_de_expedicion_cedula: cuenta.fecha_de_expedicion_cedula,
    numero_personas_a_cargo: cuenta.numero_personas_a_cargo,
  });

  const [informacionResidencial, setInformacionResidencial] = useState({
    direccion_residencia: cuenta.direccion_residencia,
    ciudad_residencia: cuenta.ciudad_residencia,
    estrato: cuenta.estrato,
    tiempo_en_vivienda: cuenta.tiempo_en_vivienda,
    tipo_vivienda: cuenta.tipo_vivienda,
  });

  const [preguntasEmpleados, setPreguntasEmpleados] = useState({
    nombre_empresa: cuenta.nombre_empresa,
    cargo: cuenta.cargo,
    tipo_contrato: cuenta.tipo_contrato,
    ciudad_trabajo: cuenta.ciudad_trabajo,
    direccion_trabajo: cuenta.direccion_trabajo,
    telefono_trabajo: cuenta.telefono_trabajo,
    fecha_de_ingreso_empresa: cuenta.fecha_de_ingreso_empresa,
    eps: cuenta.eps,
  });

  const [PreguntasXIndependientes, setPreguntasXIndependientes] = useState({
    actividad_economica: cuenta.actividad_economica,
    nombre_empresa_independiente: cuenta.nombre_empresa_independiente,
    ciudad_empresa_independiente: cuenta.ciudad_empresa_independiente,
    direccion_empresa_independiente: cuenta.direccion_empresa_independiente,
    telefono_empresa_independiente: cuenta.telefono_empresa_independiente,
    tiempo_empresa_independiente: cuenta.tiempo_empresa_independiente,
  });

  const [preguntasPensionados, setPreguntasPensionados] = useState({
    fecha_resolucion_pension: hoy,
    fondo_pensional: cuenta.fondo_pensional,
    causa_pension: cuenta.causa_pension,
  });

  const [preguntasDesempleado, setPreguntasDesempleado] = useState({
    tiempo_desempleado: '',
  });

  const [informacionReferencias, setInformacionReferencias] = useState({
    // referencia_familiar_nombres: cuenta.referencia_familiar_nombres,
    // referencia_familiar_apellidos: cuenta.referencia_familiar_apellidos,
    // referencia_familiar_ciudad: cuenta.referencia_familiar_ciudad,
    // referencia_familiar_telefono: cuenta.referencia_familiar_telefono,
    // referencia_familiar_parentesco: cuenta.referencia_familiar_parentesco,
    referencia_personal_nombres: cuenta.referencia_personal_nombres,
    referencia_personal_apellidos: cuenta.referencia_personal_apellidos,
    referencia_personal_ciudad: cuenta.referencia_personal_ciudad,
    referencia_personal_telefono: cuenta.referencia_personal_telefono,
  });

  const dispatch = useDispatch();

  const enviar = () => {
    dispatch(
      actualizarUsuario({
        ...informacionPersonal,
        ...informacionResidencial,
        ...preguntasEmpleados,
        ...PreguntasXIndependientes,
        ...PreguntasXIndependientes,
        ...preguntasPensionados,
        ...preguntasDesempleado,
        ...informacionReferencias,
      })
    );

    // crearInformacion(datos);
    // props.history.push('/inicio');
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <InformacionPersonal
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={steps}
            registroInfoPersonal={informacionPersonal}
            setInformacionPersonal={setInformacionPersonal}
          />
        );
      case 1:
        return (
          <InformacionResidencial
            informacionResidencial={informacionResidencial}
            setInformacionResidencial={setInformacionResidencial}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            setActiveStep={setActiveStep}
          />
        );
      case 2:
        return (
          <InformacionLaboral
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            preguntasEmpleados={preguntasEmpleados}
            setPreguntasEmpleados={setPreguntasEmpleados}
            PreguntasXIndependientes={PreguntasXIndependientes}
            setPreguntasXIndependientes={setPreguntasXIndependientes}
            preguntasPensionados={preguntasPensionados}
            setPreguntasPensionados={setPreguntasPensionados}
            preguntasDesempleado={preguntasDesempleado}
            setPreguntasDesempleado={setPreguntasDesempleado}
          />
        );
      case 3:
        return (
          <InformacionReferencias
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            registroInfoReferencias={informacionReferencias}
            setInformacionReferencias={setInformacionReferencias}
            enviar={enviar}
          />
        );

      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <CssBaseline />

      <main>
        <Paper sx={{ padding: 3 }}>
          <Typography component="h1" variant="h4" align="center">
            Información
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep !== steps.length && <>{getStepContent(activeStep)}</>}
        </Paper>
      </main>
    </>
  );
}
