/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import Sello from '../sello/Sello';
import { hoy } from '../../../helper/funtions';
import { editarCredito } from '../../../store/clientUser/credito/thunk';

const FirmarContrato = () => {
  const dispatch = useDispatch();

  const credito = useSelector((state) => state?.creditoCL.creditoActivo);

  const [data, setData] = useState(credito);
  const [firmas, setFirmas] = useState({
    corta: '',
    larga: '',
  });

  const handleChage = (e) => {
    setFirmas({
      ...firmas,
      [e.target.name]: e.target.value,
    });
    setData({
      ...data,
      fecha_Firma: hoy,
    });
  };

  const { corta, larga } = firmas;

  const enviarFirma = async (e) => {
    e.preventDefault();

    if (corta.trim() === '' || larga.trim() === '') {
      Swal.fire({
        icon: 'error',
        title: 'Debes diligenciar ambas firmas',
      });
      return;
    }
    if (firmas.corta.trim() !== credito.firma_Corta || firmas.larga.trim() !== credito.firma_Larga) {
      Swal.fire({
        icon: 'error',
        title: 'Los codigos son incorrectos',
      });
      return;
    }
    dispatch(editarCredito(credito.id, data));
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box component="form" sx={{ marginTop: '20px' }} onSubmit={enviarFirma}>
            <TextField
              id="corta"
              label="Clave Corta"
              variant="outlined"
              name="corta"
              value={firmas.corta}
              sx={{ margin: '10px !important' }}
              onChange={handleChage}
              disabled={credito.fecha_Firma !== null}
            />
            <TextField
              id="larga"
              label="Clave Larga"
              variant="outlined"
              name="larga"
              value={firmas.larga}
              sx={{ margin: '10px !important' }}
              onChange={handleChage}
              disabled={credito.fecha_Firma !== null}
            />
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: '10px !important' }}
              type="onsubmit"
              disabled={credito.fecha_Firma !== null}
            >
              {credito.fecha_Firma ? 'Firmado' : 'Firmar'}
            </Button>
          </Box>
          <br />
          <br />
          Clave Larga: <strong>{credito.firma_Larga}</strong>
          {credito.fecha_Firma && (
            <Sello clase="stamp" mensaje={`Firmado en ${moment(credito.fecha_Firma).format('l')}`} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
export default FirmarContrato;
