import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  logueado: false,
  censado: false,
  email: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.email = action.payload.name;
      state.censado = action.payload.censado;
      state.logueado = action.payload.logueado;
    },
    censado: (state) => {
      state.censado = true;
    },
    cerrarSesion: (state) => {
      state.logueado = false;
      state.censado = false;
      state.email = null;
    },
  },
});

export const { login, censado, cerrarSesion } = authSlice.actions;
