/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import './estilos.css';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Card, Grid, Paper, Typography } from '@mui/material';

function Contador() {
  const { fecha_Limite_Pago, fecha_Cancelado, cancelado } = useSelector((state) => state.creditoCL.creditoActivo);

  const difference = +new Date(fecha_Limite_Pago) - +new Date();

  const calculateTimeLeft = () => {
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
        Horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
        Minutos: Math.floor((difference / 1000 / 60) % 60),
        Segundos: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{' '}
      </span>
    );
  });

  return (
    <>
      {timeLeft.dias !== undefined ? (
        <Card sx={{ flexGrow: 1, padding: 3 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
                Tu crédito vence el {moment(fecha_Limite_Pago).format('ll')}
              </Typography>
            </Grid>

            {fecha_Cancelado !== null && cancelado === null ? (
              <Typography>Tu Pago se encuentra en verificación.</Typography>
            ) : (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <div>Días</div>
                  <div>{timeLeft.dias}</div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <div>Horas</div>
                  <div>{timeLeft.Horas}</div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <div>Minutos</div>
                  <div>{timeLeft.Minutos}</div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div>Segundos</div>
                  <div>{timeLeft.Segundos}</div>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      ) : (
        <Paper
          sx={{
            margin: '0 auto',
            padding: 2,
          }}
        >
          <h1 className="tmora">Tu crédito se encuentra en mora.</h1>
        </Paper>
      )}
    </>
  );
}

export default Contador;
