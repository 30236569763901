/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { ciudadesSelect } from '../../constants/ciudades';
// eslint-disable-next-line import/no-unresolved

const InformacionPersonal = ({ clienteInfo, setInformacionPersonal }) => {
  const classes = {};

  const handleDatosChange = (e) => {
    setInformacionPersonal((anterior) => ({
      ...anterior,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInput = ({ target }) => {
    setInformacionPersonal((anterior) => ({
      ...anterior,
      [target.name]: target.value,
    }));
  };

  const handleFechaExpedicion = (e) => {
    setInformacionPersonal((anterior) => ({
      ...anterior,
      fecha_de_expedicion_cedula: e,
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Primer Nombre"
              variant="filled"
              name="primerNombre"
              value={clienteInfo?.primerNombre}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Segundo Nombre"
              variant="filled"
              name="segundoNombre"
              value={clienteInfo?.segundoNombre}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Primer Apellido"
              variant="filled"
              name="primerApellido"
              value={clienteInfo?.primerApellido}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Segundo Apellido"
              variant="filled"
              name="segundoApellido"
              value={clienteInfo?.segundoApellido}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Numero de Cedula"
              variant="filled"
              name="cedula"
              value={clienteInfo?.cedula}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth variant="filled">
            <DemoContainer components={['DatePicker', 'DatePicker']} sx={{ padding: 0 }}>
              <DatePicker
                sx={{ width: '100% !important' }}
                fullWidth
                label="Fecha de expedición de la cedula"
                name="fecha_de_expedicion_cedula"
                // value={clienteInfo?.fecha_de_expedicion_cedula}
                onChange={handleFechaExpedicion}
                renderInput={(params) => <TextField {...params} />}
              />
            </DemoContainer>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Direccion de residencia"
              variant="filled"
              name="direccion_residencia"
              value={clienteInfo?.direccion_residencia}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Celular"
              variant="filled"
              name="celular"
              value={clienteInfo?.celular}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel>Ciudad</InputLabel>
            <Select
              id="ciudad_residencia"
              name="ciudad_residencia"
              onChange={handleDatosChange}
              value={clienteInfo?.ciudad_residencia}
            >
              {ciudadesSelect.map((ciudad) => (
                <MenuItem value={ciudad.codigo} key={ciudad.codigo + ciudad.nombre}>
                  {ciudad.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="estrato">Estrato</InputLabel>
            <Select
              required
              inputProps={{
                id: 'estrato',
                tabindex: '0',
              }}
              labelId="estrato"
              value={clienteInfo?.estrato}
              name="estrato"
              onChange={handleSelectInput}
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="tipo_vivienda">Tipo de Vivienda</InputLabel>
            <Select
              required
              labelId="tipo_vivienda"
              value={clienteInfo?.tipo_vivienda}
              name="tipo_vivienda"
              onChange={handleSelectInput}
            >
              <MenuItem value="propia">Propia</MenuItem>
              <MenuItem value="arrendada">Arrendada</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="nivel_de_estudios">Nivel de Estudios</InputLabel>
            <Select
              labelId="nivel_de_estudios"
              value={clienteInfo?.nivel_de_estudios}
              name="nivelEstudio"
              onChange={handleSelectInput}
              required
            >
              <MenuItem value="primaria">Básica Primaria</MenuItem>
              <MenuItem value="bachiller">Bachiller</MenuItem>
              <MenuItem value="tecnico">Técnico</MenuItem>
              <MenuItem value="tecnologo">Tecnologo</MenuItem>
              <MenuItem value="profesional">Profesional</MenuItem>
              <MenuItem value="doctorado">Doctorado</MenuItem>
              <MenuItem value="sinEstudio">Sin Estudios</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="sexo_usuario">Sexo</InputLabel>
            <Select
              labelId="sexo_usuario"
              name="genero"
              onChange={handleSelectInput}
              value={clienteInfo?.genero}
              required
            >
              <MenuItem value="masculino">Masculino</MenuItem>
              <MenuItem value="femenino">Femenino</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Correo Electronico"
              variant="filled"
              name="email"
              disabled
              value={clienteInfo?.email}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="select-banco">Banco</InputLabel>
            <Select
              required
              labelId="select-banco"
              name="banco"
              value={clienteInfo?.banco}
              onChange={handleSelectInput}
            >
              <MenuItem value="40">BANCO AGRARIO</MenuItem>
              <MenuItem value="52">BANCO AV VILLAS</MenuItem>
              <MenuItem value="13">BANCO BBVA COLOMBIA S.A </MenuItem>
              <MenuItem value="32">BANCO CAJA SOCIAL</MenuItem>
              <MenuItem value="67">BANCO COOPERATIVO COOPCENTRAL</MenuItem>
              <MenuItem value="51">BANCO DAVIVIENDA</MenuItem>
              <MenuItem value="01">BANCO DE BOGOTÁ</MenuItem>
              <MenuItem value="23">BANCO DE OCCIDENTE</MenuItem>
              <MenuItem value="12">BANCO DE GNB SUDAMERIS</MenuItem>
              <MenuItem value="06">BANCO ITAU</MenuItem>
              <MenuItem value="02">BANCO POPULAR</MenuItem>
              <MenuItem value="63">BANCO FINANDINA</MenuItem>
              <MenuItem value="66">BANCO SANTANDER COLOMBIA</MenuItem>
              <MenuItem value="69">BANCO SERFINANZA</MenuItem>
              <MenuItem value="07">BANCOLOMBIA</MenuItem>
              <MenuItem value="36">CONFIAR COOPERATIVA FINANCIERA</MenuItem>
              <MenuItem value="19">SCOTIABANK COLPATRIA</MenuItem>
              <MenuItem value="61">BANCOOMEVA</MenuItem>
              <MenuItem value="62">BANCO FALABELLA</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Numero de Cuenta"
              variant="filled"
              name="numero_cuenta"
              value={clienteInfo?.numero_cuenta}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="tipo-cuenta">Tipo de Cuenta</InputLabel>
            <Select
              required
              labelId="tipo-cuenta"
              name="tipo_cuenta"
              value={clienteInfo?.tipo_cuenta}
              onChange={handleSelectInput}
            >
              <MenuItem value="ahorros">Ahorros</MenuItem>
              <MenuItem value="corriente">Corriente</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <InputLabel id="demo-simple-select-label">Posee Tarjeta de credito?</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              name="tiene_tarjeta"
              value={clienteInfo?.tiene_tarjeta}
              onChange={handleSelectInput}
            >
              <MenuItem value="si">Sí</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Ingresos Mensuales"
              variant="filled"
              name="ingresos_Mensuales"
              value={clienteInfo?.ingresos_Mensuales}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6} className={classes.form}>
          <FormControl fullWidth className={classes.margin} variant="filled">
            <TextField
              id="filled-read-only-input"
              label="Egresos Mensuales"
              variant="filled"
              name="gastos_Mensuales"
              value={clienteInfo?.gastos_Mensuales}
              onChange={handleDatosChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default InformacionPersonal;
