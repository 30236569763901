/* eslint-disable import/order */
/* eslint-disable react/jsx-no-bind */
import {
  Alert,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { MAIN_PATH, PATH_NAME } from '../../constants';
import React, { useState } from 'react';
import { cambioLoadingClarisa, startActualizarCreditos } from '../../store';
import { useDispatch, useSelector } from 'react-redux';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { NumericFormatCustom } from '../../components/numericForm/NumericForm';
import ReplyIcon from '@mui/icons-material/Reply';
import Swal from 'sweetalert2';
import { calculosDelCredito } from '../../helper/liquidarCredito';
import { formateador } from '../../helper/Formateador';
import { hoy } from '../../helper/funtions';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

const DetalleCancelarCredito = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const credito = useSelector((state) => state?.creditosActivos?.creditoEditable);
  const { loadingClarisa } = useSelector((state) => state?.creditosActivos);
  const {
    montoAprobando,
    valorAdministracion,
    valorPlataforma,
    valorPrimerCobranza,
    valorIva,
    interesCorriente,
    interesPorMora,
    valorSegundaCobranza,
    valorTotalParaPago,
  } = calculosDelCredito(credito);

  function handleClick() {
    navigate(-1);
  }

  const [creditoACancelar, setCreditoACancelar] = useState({
    ...credito,
    fecha_Cancelado: hoy,
    plataforma: valorPlataforma,
    interes_Corriente: interesCorriente,
    administracion: valorAdministracion,
    iva: valorIva,
    valor_Cancelado_Capital: montoAprobando,
    cobranza_Inicial: valorPrimerCobranza,
    cobranza_Segunda: valorSegundaCobranza,
    interes_Moratorio: interesPorMora,
    cancelado: true,
    paraCancelar: true,
  });

  const handleChange = ({ target }) => {
    setCreditoACancelar({
      ...creditoACancelar,
      [target.name]: Number(target.value),
    });
  };

  const handleMetodoPagoChange = (event) => {
    setCreditoACancelar({
      ...creditoACancelar,
      metodo_Cancelacion: event.target.value,
    });
  };

  const efectuarPago = async (e) => {
    e.preventDefault();

    if (creditoACancelar.fecha_Cancelado === null) {
      Swal.fire({
        icon: 'error',
        title: 'Debes diligenciar la fecha de Pago',
      });
      return;
    }

    if (creditoACancelar.facturar === 'true' && creditoACancelar.metodo_Cancelacion === null) {
      Swal.fire({
        icon: 'error',
        title: 'Debes elegir un metodo de pago.',
      });
      return;
    }

    Swal.fire({
      title: '¿Está seguro de finalizar este crédito???',
      text: 'Esta acción marcará como cancelado el crédito',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No, Cancelar',
    }).then(async (result) => {
      if (result.value) {
        dispatch(cambioLoadingClarisa(true));
        try {
          await dispatch(startActualizarCreditos(creditoACancelar));
          dispatch(cambioLoadingClarisa(false));

          if (loadingClarisa) {
            navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
          }
        } catch (error) {
          console.error(error);
          dispatch(cambioLoadingClarisa(false));
        }
      }
      navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
    });
  };

  const handleFacturarChange = (event) => {
    if (event.target.value === 'false') {
      setCreditoACancelar({
        ...creditoACancelar,
        facturar: event.target.value,
        metodo_Cancelacion: null,
        paraCancelar: null,
      });
    }
    setCreditoACancelar({ ...creditoACancelar, facturar: event.target.value });
  };

  return (
    <>
      {credito?.fecha_Cancelado !== null && credito?.cancelado === null && (
        <Alert severity="warning">Pago realizado por el cliente, pendiente verificación.</Alert>
      )}

      {loadingClarisa ? (
        <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 1.7 }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      ) : (
        <form onSubmit={efectuarPago}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container>
              <FormControl sx={{ mx: 'auto' }}>
                <Grid container sx={{ display: 'flex' }}>
                  <Grid sx={{ margin: 2 }}>
                    <Box>
                      <Card sx={{ padding: 3, lineHeight: 'normal', fontSize: 12 }}>
                        <h4>Detalle Pago:</h4>
                        <p>Credito: $ {formateador(montoAprobando)}</p>
                        <p>Administración: $ {formateador(valorAdministracion)}</p>
                        <p>Plataforma: $ {formateador(valorPlataforma)}</p>
                        <p>IVA: $ {formateador(valorIva)}</p>
                        <p>Intereses: $ {formateador(interesCorriente)}</p>
                        {interesPorMora > 0 && <p>Intereses por mora: $ {formateador(interesPorMora)}</p>}
                        {valorPrimerCobranza > 0 && <p>Primera Cobranza: $ {formateador(valorPrimerCobranza)}</p>}
                        {valorSegundaCobranza > 0 && <p>Primera Cobranza: $ {formateador(valorSegundaCobranza)}</p>}
                        <p>Total: $ {formateador(valorTotalParaPago)}</p>
                      </Card>
                    </Box>
                  </Grid>

                  <Paper sx={{ mx: 'auto' }} component="form">
                    <Box sx={{ mx: 'auto', width: 600, p: 5 }}>
                      <Typography variant="h6" gutterBottom>
                        Cancelación Manual de Crédito:
                      </Typography>
                      <List disablePadding>
                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Capital" secondary={`$ ${formateador(montoAprobando)}`} />
                          <TextField
                            focused
                            required
                            label="Pago Capital"
                            name="valor_Cancelado_Capital"
                            onChange={handleChange}
                            value={creditoACancelar?.valor_Aprobado}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>
                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText
                            primary="Plataforma"
                            secondary={`$ ${formateador(valorPlataforma)} ${
                              credito?.descuento_Plataforma ? 'Tiene un descuento aplicado.' : ''
                            }`}
                          />
                          <TextField
                            focused
                            label="Pago Plataforma"
                            name="plataforma"
                            required
                            onChange={handleChange}
                            value={creditoACancelar.plataforma}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>
                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText
                            primary="Administración"
                            secondary={`$ ${formateador(valorAdministracion)} ${
                              credito?.descuento_Administracion ? 'Tiene un descuento aplicado.' : ''
                            }`}
                          />
                          <TextField
                            required
                            focused
                            label="Pago Administración"
                            name="administracion"
                            onChange={handleChange}
                            value={creditoACancelar?.administracion}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Interes" secondary={`$ ${formateador(interesCorriente)}`} />
                          <TextField
                            label="Pago Interes"
                            required
                            focused
                            name="interes_Corriente"
                            onChange={handleChange}
                            value={creditoACancelar?.interes_Corriente}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Iva" secondary={`$ ${formateador(valorIva)}`} />
                          <TextField
                            label="Pago Iva"
                            name="iva"
                            required
                            focused
                            onChange={handleChange}
                            value={creditoACancelar?.iva}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Cobranza 1" secondary={`$ ${formateador(valorPrimerCobranza)}`} />
                          <TextField
                            label="Cobranza 1"
                            name="cobranza_Inicial"
                            required
                            focused
                            onChange={handleChange}
                            value={creditoACancelar?.cobranza_Inicial}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Cobranza 2" secondary={`$ ${formateador(valorSegundaCobranza)}`} />
                          <TextField
                            label="Cobranza 2"
                            name="cobranza_Segunda"
                            required
                            focused
                            onChange={handleChange}
                            value={creditoACancelar?.cobranza_Segunda}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Interes por Mora" secondary={`$ ${formateador(interesPorMora)}`} />
                          <TextField
                            label="Interes por Mora"
                            name="interes_Moratorio"
                            required
                            focused
                            onChange={handleChange}
                            value={creditoACancelar?.interes_Moratorio}
                            variant="filled"
                            InputProps={{
                              inputComponent: NumericFormatCustom,
                            }}
                          />
                        </ListItem>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText
                            primary="Fecha Oportuna de Pago"
                            secondary={moment(credito?.fecha_Limite_Pago).format('l')}
                          />

                          <DatePicker
                            name="fecha_Cancelado"
                            label="Fecha de Pago"
                            fullWidth
                            sx={{ width: '100%' }}
                            onChange={(newValue) => {
                              setCreditoACancelar({
                                ...creditoACancelar,
                                fecha_Cancelado: newValue,
                              });
                            }}
                          />
                        </ListItem>

                        <FormControl
                          sx={{
                            '& > :not(style)': { width: '300px' },
                          }}
                        >
                          <FormLabel id="demo-row-radio-buttons-group-label">Facturar?</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              checked={creditoACancelar?.facturar === 'true'}
                              value="true"
                              control={<Radio />}
                              label="Sí"
                              onChange={handleFacturarChange}
                            />
                            <FormControlLabel
                              checked={creditoACancelar?.facturar === 'false'}
                              value={'false'}
                              onChange={handleFacturarChange}
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div
                          className={
                            creditoACancelar?.facturar === 'true'
                              ? `animate__animated animate__fadeIn `
                              : 'animate__animated animate__fadeOut'
                          }
                        >
                          <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                              Modo de Pago
                            </InputLabel>
                            <NativeSelect
                              onChange={handleMetodoPagoChange}
                              inputProps={{
                                name: 'metodo_Cancelacion',
                              }}
                            >
                              <option value="" />
                              <option value="47">Transferencia</option>
                              <option value="10">Efectivo</option>
                            </NativeSelect>
                          </FormControl>
                        </div>

                        <ListItem sx={{ py: 1, px: 0 }}>
                          <ListItemText primary="Total" />
                          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                            $ {formateador(valorTotalParaPago)}
                          </Typography>
                        </ListItem>
                      </List>
                    </Box>
                  </Paper>
                </Grid>
              </FormControl>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                marginTop: 3,
              }}
            >
              <Button
                variant="contained"
                color="warning"
                sx={{ marginRight: 3, display: 'flex', justifyContent: 'flex-end' }}
                onClick={handleClick}
                startIcon={<ReplyIcon />}
              >
                Atras
              </Button>
              <Button variant="contained" color="primary" sx={{ marginRight: 4 }} type="submit">
                Cancelar Crédito
              </Button>
            </Box>
          </LocalizationProvider>
        </form>
      )}
    </>
  );
};

export default DetalleCancelarCredito;
