/* eslint-disable radix */
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { NumericFormatCustom } from '../numericForm/NumericForm';
import { calculosDelCredito } from '../../helper/liquidarCredito';
import { agregarCompromiso } from '../../store';
import { formateador } from '../../helper/Formateador';

export const CrearCompromiso = () => {
  const { creditoEditable } = useSelector((state) => state?.creditosActivos);
  const dispatch = useDispatch();
  const [compromiso, setCompromiso] = useState({
    fecha_Compromiso: null,
    nota_Compomiso: null,
    valor_Compromiso: null,
    id: creditoEditable?.id,
    cumplio_Compomiso: null,
  });

  const valorCompromisos =
    creditoEditable?.compromisoCreditos?.length > 0
      ? creditoEditable?.compromisoCreditos?.reduce((total, numero) => total + parseInt(numero.valor_Compromiso), 0)
      : 0;

  const {
    montoAprobando,
    interesPorMora,
    interesCorriente,
    valorPlataforma,
    valorAdministracion,
    valorIva,
    valorPrimerCobranza,
    valorSegundaCobranza,
  } = calculosDelCredito(creditoEditable);

  const deuda =
    parseInt(montoAprobando) +
    parseInt(interesPorMora) +
    parseInt(interesCorriente) +
    parseInt(valorPlataforma) +
    parseInt(valorAdministracion) +
    parseInt(valorIva) +
    parseInt(valorPrimerCobranza) +
    parseInt(valorSegundaCobranza);

  const saldoRestante = deuda - parseInt(valorCompromisos);

  const agregarCompromisos = (e) => {
    e.preventDefault();
    if (!compromiso.fecha_Compromiso || !compromiso.valor_Compromiso) {
      Swal.fire({
        title: 'Error!',
        text: 'Los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (
      saldoRestante < 0 ||
      parseInt(compromiso.valor_Compromiso) > deuda ||
      parseInt(compromiso.valor_Compromiso) + valorCompromisos > deuda
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'El compromiso es superior a la deuda actual',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    dispatch(agregarCompromiso(compromiso));

    setCompromiso({
      id: creditoEditable?.id,
      fecha_Compromiso: null,
      nota_Compomiso: '',
      valor_Compromiso: 0,
      cumplio_Compomiso: null,
    });
  };

  return (
    <Grid item xs={12} md={4} lg={4}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card component="form" onSubmit={agregarCompromisos}>
          <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
            Crear Compromisos:
          </Typography>
          <Typography
            variant="overline"
            component="div"
            sx={{ color: '#637381', marginLeft: 2, fontSize: '12px', lineHeight: '22px', fontWeight: '400' }}
          >
            Monto Pendiente: <b>${formateador(saldoRestante)}</b>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginY: 2,
              maxWidth: 500,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  label="Fecha de Pago"
                  value={compromiso.fecha_Compromiso}
                  onChange={(newValue) =>
                    setCompromiso({
                      ...compromiso,
                      fecha_Compromiso: newValue,
                    })
                  }
                />
              </DemoContainer>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginY: 2,
                width: '65%',
                marginLeft: '66px',
              }}
            >
              <TextField
                fullWidth
                //   disabled={creditoEditable?.preAprobado}
                id="outlined-basic"
                label="Valor de la cuota"
                variant="standard"
                value={compromiso.valor_Compromiso}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                }}
                onChange={(event) => {
                  setCompromiso({
                    ...compromiso,
                    valor_Compromiso: event.target.value,
                  });
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginY: 2,
                width: '65%',
                marginLeft: '66px',
              }}
            >
              <TextField
                fullWidth
                //   disabled={creditoEditable?.preAprobado}
                id="outlined-basic"
                label="Observaciones"
                variant="standard"
                value={compromiso.nota_Compomiso}
                onChange={(event) => {
                  setCompromiso({
                    ...compromiso,
                    nota_Compomiso: event.target.value,
                  });
                }}
              />
            </Box>
            <Button
              sx={{
                width: '77%',
                marginLeft: '46px',
                marginBottom: 4,
                marginTop: 2,
              }}
              variant="contained"
              type="submit"
            >
              Agregar
            </Button>
          </Box>
        </Card>
      </LocalizationProvider>
    </Grid>
  );
};
