/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import { Badge, Grid, IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import { formateador } from '../helper/Formateador';
import { MAIN_PATH, PATH_NAME, TITLES } from '../constants';
import TableList from '../components/table-list';
import Iconify from '../components/iconify/Iconify';
import { startActualizarCreditos } from '../store';
import { hoy } from '../helper/funtions';

export function GestorClaves() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isGestorClaves = location?.pathname.includes(PATH_NAME.gestor_de_clave);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [datos, setDatos] = useState({
    loading: true,
    data: [],
  });
  const firmarCredito = async (idCredito) => {
    const credito = datos.data.filter((credito) => credito.id === idCredito)[0];

    if (!credito.adquirido) {
      Swal.fire({
        title: 'Error!',
        text: 'Este crédito aun no ha sido adquirido por el cliente.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (!credito.adquirido) {
      Swal.fire({
        title: 'Error!',
        text: 'Este crédito aún no ha sido adquirido por el cliente.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (credito.fecha_Firma) {
      Swal.fire({
        title: 'Error!',
        text: 'Este crédito ya se encuentra firmado.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    if (!credito.fecha_Firma && credito.adquirido) {
      Swal.fire({
        title: '¿Esta seguro de firmar este crédito?',
        text: 'Esta apunto de firmar el crédito del cliente, asegúrate de tener la aprobación necesaria',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, Firmar!',
        cancelButtonText: 'No, Cancelar',
      }).then(async (result) => {
        if (result.value) {
          credito.fecha_Firma = hoy;

          setLoadingUpdate(true);
          await dispatch(startActualizarCreditos(credito));
          setLoadingUpdate(false);
          navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
        }
      });
    }
  };
  const { creditos } = useSelector((state) => state.creditosActivos);

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Consecutivo',
      name: 'consecutivo',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Aprobado',
      name: 'valor_aprobado',
      options: {
        filter: true,
      },
    },
    {
      label: 'Firma Corta',
      name: 'clave_corta',
      options: {
        filter: true,
      },
    },
    {
      label: 'Firma Larga',
      name: 'clave_larga',
      options: {
        filter: true,
      },
    },

    {
      label: 'Firmado?',
      name: 'firmado',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Badge
            color={value === 'Sí' ? 'success' : 'error'}
            variant="dot"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {value}
          </Badge>
        ),
      },
    },
    {
      name: 'Firmar',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <>
            <Tooltip title="Gestionar" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => firmarCredito(tableMeta.rowData[1])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];

  useEffect(() => {
    setDatos({
      loading: false,
      data: creditos
        .filter((creditos) => creditos.aprobado === true)
        .map((credito) => ({
          nombre: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.segundoNombre || ''} ${
            credito?.cuentaUsuario?.primerApellido
          } ${credito?.cuentaUsuario?.segundoApellido || ''}`,
          consecutivo: credito?.id,
          valor_aprobado: `$ ${formateador(credito?.valor_Aprobado) || '-'}`,
          clave_corta: credito?.firma_Corta || '-',
          clave_larga: credito?.firma_Larga || '-',
          firmado: credito?.fecha_Firma === null ? 'No' : 'Sí',
          adquirido: credito?.adquirido,
          id: credito?.id,
          fecha_Firma: credito?.fecha_Firma,
        })),
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> {isGestorClaves ? TITLES.gestor_de_claves : TITLES.default} </title>
      </Helmet>

      {loadingUpdate ? (
        <Grid>
          <Grid display="flex" justifyContent="center" alignItems="end">
            <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
          </Grid>
        </Grid>
      ) : (
        <TableList titulo="Listado Claves Créditos" columnas={columnas} data={datos.data} loading={datos.loading} />
      )}
    </>
  );
}
