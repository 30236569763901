/* eslint-disable react/prop-types */
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';
import PeopleIcon from '@mui/icons-material/People';
import { Box, Card, Grid, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

function DatosRapidos({ data }) {
  return (
    <Box sx={{ display: 'flex', marginTop: 2.5, minWidth: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ padding: 2 }}>
            <Box sx={{ alignItems: 'baseline', margin: '0 auto' }}>
              <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
                Datos Rapidos:
              </Typography>

              <List sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <SettingsCellIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Celular" secondary={data?.celular} />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EmailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Correo" secondary={data?.usuario?.email} />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PeopleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Referencia Personal"
                    secondary={`${data?.referencia_personal_nombres} ${data?.referencia_personal_apellidos} - Telefono: ${data?.referencia_personal_telefono}`}
                  />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PeopleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Referencia Familiar"
                    secondary={`${data?.referencia_familiar_nombres} ${data?.referencia_familiar_apellidos} - Telefono: ${data?.referencia_familiar_telefono}`}
                  />
                </ListItem>
              </List>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DatosRapidos;
