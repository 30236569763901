import React from 'react';
import { useDispatch } from 'react-redux';
import { startConsultarCreditosHistorico } from '../../store/historicos';
import InformeDavivienda from './VistaInformes';

const InformeDaviviendaScreen = () => {
  const dispatch = useDispatch();

  dispatch(startConsultarCreditosHistorico());

  return <InformeDavivienda />;
};

export default InformeDaviviendaScreen;
