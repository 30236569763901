export const bancos = [
  { value: '40', nombre: 'BANCO AGRARIO' },
  { value: '52', nombre: 'BANCO AV VILLAS' },
  { value: '13', nombre: 'BANCO BBVA COLOMBIA S.A ' },
  { value: '32', nombre: 'BANCO CAJA SOCIAL' },
  { value: '67', nombre: 'BANCO COOPERATIVO COOPCENTRAL' },
  { value: '51', nombre: 'BANCO DAVIVIENDA' },
  { value: '01', nombre: 'BANCO DE BOGOTÁ' },
  { value: '23', nombre: 'BANCO DE OCCIDENTE' },
  { value: '12', nombre: 'BANCO DE GNB SUDAMERIS' },
  { value: '06', nombre: 'BANCO ITAU' },
  { value: '02', nombre: 'BANCO POPULAR' },
  { value: '63', nombre: 'BANCO FINANDINA' },
  { value: '66', nombre: 'BANCO SANTANDER COLOMBIA' },
  { value: '69', nombre: 'BANCO SERFINANZA' },
  { value: '07', nombre: 'BANCOLOMBIA' },
  { value: '36', nombre: 'CONFIAR COOPERATIVA FINANCIERA' },
  { value: '19', nombre: 'SCOTIABANK COLPATRIA' },
];
