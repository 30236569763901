/* eslint-disable no-unused-vars */
import { Chip, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { listaCompromisosPorAsentar } from '../helper/coleccionCreditos';
import TableList from '../components/table-list/TableList';
import { MAIN_PATH, PATH_NAME } from '../constants';
import { cargarCreditoAEditar } from '../store';
import Iconify from '../components/iconify/Iconify';

export function GestorCompromisos() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { compromisos, loading } = useSelector((state) => state?.creditosActivos?.compromisosRealizados);
  const { creditos } = useSelector((state) => state?.creditosActivos);

  const data = listaCompromisosPorAsentar(compromisos);

  const redireccionarEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    if (credito === undefined) {
      return;
    }
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito_vencido}`, { replace: true });
  };

  const columnas = [
    {
      label: 'N. Crédito',
      name: 'creditoId',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Compromiso',
      name: 'monto',
      options: {
        filter: true,
      },
    },
    {
      label: 'Fecha del Compromiso',
      name: 'fecha_Compromiso',
      options: {
        filter: true,
      },
    },
    {
      label: 'Observaciones',
      name: 'nota_Compomiso',
      options: {
        filter: false,
      },
    },
    {
      label: 'Estado',
      name: 'estado',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Chip label={value ? 'Vencido' : 'Pendiente'} color={value ? 'error' : 'default'} />
        ),
      },
    },
    {
      name: 'Ir al Crédito',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => (
          <Tooltip title="Ir al Crédito" aria-label="Gestionar">
            <IconButton disabled aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[0])}>
              <Iconify icon="ic:outline-manage-search" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      name: 'Asentar',
      options: {
        sort: false,
        customBodyRender: (_, tableMeta) => (
          <>
            <Tooltip title="Asentar Crédito">
              <IconButton
                disabled
                // onClick={() => asentarPagoEdicion(tableMeta.rowData[2])}
              >
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];
  return (
    <>
      <Helmet>{/* <title> {isGestorCompromisos ? TITLES.gestor_de_compromisos : TITLES.default} </title> */}</Helmet>

      <TableList titulo="Gestor de Compromisos" columnas={columnas} loading={loading} data={data} />
    </>
  );
}
