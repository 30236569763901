import Swal from 'sweetalert2';
import clienteAxios from '../../helper/axios';
import { bienestar, informesDone, limpiarInformeAEditar } from './contadorBienestarSlice';

export const startcontadorBienestar = () => async (dispatch) => {
  try {
    const { data } = await clienteAxios.get('/admin/tiles-bienestar', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(bienestar(data.contador));
  } catch (error) {
    console.error(error);
  }
};

export const generarInforme = (id, info) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.patch(
      `/admin/informe-bienestar/${id}`,
      { info },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    Swal.fire(respuesta.data.mensaje, 'Informe generado correctamente.', respuesta.data.success);
    dispatch(startcontadorBienestar());
    dispatch(limpiarInformeAEditar());
  } catch (error) {
    console.error('-->', error);
  }
};

export const startInformesDone = () => async (dispatch) => {
  try {
    const { data } = await clienteAxios.get('/admin/informe-bienestar-realizados', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(informesDone(data.contador));
  } catch (error) {
    console.error(error);
  }
};
