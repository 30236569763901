/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Paper,
  FormControl,
  InputLabel,
  NativeSelect,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { limpiarCreditoAEditar } from '../../store';
import { formateador } from '../../helper/Formateador';
import { cambioLoadingClarisa, startActualizarCreditos } from '../../store/creditosActivos';
import '../../style.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AsentarPago({ open, setOpen }) {
  const dispatch = useDispatch();
  const { creditoEditable } = useSelector((state) => state.creditosActivos);

  const [creditoACancelar, setCreditoACancelar] = React.useState(creditoEditable);

  const handleClose = () => {
    dispatch(limpiarCreditoAEditar());
    setOpen(false);
  };
  const handleFacturarChange = ({ target }) => {
    if (target.value === 'false') {
      setCreditoACancelar({
        ...creditoACancelar,
        facturar: 'false',
        metodo_Cancelacion: null,
        paraCancelar: null,
      });
      return;
    }
    setCreditoACancelar({ ...creditoACancelar, facturar: 'true', cancelado: true, paraCancelar: true });
  };

  const handleMetodoPagoChange = (event) => {
    setCreditoACancelar({ ...creditoACancelar, metodo_Cancelacion: event.target.value });
  };

  const rechazarPago = () => {
    setOpen(false);
    dispatch(
      startActualizarCreditos({
        ...creditoEditable,
        cobranza_Segunda: null,
        cobranza_Inicial: null,
        valor_Cancelado_Capital: null,
        interes_Moratorio: null,
        administracion: null,
        plataforma: null,
        iva: null,
        interes_Corriente: null,
        fecha_Cancelado: null,
      })
    );
  };

  const efectuarPago = async (e) => {
    e.preventDefault();

    setOpen(false);
    dispatch(cambioLoadingClarisa(true));

    try {
      await dispatch(startActualizarCreditos({ ...creditoACancelar, paraCancelar: true, cancelado: true }));
      dispatch(cambioLoadingClarisa(false));
    } catch (error) {
      console.error(error);
      dispatch(cambioLoadingClarisa(false));
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Credito: {creditoEditable?.id}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Nombre: {creditoEditable?.cuentaUsuario?.primerNombre} {creditoEditable?.cuentaUsuario?.primerApellido}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Fecha de Pago: {moment(creditoEditable?.fecha_Cancelado).format('l')}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Valor Pagado:{' $  '}
              {formateador(
                creditoEditable?.cobranza_Segunda +
                  creditoEditable?.cobranza_Inicial +
                  creditoEditable?.valor_Aprobado +
                  creditoEditable?.interes_Moratorio +
                  creditoEditable?.administracion +
                  creditoEditable?.plataforma +
                  creditoEditable?.iva +
                  creditoEditable?.interes_Corriente
              )}
            </Typography>
          </Box>
          <Paper
            sx={{
              background: '#F0F2F5',
              marginY: 3,
              padding: 1,
            }}
          >
            <p>Credito: $ {formateador(creditoEditable?.valor_Aprobado)}</p>
            <p>Administración: $ {formateador(creditoEditable?.administracion)}</p>
            <p>Plataforma: $ {formateador(creditoEditable?.plataforma)}</p>
            <p>IVA: $ {formateador(creditoEditable?.iva)}</p>
            <p>Intereses: $ {formateador(creditoEditable?.interes_Corriente)}</p>
            {creditoEditable?.interes_Moratorio > 0 && (
              <p>Intereses por mora: $ {formateador(creditoEditable?.interes_Moratorio)}</p>
            )}
            {creditoEditable?.cobranza_Inicial > 0 && (
              <p>Primera Cobranza: $ {formateador(creditoEditable?.cobranza_Inicial)}</p>
            )}
            {creditoEditable?.cobranza_Segunda > 0 && (
              <p>Primera Cobranza: $ {formateador(creditoEditable?.cobranza_Segunda)}</p>
            )}
          </Paper>
          <FormControl
            sx={{
              '& > :not(style)': { width: '300px' },
            }}
          >
            <FormLabel id="demo-row-radio-buttons-group-label">Facturar?</FormLabel>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              <FormControlLabel
                checked={creditoACancelar?.facturar === 'true'}
                value={'true'}
                onChange={handleFacturarChange}
                control={<Radio />}
                name="facturar"
                label="Si"
              />
              <FormControlLabel
                checked={creditoACancelar?.facturar === 'false'}
                value={'false'}
                name="facturar"
                onChange={handleFacturarChange}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
          <Box
            sx={{ marginY: 2 }}
            className={
              creditoACancelar?.facturar === 'true'
                ? 'animate__animated animate__fadeIn'
                : 'animate__animated animate__fadeOut'
            }
          >
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Modo de Pago
              </InputLabel>
              <NativeSelect
                onChange={handleMetodoPagoChange}
                inputProps={{
                  name: 'metodo_Cancelacion',
                }}
              >
                <option value={''} />
                <option value="47">Transferencia</option>
                <option value="10">Efectivo</option>
              </NativeSelect>
            </FormControl>
          </Box>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <Button variant="contained" color="error" onClick={rechazarPago}>
              Eliminar
            </Button>
            <Button size="large" variant="contained" onClick={efectuarPago}>
              Asentar
            </Button>
          </Box>
        </Card>
      </Modal>
    </div>
  );
}
