/* eslint-disable react/prop-types */
import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import Swal from 'sweetalert2';

export const Independiente = ({
  PreguntasXIndependientes,
  setPreguntasXIndependientes,
  activeStep,
  setActiveStep,
  steps,
}) => {
  const setInformacion = ({ target }) => {
    setPreguntasXIndependientes({
      ...PreguntasXIndependientes,
      [target.name]: target.value,
    });
  };
  const handleNext = (e) => {
    e.preventDefault();
    if (
      PreguntasXIndependientes.actividad_economica === null ||
      PreguntasXIndependientes.nombre_empresa_independiente === null ||
      PreguntasXIndependientes.ciudad_empresa_independiente === null ||
      PreguntasXIndependientes.direccion_empresa_independiente === null ||
      PreguntasXIndependientes.telefono_empresa_independiente === null ||
      PreguntasXIndependientes.tiempo_empresa_independiente === null
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Grid item xs={12}>
      <div className="animate__animated animate__fadeIn">
        <Box
          component="form"
          sx={{
            '& > :not(style)': { width: '100%' },
          }}
          noValidate
          autoComplete="off"
        >
          <Typography variant="h6" component="h2">
            Información Independiente
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="actividad_economica"
                label="Actividad Economica"
                fullWidth
                onChange={setInformacion}
                value={PreguntasXIndependientes.actividad_economica}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="nombre_empresa_independiente"
                value={PreguntasXIndependientes.nombre_empresa_independiente}
                label="Nombre de la empresa"
                fullWidth
                onChange={setInformacion}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="ciudad_empresa_independiente"
                value={PreguntasXIndependientes.ciudad_empresa_independiente}
                label="Ciudad donde se encuentra la empresa"
                fullWidth
                onChange={setInformacion}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="direccion_empresa_independiente"
                value={PreguntasXIndependientes.direccion_empresa_independiente}
                label="Dirección donde se encuentra la empresa"
                onChange={setInformacion}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="telefono_empresa_independiente"
                value={PreguntasXIndependientes.telefono_empresa_independiente}
                label="Telefono de la empresa"
                fullWidth
                type="number"
                onChange={setInformacion}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="tiempo_empresa_independiente"
                value={PreguntasXIndependientes.tiempo_empresa_independiente}
                onChange={setInformacion}
                label="Tiempo de Actividad"
                fullWidth
                type="number"
                InputProps={{
                  endAdornment: <InputAdornment position="start">años</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        {activeStep !== 0 && <Button onClick={handleBack}>Anterior</Button>}
        <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </div>
    </Grid>
  );
};
