import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import PagoInformeBienestar from '../../../pages/PagoInformeBienestar';
import { Reporte } from '../../../client/components/bienestarFinanciero/Reporte';
import { Cursos } from '../../../client/pages/cursos/Cursos';
import DashboardAppPageClient from '../../../client/pages/DashboardAppPageClient';

import DashboardClienteLayout from '../../../client/layouts/dashboard/DashboardClienteLayout';
import { MAIN_PATH_CLIENTE, PATH_NAME_CLIENTE } from '../../../constants';
import ContratoLetra from '../../../client/components/contrato/Pagare';
import LiquidarContrato from '../../../client/components/liquidarContrato/LiquidarCredito';
import { Historico } from '../../../client/components/historico/Historico';
import RenovarCredito from '../../../client/components/renovarCredito/RenovarCredito';
import AbonoCompromiso from '../../../client/components/liquidarContrato/AbonoCompromiso';
import Profile from '../../../client/pages/profile/Profile';
import EditarQuestionario from '../../../client/pages/EditarQuestionario';
import { PageClient } from '../../../shared/InformeBienestar/PageClient';

// ----------------------------------------------------------------------

export default function ClientRoutes() {
  const routes = useRoutes([
    {
      path: `/${MAIN_PATH_CLIENTE}`,
      element: <DashboardClienteLayout />,
      children: [
        { element: <Navigate to={`/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.home_cliente}`} />, index: true },
        { path: PATH_NAME_CLIENTE.home_cliente, element: <DashboardAppPageClient /> },
        { path: PATH_NAME_CLIENTE.contrato_cliente, element: <ContratoLetra /> },
        { path: PATH_NAME_CLIENTE.liquidar_contrato_cliente, element: <LiquidarContrato /> },
        { path: PATH_NAME_CLIENTE.abono_compromiso, element: <AbonoCompromiso /> },
        { path: PATH_NAME_CLIENTE.historico_contrato_cliente, element: <Historico /> },
        { path: PATH_NAME_CLIENTE.renovar_credito, element: <RenovarCredito /> },
        { path: PATH_NAME_CLIENTE.perfil_cliente, element: <Profile /> },
        { path: PATH_NAME_CLIENTE.editar_perfil_cliente, element: <EditarQuestionario /> },
        { path: PATH_NAME_CLIENTE.cursos, element: <Cursos /> },
        { path: PATH_NAME_CLIENTE.bienestar_financiero, element: <PageClient /> },
        { path: PATH_NAME_CLIENTE.solicitud_bienestar_financiero, element: <PagoInformeBienestar /> },
      ],
    },
  ]);

  return routes;
}
