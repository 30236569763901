export const ACCESOS_GESTOR_PAGOS_CREDITOS = ['super'];
export const ACCESOS_GESTOR_CANCELACION_MANUAL = ['super'];
export const ACCESOS_GESTOR_RENOVACIONES = ['super'];

export const ACCESOS_GESTOR_PAGOS_COMPROMISOS = ['super'];
export const ACCESOS_GESTOR_CLAVES = ['super', 'cobro'];
export const ACCESOS_GESTOR_DATOS = ['super'];
export const ACCESOS_GESTOR_CREDITO = ['super', 'cobro'];
export const ACCESOS_GESTOR_ATRAZADOS = ['super', 'cobro'];
export const ACCESOS_CONSULTAS_COMPROMISOS_GENERAL = ['super', 'cobro'];
export const ACCESOS_CONSULTAS_COMPROMISOS_POR_VENCER = ['super', 'cobro'];
export const ACCESOS_CONSULTAS_COMPROMISOS_VENCIDOS = ['super', 'cobro'];
export const ACCESOS_INFORMES_FACTURACION = ['super'];
export const ACCESOS_INFORMES_GESTION = ['super'];
export const ACCESOS_INFORMES_HISTORICOS = ['super', 'cobro'];
export const ACCESOS_INFORMES_DAVIVIENDA = ['super', 'cobro'];
export const ACCESOS_INFORMES_DATACREDITO = ['super', 'cobro'];
export const ACCESOS_HOME = ['super', 'cobro'];
