/* eslint-disable react/prop-types */
import { Box, Button, TextField, Typography } from '@mui/material';

import Swal from 'sweetalert2';

export const Desempleado = ({ preguntasDesempleado, setPreguntasDesempleado, activeStep, setActiveStep, steps }) => {
  const setInformacion = ({ target }) => {
    setPreguntasDesempleado({
      [target.name]: target.value,
    });
  };
  const handleNext = (e) => {
    e.preventDefault();
    if (preguntasDesempleado?.tiempo_desempleado.trim() === '') {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 2, width: '26ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Typography variant="h6" component="h2">
          Informacion Desempleados
        </Typography>

        <TextField
          name="tiempo_desempleado"
          value={preguntasDesempleado.tiempo_desempleado}
          label="Tiempo de desempleo"
          fullWidth
          type="number"
          onChange={setInformacion}
        />
      </Box>
      <div>
        {activeStep !== 0 && <Button onClick={handleBack}>Anterior</Button>}
        <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </div>
    </div>
  );
};
