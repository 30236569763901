/* eslint-disable radix */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import SpeakerNotesTwoToneIcon from '@mui/icons-material/SpeakerNotesTwoTone';

import Tooltip from '@mui/material/Tooltip';
import { calculosDelCredito } from '../../helper/liquidarCredito';
import { formateador } from '../../helper/Formateador';

export default function DetalleCredito() {
  const { creditoEditable } = useSelector((state) => state.creditosActivos);

  const {
    montoAprobando,
    interesPorMora,
    interesCorriente,
    valorPlataforma,
    valorAdministracion,
    valorIva,
    valorPrimerCobranza,
    valorSegundaCobranza,
  } = calculosDelCredito(creditoEditable);

  return (
    <Grid item xs={12} sx={{ marginTop: 3 }}>
      <Card sx={{ padding: 2 }}>
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <div className="title">
            <Typography variant="h5" gutterBottom component="div" sx={{ margin: 2 }}>
              Liquidación del crédito (Consolidado)
            </Typography>
          </div>
          <Table sx={{ minWidth: 650, marginBottom: 3 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Monto Aprobado</TableCell>
                <TableCell>Plataforma</TableCell>
                <TableCell>Interés Corriente</TableCell>
                <TableCell>Administración</TableCell>
                <TableCell>Iva</TableCell>
                <TableCell>Interes por Mora</TableCell>
                <TableCell>1º Cobranza</TableCell>
                <TableCell>2º Cobranza</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {`$ ${formateador(montoAprobando)}`}
                </TableCell>
                <TableCell component="th" scope="row">
                  {`$ ${formateador(valorPlataforma)}`}
                  <Tooltip
                    //   title={`Se aplicó descuento de $ ${formateador(
                    //     descuento_Plataforma,
                    //   )} en Plataforma`}
                    placement="top"
                  >
                    <SpeakerNotesTwoToneIcon />
                  </Tooltip>
                </TableCell>
                <TableCell component="th" scope="row">
                  $ {formateador(interesCorriente)}
                </TableCell>
                <TableCell component="th" scope="row">
                  $ {formateador(valorAdministracion)}
                  {/* $ {formateador(valorAdministracion)}
              {descuento_Administracion !== null && (
                <Tooltip
                  title={`Se aplicó descuento de $ ${formateador(descuento_Administracion)} en administración`}
                  placement="top"
                >
                  <SpeakerNotesTwoToneIcon />
                </Tooltip>
              )} */}
                </TableCell>
                <TableCell component="th" scope="row">
                  $ {formateador(valorIva)}
                </TableCell>
                <TableCell component="th" scope="row">
                  $ {formateador(interesPorMora)}
                </TableCell>
                <TableCell component="th" scope="row">
                  $ {formateador(valorPrimerCobranza)}
                </TableCell>
                <TableCell component="th" scope="row">
                  $ {formateador(valorSegundaCobranza)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6" component="div">
            Total a la fecha: {'$ '}
            {formateador(
              parseInt(montoAprobando) +
                parseInt(interesPorMora) +
                parseInt(interesCorriente) +
                parseInt(valorPlataforma) +
                parseInt(valorAdministracion) +
                parseInt(valorIva) +
                parseInt(valorPrimerCobranza) +
                parseInt(valorSegundaCobranza)
            )}
          </Typography>
          <Typography variant="p" component="div" sx={{ color: 'red' }}>
            Los interesés moratorios se generarán diariamente hasta la cancelación del credito
          </Typography>
        </TableContainer>
      </Card>
    </Grid>
  );
}
