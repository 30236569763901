/* eslint-disable react/prop-types */
import CssBaseline from '@mui/material/CssBaseline';
import { Card, Grid, Typography } from '@mui/material';

export const MensajeEstadoCredito = ({ titulo, subtitulo, mensaje, monto }) => (
  <Card sx={{ width: '70%', margin: '0 auto', padding: 3 }}>
    <Grid container component="main" sx={{ margin: 'auto' }}>
      <CssBaseline />
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography
          variant="h2"
          sx={{
            color: '#ffde2e',
            fontFamily: 'Raleway',
            fontSize: '40px',
            marginBottom: 2,
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {titulo}
        </Typography>
        <Typography
          variant="h4"
          sx={{ color: '#0074bc', fontFamily: 'Raleway', fontSize: '30px', textAlign: 'center' }}
        >
          {subtitulo} {monto}
        </Typography>
        <Typography
          variant="p"
          sx={{ color: '#0074bc', fontFamily: 'Raleway', textAlign: 'center', marginBottom: '20px' }}
        >
          {mensaje}
        </Typography>
      </Grid>
    </Grid>
  </Card>
);
