import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { GestionarCredito } from '../../pages/GestionarCredito';
import { CrearCompromiso, ListarCompromiso } from '../compromisos';
import DetalleCredito from '../consolidadoEnMora/ConsolidadoEnMora';
import DatosRapidos from '../datosRapidos/DatosRapidos';

export const GestionCreditoAtrazado = () => {
  const { creditoEditable } = useSelector((state) => state.creditosActivos);
  return (
    <>
      <GestionarCredito />
      <DatosRapidos data={creditoEditable?.cuentaUsuario} />
      <DetalleCredito />
      <Grid sx={{ marginTop: 1 }} container spacing={2}>
        <CrearCompromiso />
        <ListarCompromiso />
      </Grid>
    </>
  );
};
