import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PATH_NAME, TITLES } from '../constants';
import InformeDatacreditoScreen from '../components/informeDatacredito/informeDatacreditoScreen';
import { startConsultarCreditosHistorico } from '../store/historicos';

export function InformeDatacredito() {
  const location = useLocation();
  const dispatch = useDispatch();

  const isInformeDatacredito = location?.pathname.includes(PATH_NAME.informe_de_dataCredito);
  useEffect(() => {
    dispatch(startConsultarCreditosHistorico());
  }, []);
  return (
    <>
      <Helmet>
        <title> {isInformeDatacredito ? TITLES.informe_de_davivienda : TITLES.default} </title>
      </Helmet>

      <InformeDatacreditoScreen />
    </>
  );
}
