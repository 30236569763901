/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MenuItem, Typography, TextField, Grid, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Swal from 'sweetalert2';
import { ciudadesSelect } from './ciudades';

const InformacionPersonal = ({ setInformacionPersonal, registroInfoPersonal, setActiveStep, activeStep, steps }) => {
  const handleNext = () => {
    if (
      registroInfoPersonal.primerNombre.trim() === '' ||
      registroInfoPersonal.primerApellido.trim() === '' ||
      registroInfoPersonal.celular.trim() === '' ||
      registroInfoPersonal.cedula.trim() === '' ||
      registroInfoPersonal.fecha_de_nacimiento === '' ||
      registroInfoPersonal.fecha_de_expedicion_cedula === '' ||
      registroInfoPersonal.ciudad_expedicion_cedula.trim() === '' ||
      registroInfoPersonal.genero.trim() === '' ||
      // registroInfoPersonal.numero_personas_a_cargo.trim() === '' ||
      registroInfoPersonal.nivel_de_estudios.trim() === ''
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const setInformacion = ({ target }) => {
    setInformacionPersonal({
      ...registroInfoPersonal,
      [target.name]: target.value,
    });
  };

  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          marginTop: 3,
          marginBottom: 3,
        }}
      >
        Información Personal:
      </Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="primerNombre"
              value={registroInfoPersonal.primerNombre}
              label="Primer Nombre"
              fullWidth
              onChange={setInformacion}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="segundoNombre"
              value={registroInfoPersonal.segundoNombre}
              label="Segundo Nombre"
              fullWidth
              onChange={setInformacion}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="primerApellido"
              value={registroInfoPersonal.primerApellido}
              label="Primer Apellido"
              fullWidth
              onChange={setInformacion}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="segundoApellido"
              value={registroInfoPersonal.segundoApellido}
              label="Segundo Apellido"
              fullWidth
              onChange={setInformacion}
            />
          </Grid>

          <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="cedula"
              value={registroInfoPersonal.cedula}
              label="Cédula"
              type="number"
              fullWidth
              onChange={setInformacion}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="celular"
              value={registroInfoPersonal.celular}
              label="Celular"
              type="number"
              fullWidth
              onChange={setInformacion}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <DatePicker
              name="fecha_de_nacimiento"
              label="Fecha de Nacimiento"
              value={registroInfoPersonal.fecha_de_nacimiento}
              fullWidth
              sx={{ width: '100%' }}
              onChange={(newValue) =>
                setInformacionPersonal({
                  ...registroInfoPersonal,
                  fecha_de_nacimiento: newValue,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <DatePicker
              name="fecha_de_expedicion_cedula"
              value={registroInfoPersonal.fecha_de_expedicion_cedula}
              onChange={(newValue) =>
                setInformacionPersonal({
                  ...registroInfoPersonal,
                  fecha_de_expedicion_cedula: newValue,
                })
              }
              label="Expedición de la cédula"
              fullWidth
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              type="text"
              sx={{ marginTop: 0 }}
              name="ciudad_expedicion_cedula"
              value={registroInfoPersonal.ciudad_expedicion_cedula}
              label="Ciudad de expedición"
              select
              onChange={setInformacion}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            >
              {ciudadesSelect.map((option) => (
                <MenuItem key={option.codigo + option.nombre} value={option.codigo}>
                  {option.nombre}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              sx={{ marginTop: 0 }}
              type="text"
              name="genero"
              value={registroInfoPersonal.genero}
              label="Género"
              onChange={setInformacion}
              select
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            >
              <MenuItem value="femenino">Femenino</MenuItem>
              <MenuItem value="masculino">Masculino</MenuItem>
            </TextField>
          </Grid>
          {/* <Grid item xs={12} md={4} sx={3}>
            <TextField
              name="numero_personas_a_cargo"
              value={registroInfoPersonal.numero_personas_a_cargo}
              onChange={setInformacion}
              label="Número de personas a cargo"
              type="number"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={4} sx={3}>
            <TextField
              sx={{ marginTop: 0 }}
              type="text"
              name="nivel_de_estudios"
              value={registroInfoPersonal.nivel_de_estudios}
              label="Nivel de Estudios"
              onChange={setInformacion}
              select
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            >
              <MenuItem value="primaria">Básica Primaria</MenuItem>
              <MenuItem value="bachiller">Bachiller</MenuItem>
              <MenuItem value="tecnico">Técnico</MenuItem>
              <MenuItem value="tecnologo">Tecnologo</MenuItem>
              <MenuItem value="profesional">Profesional</MenuItem>
              <MenuItem value="doctorado">Doctorado</MenuItem>
              <MenuItem value="sinEstudio">Sin Estudios</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </LocalizationProvider>
      <div>
        <Button onClick={handleNext} variant="contained" color="primary">
          {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </div>
    </>
  );
};

export default InformacionPersonal;
