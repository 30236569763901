/* eslint-disable react/prop-types */
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import Swal from 'sweetalert2';

export const Pensionado = ({ preguntasPensionados, setPreguntasPensionados, activeStep, setActiveStep, steps }) => {
  const setInformacion = ({ target }) => {
    setPreguntasPensionados({
      ...preguntasPensionados,
      [target.name]: target.value,
    });
  };
  const handleNext = (e) => {
    e.preventDefault();
    if (
      preguntasPensionados.fecha_resolucion_pension === null ||
      preguntasPensionados.fondo_pensional === null ||
      preguntasPensionados.causa_pension === null
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Grid item xs={12}>
      <div className="animate__animated animate__fadeIn">
        <Box component="form">
          <Typography variant="h6" component="h2">
            Información Pensionado
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                  name="fecha_resolucion_pension"
                  value={preguntasPensionados.fecha_resolucion_pension}
                  label="Fecha de Resolución"
                  fullWidth
                  onChange={(newValue) =>
                    setPreguntasPensionados({
                      ...preguntasPensionados,
                      fecha_resolucion_pension: newValue,
                    })
                  }
                  sx={{ width: '100%' }}
                />
              </DemoContainer>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                onChange={setInformacion}
                name="fondo_pensional"
                value={preguntasPensionados.fondo_pensional}
                label="Fondo Pensional"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                onChange={setInformacion}
                name="causa_pension"
                value={preguntasPensionados.causa_pension}
                label="Causa Pensión"
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </div>
      <div>
        {activeStep !== 0 && <Button onClick={handleBack}>Anterior</Button>}
        <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </div>
    </Grid>
  );
};
