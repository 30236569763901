import axios from 'axios';

const clienteAxios = axios.create({
  // baseURL: `http://localhost:5050/api/`,
  // baseURL: "http://64.227.108.44:3500/",
  baseURL: '/api/',
});

export const clarisaAxios = axios.create({
  baseURL: `http://`,
});

export default clienteAxios;
