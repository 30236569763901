import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { BienestarFinancieroGeneralDone } from '../../../pages/ReporteBienestarFinacieroGeneralDone';
import { GestionarInfoB } from '../../../pages/GestionarInfoB';
import { DatosPersonales } from '../../../pages/DatosPersonales';
import DashboardLayout from '../../../layouts/dashboard';
import DashboardAppPage from '../../../pages/DashboardAppPage';
import {
  GestorClaves,
  GestorCompromisos,
  GestorCreditos,
  GestorCreditosAtrazados,
  GestorDatos,
  GestorPagos,
  GestorPagosManuales,
  GestorRenovaciones,
  InformeDavivienda,
  InformeFacturacion,
  InformeGestion,
  InformeHistorico,
  ListadoCompromisos,
  ListadoCompromisosPorVencer,
  ListadoCompromisosVencidos,
  PorAprobarAntiguos,
  PorAprobarNuevos,
  PorDesembolsarAntiguos,
  PorDesembolsarNuevos,
  PreAprobadosConDocumentosAntiguos,
  PreAprobadosConDocumentosNuevos,
  PreAprobadosSinDocumentosAntiguos,
  PreAprobadosSinDocumentosNuevos,
  SolicitudesAntiguos,
  SolicitudesNuevos,
  SolicitudesRenovacionesAntiguos,
  SolicitudesRenovacionesNuevos,
  NotasUsuario,
  BienestarFinancieroAntiguos,
} from '../../../pages';
import { MAIN_PATH, PATH_NAME } from '../../../constants';
import { GestionarCredito } from '../../../pages/GestionarCredito';
import { InformeSolicitos } from '../../../pages/informes/InformeSolicitado';
import { GestionCreditoAtrazado } from '../../../components/gestionCreditoAtrazado/GestionCreditoAtrazado';
import { Renovacion } from '../../../components/renovacion/Renovacion';
import { NotasCredito } from '../../../pages/notasCredito';
import { HistoricoCliente } from '../../../components/historicoCliente/HistoricoCliente';
import Contrato from '../../../components/contrato/Contrato';
import RenovarCreditoDelCliente from '../../../pages/renovarCredito/RenovarCredito';
import { InformeCancelados } from '../../../pages/informes/InformeCancelados';
import { InformeDesembolsados } from '../../../pages/informes/InformeDesembolsados';
import { InformeRechazados } from '../../../pages/informes/InformeRechazados';
import { GestionarCreditoHistorico } from '../../../pages/GestionarCreditoHistorico';
import { InformeRenovados } from '../../../pages/informes/InformeRenovados';
import CancelarCredito from '../../../pages/cancelarCredito/CancelarCredito';
import { InformeDatacredito } from '../../../pages/InformeDataCredito';
import { BienestarFinancieroGeneral } from '../../../pages/ReporteBienestarFinacieroGeneral';
import { BienestarFinancieroNuevos } from '../../../pages/listaCreditosClasificados/clientesNuevos/ReporteBienestarFinaciero';
import { PageAdmin } from '../../../shared/InformeBienestar/PageAdmin';

// ----------------------------------------------------------------------

export default function AdminRoutes() {
  const routes = useRoutes([
    {
      path: `/${MAIN_PATH}`,
      // path: `/admin`,
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={`admin/${MAIN_PATH}/${PATH_NAME.home_admin}`} />, index: true },
        { path: PATH_NAME.home_admin, element: <DashboardAppPage /> },
        { path: PATH_NAME.gestor_de_datos, element: <GestorDatos /> },
        { path: PATH_NAME.gestor_de_clave, element: <GestorClaves /> },
        { path: PATH_NAME.gestor_de_creditos_atrazados, element: <GestorCreditosAtrazados /> },
        { path: PATH_NAME.gestor_de_renovaciones, element: <GestorRenovaciones /> },
        { path: PATH_NAME.gestor_de_pagos, element: <GestorPagos /> },
        { path: PATH_NAME.gestor_de_compromisos, element: <GestorCompromisos /> },
        { path: PATH_NAME.gestor_de_pagos_manuales, element: <GestorPagosManuales /> },
        { path: PATH_NAME.listado_de_compromisos, element: <ListadoCompromisos /> },
        { path: PATH_NAME.listado_de_compromisos_por_vencer, element: <ListadoCompromisosPorVencer /> },
        { path: PATH_NAME.listado_de_compromisos_vencidos, element: <ListadoCompromisosVencidos /> },
        { path: PATH_NAME.informe_facturacion, element: <InformeFacturacion /> },
        { path: PATH_NAME.informe_de_gestion, element: <InformeGestion /> },
        { path: PATH_NAME.informe_de_gestion_solicitados, element: <InformeSolicitos /> },
        { path: PATH_NAME.informe_de_gestion_cancelados, element: <InformeCancelados /> },
        { path: PATH_NAME.informe_de_gestion_desembolsados, element: <InformeDesembolsados /> },
        { path: PATH_NAME.informe_de_gestion_rechazados, element: <InformeRechazados /> },
        { path: PATH_NAME.informe_de_gestion_renovados, element: <InformeRenovados /> },
        { path: PATH_NAME.informe_historico, element: <InformeHistorico /> },
        { path: PATH_NAME.informe_de_davivienda, element: <InformeDavivienda /> },
        { path: PATH_NAME.informe_de_dataCredito, element: <InformeDatacredito /> },
        { path: PATH_NAME.gestor_de_creditos, element: <GestorCreditos /> },
        { path: PATH_NAME.gestionar_credito, element: <GestionarCredito /> },
        { path: PATH_NAME.gestionar_credito_vencido, element: <GestionCreditoAtrazado /> },
        { path: PATH_NAME.gestionar_datos_cliente, element: <DatosPersonales /> },
        { path: PATH_NAME.gestionar_renovacion_credito, element: <Renovacion /> },
        { path: PATH_NAME.gestionar_notas_usuario, element: <NotasUsuario /> },
        { path: PATH_NAME.gestionar_notas_credito, element: <NotasCredito /> },
        { path: PATH_NAME.historico_cliente, element: <HistoricoCliente /> },
        { path: PATH_NAME.contrato_credito, element: <Contrato /> },
        { path: PATH_NAME.renovar_credito, element: <RenovarCreditoDelCliente /> },
        { path: PATH_NAME.cancelar_credito, element: <CancelarCredito /> },
        { path: PATH_NAME.visualizar_credito_historico, element: <GestionarCreditoHistorico /> },
        { path: PATH_NAME.informe_bienestar_financiero, element: <BienestarFinancieroGeneral /> },
        { path: PATH_NAME.listado_informe_bienestar_financiero, element: <BienestarFinancieroGeneralDone /> },
        { path: PATH_NAME.imprimir_bienestar_financiero, element: <PageAdmin /> },

        // =============================== Listado de creditos por clasificacion ===============================

        // Clientes Antiguos
        { path: PATH_NAME.solicitudes_de_usuarios_antiguos, element: <SolicitudesAntiguos /> },
        {
          path: PATH_NAME.solicitudes_de_renovaciones_de_usuarios_antiguos,
          element: <SolicitudesRenovacionesAntiguos />,
        },
        {
          path: PATH_NAME.creditos_preaprobados_sin_documentos_de_usuarios_antiguos,
          element: <PreAprobadosSinDocumentosAntiguos />,
        },
        {
          path: PATH_NAME.creditos_preaprobados_con_documentos_de_usuarios_antiguos,
          element: <PreAprobadosConDocumentosAntiguos />,
        },
        {
          path: PATH_NAME.creditos_por_aprobar_de_usuarios_antiguos,
          element: <PorAprobarAntiguos />,
        },
        {
          path: PATH_NAME.creditos_por_desembolsar_de_usuarios_antiguos,
          element: <PorDesembolsarAntiguos />,
        },

        // Clientes Nuevos
        { path: PATH_NAME.solicitudes_de_usuarios_nuevos, element: <SolicitudesNuevos /> },
        {
          path: PATH_NAME.solicitudes_de_renovaciones_de_usuarios_nuevos,
          element: <SolicitudesRenovacionesNuevos />,
        },
        {
          path: PATH_NAME.creditos_preaprobados_sin_documentos_de_usuarios_nuevos,
          element: <PreAprobadosSinDocumentosNuevos />,
        },
        {
          path: PATH_NAME.creditos_preaprobados_con_documentos_de_usuarios_nuevos,
          element: <PreAprobadosConDocumentosNuevos />,
        },
        {
          path: PATH_NAME.creditos_por_aprobar_de_usuarios_nuevos,
          element: <PorAprobarNuevos />,
        },
        {
          path: PATH_NAME.creditos_por_desembolsar_de_usuarios_nuevos,
          element: <PorDesembolsarNuevos />,
        },
        {
          path: PATH_NAME.informe_bienestar_financiero_antiguos,
          element: <BienestarFinancieroAntiguos />,
        },
        {
          path: PATH_NAME.informe_bienestar_financiero_nuevos,
          element: <BienestarFinancieroNuevos />,
        },

        {
          path: PATH_NAME.gestionar_informe,
          element: <GestionarInfoB />,
        },
      ],
    },
  ]);

  return routes;
}
