/* eslint-disable react/prop-types */
import { Box, Button, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { ciudadesSelect } from './ciudades';

const InformacionResidencial = ({
  activeStep,
  setActiveStep,
  steps,
  informacionResidencial,
  setInformacionResidencial,
}) => {
  const handleNext = (e) => {
    e.preventDefault();

    if (
      informacionResidencial?.direccion_residencia === null ||
      informacionResidencial?.tiempo_en_vivienda === null ||
      informacionResidencial?.ciudad_residencia === null ||
      // informacionResidencial?.tipo_vivienda === null ||
      informacionResidencial?.estrato === null
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    setActiveStep(activeStep + 1);
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setInformacion = ({ target }) => {
    setInformacionResidencial({
      ...informacionResidencial,
      [target.name]: target.value,
    });
  };

  return (
    <Box component="form">
      <Typography
        variant="h4"
        component="h2"
        sx={{
          marginY: 3,
        }}
      >
        Información Residencial
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            onChange={setInformacion}
            value={informacionResidencial.direccion_residencia}
            name="direccion_residencia"
            label="Dirección de residencia"
            fullWidth
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <TextField
            onChange={setInformacion}
            value={informacionResidencial.tiempo_en_vivienda}
            name="tiempo_en_vivienda"
            label="Hace cuanto vive allí..."
            InputProps={{
              endAdornment: <InputAdornment position="start">años</InputAdornment>,
            }}
            type="number"
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12} md={3}>
          <TextField
            onChange={setInformacion}
            value={informacionResidencial.ciudad_residencia}
            type="text"
            name="ciudad_residencia"
            label="Ciudad"
            select
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          >
            {ciudadesSelect.map((option, idx) => (
              <MenuItem key={idx} value={option.codigo}>
                {option.nombre}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            type="text"
            onChange={setInformacion}
            value={informacionResidencial.tipo_vivienda}
            name="tipo_vivienda"
            label="Tipo de vivienda"
            select
            InputLabelProps={{
              shrink: true,
              htmlFor: 'tipo_vivienda',
            }}
            fullWidth
            inputProps={{
              id: 'tipo_vivienda',
              tabindex: '0',
            }}
          >
            <MenuItem value="propia">Propia</MenuItem>
            <MenuItem value="arrendada">Arrendada</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            type="text"
            name="estrato"
            label="Estrato"
            onChange={setInformacion}
            value={informacionResidencial.estrato}
            select
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6">6</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Box sx={{ margin: 2 }}>
        {activeStep !== 0 && (
          <Button sx={{ marginRight: 2 }} onClick={handleBack}>
            Anterior
          </Button>
        )}
        <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps?.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </Box>
    </Box>
  );
};

export default InformacionResidencial;
