/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Reproductor = ({ videoUrl, titulo }) => (
  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
    <Typography variant="h4" component="h2" gutterBottom>
      {titulo}
    </Typography>
    <Card sx={{ maxWidth: 845, boxShadow: 3 }}>
      <CardContent>
        <video controlsList="nodownload" controls style={{ width: '100%' }}>
          <source src={videoUrl} type="video/mp4" />
          Tu navegador no soporta videos MP4.
        </video>
      </CardContent>
    </Card>
  </Box>
);

export default Reproductor;
