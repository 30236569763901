import { contadorTiles } from '../../helper/contador';
import clienteAxios from '../../helper/axios';
import { creditosActivos } from './contadorCreditosSlice';

export const startcontadorCreditosActivos = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('/admin/tiles', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    const {
      solicitudesNuevos,
      preAprobadosAntiguosSin,
      preAprobadosNuevos,
      paraAprobarNuevos,
      paraAprobarAntiguos,
      porDesembolsarNuevos,
      porDesembolsarAntiguos,
      renovacionNuevos,
      solicitudesAntiguos,
      renovacionAntiguos,
      preAprobadosAntiguosCon,
      preAprobadosNuevosConDocumentos,
    } = contadorTiles(respuesta.data.contador);
    dispatch(
      creditosActivos({
        solicitudesNuevos,
        preAprobadosAntiguosSin,
        preAprobadosNuevos,
        paraAprobarNuevos,
        paraAprobarAntiguos,
        porDesembolsarNuevos,
        porDesembolsarAntiguos,
        renovacionNuevos,
        solicitudesAntiguos,
        renovacionAntiguos,
        preAprobadosAntiguosCon,
        preAprobadosNuevosConDocumentos,
      })
    );
  } catch (error) {
    console.error(error);
  }
};
