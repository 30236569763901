/* eslint-disable react/jsx-no-bind */
/* eslint-disable camelcase */
import React from 'react';
import ReplyIcon from '@mui/icons-material/Reply';
import { Button, Container, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import Sello from '../../client/components/sello/Sello';
import ContratoLetraAdmin from './PagareContrato';

const Contrato = () => {
  const navigate = useNavigate();
  const credito = useSelector((state) => state?.creditosActivos.creditoEditable);

  function handleClick() {
    navigate(-1);
  }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <ContratoLetraAdmin />
          <br />
          <br />
          <br />
          {credito?.fecha_Firma && (
            <Sello clase="stamp" mensaje={`Firmado en ${moment(credito?.fecha_Firma).format('l')}`} />
          )}
        </Grid>
        <Button
          variant="contained"
          color="warning"
          sx={{ marginRight: 3, display: 'flex', justifyContent: 'flex-end' }}
          onClick={handleClick}
          startIcon={<ReplyIcon />}
        >
          Atras
        </Button>
      </Grid>
    </Container>
  );
};
export default Contrato;
