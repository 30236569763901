import React from 'react';

import { Grid, Hidden, Typography } from '@mui/material';
import CardUser from './CardUser';
import CardInfo from './CardInfo';
import { Historico } from '../../components/historico/Historico';

const Profile = () => {
  const classes = {};
  return (
    <Grid container className={classes.contenedor}>
      <Grid item xs={12} sm={6}>
        <CardUser />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CardInfo />
      </Grid>
      <Hidden only={['xs', 'sm']}>
        <Grid item xs={12} sm={12}>
          <Grid item xs={6} sm={12}>
            <Typography variant="h5" component="h2" className={classes.title}>
              Histórico Créditos
            </Typography>
            <br />
            <Historico />
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Profile;
