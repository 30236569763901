/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PATH_NAME, TITLES } from '../constants';

import { startCargarFacturas } from '../store/facturas';
import { Facturacion } from '../components/facturacion/Facturacion';

export function InformeFacturacion() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isInformeFacturacion = location?.pathname.includes(PATH_NAME.informe_facturacion);

  useEffect(() => {
    dispatch(startCargarFacturas());
  }, []);

  return (
    <>
      <Helmet>
        <title> {isInformeFacturacion ? TITLES.informe_facturacion : TITLES.default} </title>
      </Helmet>

      <Facturacion />
    </>
  );
}
