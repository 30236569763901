import '../Style/WebStyle.css';

import { Box } from '@mui/material';
import React from 'react';
import fondobannercontacto from '../../assets/images/fondobannercontacto.png';
import slide2pagos from '../../assets/images/slide2pagos.png';

const Banner = () => (
  <Box sx={{ margin: '0 auto' }}>
    <div data-ride="banner" className="banner">
      {/* <div className="banner-inner">
        <img src={fondobannercontacto} alt="Imagen Fondo Banner Contacto" />
      </div> */}
      {/* <div>
        <div className="banner-item active">
          <img src={slide2pagos} alt="Slide de Pago" />
        </div>
      </div> */}
      <div className="banner-inner">
        <section className="titulo_InformacióndeContacto" id="contacto">
          <h1>Bienestar Financiero</h1>
          <p className="bannerp">
            Mejora tu historial crediticio con consejos personalizados basados en tu estado actual en Datacrédito.
          </p>
        </section>
      </div>
    </div>
  </Box>
);

export default Banner;
