/* eslint-disable react/prop-types */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { formateador } from '../../helper/Formateador';
import Scrollbar from '../scrollbar/Scrollbar';

export default function HistoricoCompromiso({ compromisoCreditos }) {
  return (
    <Box sx={{ margin: 2 }}>
      <Scrollbar>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Fecha del compromiso</TableCell>
                <TableCell>Valor Compromiso</TableCell>
                <TableCell>Observacion?</TableCell>
                <TableCell>Cumplio?</TableCell>
              </TableRow>
            </TableHead>

            {compromisoCreditos?.length > 0 ? (
              <TableBody>
                {compromisoCreditos?.map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {moment(row?.fecha_Compromiso).format('ll')}
                    </TableCell>
                    <TableCell>$ {formateador(row?.valor_Compromiso)}</TableCell>
                    <TableCell>{row?.nota_Compomiso}</TableCell>
                    <TableCell>{row?.cumplio_Compomiso ? 'Si' : 'No'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Typography component="legend" sx={{ display: 'flex', margin: 3, color: '#CCCCCC' }}>
                No existen compromisos.
              </Typography>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Box>
  );
}
