import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  informeElaborado: null,
};

export const bienestarSlice = createSlice({
  name: 'InformeBienestar',
  initialState,
  reducers: {
    cargarInformeCL: (state, action) => {
      state.informeElaborado = action.payload;
    },
  },
});

export const { cargarInformeCL } = bienestarSlice.actions;
