import React from 'react';
import { Box, Container, Typography, Paper, Button, List, ListItem, ListItemText } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { MAIN_PATH_CLIENTE, PATH_NAME_CLIENTE } from '../../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Reporte = () => {
  const navigate = useNavigate();
  const solicitar = () => {
    navigate(`/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.solicitud_bienestar_financiero}`);
    // dispatch(solicitudInforme());
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [
    '/assets/illustrations/image1.jpeg',
    '/assets/illustrations/image2.jpeg',
    '/assets/illustrations/image3.jpeg',
    '/assets/illustrations/image4.jpeg',
    '/assets/illustrations/image5.jpeg',
    '/assets/illustrations/image6.jpeg',
    '/assets/illustrations/image7.jpeg',
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <Box
              key={index}
              component="img"
              src={image}
              alt={`slide-${index}`}
              sx={{ width: '200px', height: '900px' }}
            />
          ))}
        </Slider>
        {/* <Typography variant="h4" gutterBottom>
          ¡Construye tu futuro financiero, mejora tu puntaje de crédito!
        </Typography>
        <Typography variant="body1" paragraph>
          En EasyCredit Colombia, somos un equipo apasionado y comprometido en ayudarte a tomar el control de tu
          historial crediticio. Nuestro objetivo es brindarte asesoramiento experto y personalizado para mejorar tu
          historial crediticio, abriendo así las puertas hacia un futuro financiero más sólido y lleno de posibilidades.
        </Typography>

        <Typography variant="h5" gutterBottom>
          ¿Por qué las centrales de riesgo son importantes?
        </Typography>
        <Typography variant="body1" paragraph>
          Sabemos lo crucial que es un buen historial de crédito para alcanzar tus metas financieras. Tu historial
          crediticio puede afectar tu capacidad para obtener préstamos, tarjetas de crédito, hipotecas y otras formas de
          financiamiento. Además, puede influir en las tasas de interés que te ofrecen, afectando directamente tus
          finanzas a largo plazo.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Nuestro enfoque personalizado:
        </Typography>
        <Typography variant="body1" paragraph>
          En easycredit Colombia, comprendemos que cada persona tiene una situación financiera única. Es por eso que
          ofrecemos un enfoque personalizado para cada cliente. Nuestro equipo de expertos analizará detenidamente tu
          reporte de crédito y evaluará tu situación crediticia actual. A partir de esta evaluación, diseñaremos un plan
          de acción específico y adaptado a tus necesidades para mejorar.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Lo que encontrarás en nuestro servicio:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Reporte actualizado centrales de riesgo"
              secondary="Obtendrás un detallado informe con nuestro concepto de tus datos en centrales de riesgo. Conocerás la información que afectan tu historial crediticio."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Consejos para mejorar tu historial"
              secondary="Basándonos en tu reporte, te proporcionaremos consejos personalizados para que puedas tomar medidas efectivas y mejorar tu vida crediticia."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Cuentas de ahorro y buen manejo financiero"
              secondary="Identificaremos todas tus cuentas de ahorro y te brindaremos pautas para un buen manejo financiero que impulse tu estabilidad económica."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Deudas en diferentes sectores"
              secondary="Analizaremos tus deudas en el sector real financiero, cooperativo y de telecomunicaciones. Te diremos cuáles son deudas positivas y cuáles pueden afectar tu historial, además de cómo manejarlas adecuadamente."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Manejo correcto de tus datos personales"
              secondary="Revisaremos si tus números de celular, dirección y correo electrónico están en bases de datos y te asesoraremos sobre el manejo correcto de esta información."
            />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Tu acceso a una vida financiera sólida:
        </Typography>
        <Typography variant="body1" paragraph>
          En easycredit Colombia, nuestro objetivo es brindarte las herramientas necesarias para que alcances una vida
          financiera sólida y exitosa. Con nuestro servicio, podrás tomar decisiones informadas y mejorar tu situación
          crediticia, abriendo así nuevas oportunidades y alcanzando tus metas económicas.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Precio accesible:
        </Typography>
        <Typography variant="body1" paragraph>
          Todo esto lo encontrarás en nuestro servicio por tan solo $70,000. Realiza tu pago fácil y seguro a través de
          PSE (Pagos Seguros en Línea) y BANCOLOMBIA. Aprovecha esta oportunidad para transformar tu vida crediticia.
        </Typography> */}

        <Box textAlign="center" sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" size="large" onClick={solicitar}>
            ¡Mejora tu futuro financiero hoy mismo!
          </Button>
        </Box>
        <Typography variant="body1" paragraph align="center" sx={{ mt: 2 }}>
          Ponte en contacto con nosotros y empieza a construir un mejor futuro financiero. ¡No esperes más para tomar el
          control de tu vida crediticia!
        </Typography>
      </Paper>
    </Container>
  );
};
