import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  informes: [],
  informeEditable: null,
  informesDone: [],
};

export const contadorBienestarSlice = createSlice({
  name: 'contadorInformeBienestar',
  initialState,
  reducers: {
    bienestar: (state, action) => {
      state.informes = action.payload;
      state.loading = false;
    },

    cargarInformeAEditar: (state, action) => {
      state.informeEditable = action.payload;
    },

    cargarInformeAImprimir: (state, action) => {
      state.informeAImprimir = action.payload;
    },

    limpiarInformeAEditar: (state) => {
      state.informeEditable = null;
    },

    informesDone: (state, action) => {
      state.informesDone = action.payload;
      state.loading = false;
    },
  },
});

export const { bienestar, cargarInformeAEditar, cargarInformeAImprimir, limpiarInformeAEditar, informesDone } =
  contadorBienestarSlice.actions;
