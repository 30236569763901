export const DIAS_PRIMERA_COBRANZA = 6;
export const DIAS_SEGUNDA_COBRANZA = 30;
export const VALOR_PRIMER_COBRANZA = 5000;
export const PORCENTAJE_SEGUNDA_COBRANZA = 0.4;
export const DIAS_MAXIMO_COBROS = 60;
export const PORCENTAJE_INTERES_MORATORIO = 0.0007;
export const VALOR_PLATAFORMA = 1150;
export const IVA = 0.19;

export const NIT = '901011172';
// export const NUMERO_RESOLUCION = '18764033523899';
// export const NUMERO_RESOLUCION = '18764053702554';
export const NUMERO_RESOLUCION = '18764061408013';
