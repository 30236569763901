/* eslint-disable camelcase */
import Swal from 'sweetalert2';
import { hoy } from '../../../helper/funtions';
import clienteAxios from '../../../helper/axios';
import { cargarCreditoCL, consultarBloqueadoPorRechazo, consultarHistoricoCL } from './creditoSlice';

export const consultarCreditoActivo = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('credito/consultar-activo', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    dispatch(cargarCreditoCL(respuesta.data.credito));
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const consultarBloqueo = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('credito/consulta-bloqueo', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(consultarBloqueadoPorRechazo(respuesta.data.bloqueadoPorRechazo));
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const consultarHistoricos = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('credito/historico', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });

    dispatch(consultarHistoricoCL(respuesta?.data?.creditos));
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const solicitudCredito = (valor_Solicitado, dias_Prestamo) => async (dispatch) => {
  const fecha_Solicitado = hoy;
  try {
    const respuesta = await clienteAxios.post(
      'credito/solicitud',
      { valor_Solicitado, dias_Prestamo, fecha_Solicitado },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    Swal.fire(respuesta.data.mensaje, 'Permanece atento a tu aplicación', respuesta.data.success);
    dispatch(cargarCreditoCL(respuesta.data.data));
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const editarCredito = (id, info) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.put(
      `credito/solicitud/${id}`,
      { info },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    Swal.fire(respuesta.data.mensaje, 'Permanece atento a tu aplicación', respuesta.data.success);

    dispatch(cargarCreditoCL(info));
  } catch (error) {
    console.error(error);
  }
};

export const pagoCompromiso = (id, info) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.put(
      `credito/compromiso/${id}`,
      { info },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    Swal.fire(respuesta.data.mensaje, 'Permanece atento a tu aplicación', respuesta.data.success);

    dispatch(cargarCreditoCL(info));
  } catch (error) {
    console.error(error);
  }
};
