// component
import { MAIN_PATH_CLIENTE, PATH_NAME_CLIENTE } from '../../../../constants';
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Inicio',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.home_cliente}`,
    icon: <Iconify icon={'fluent-mdl2:view-dashboard'} width={1} height={1} />,
  },
  {
    title: 'Contrato',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.contrato_cliente}`,
    icon: <Iconify icon={'clarity:contract-line'} width={1} height={1} />,
  },

  {
    title: 'Historico Créditos',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.historico_contrato_cliente}`,
    icon: <Iconify icon={'iwwa:history'} width={1} height={1} />,
  },
  {
    title: 'Liquidar Credito',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.liquidar_contrato_cliente}`,
    icon: <Iconify icon={'material-symbols:payments-outline'} width={1} height={1} />,
  },
  {
    title: 'Renovar Credito',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.renovar_credito}`,
    icon: <Iconify icon={'material-symbols:autorenew-rounded'} width={1} height={1} />,
  },
  // {
  //   title: 'Compromisos',
  //   path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.abono_compromiso}`,
  //   icon: <Iconify icon={'fluent:payment-32-regular'} width={1} height={1} />,
  // },
  {
    title: 'Bienestar Financiero',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.bienestar_financiero}`,
    icon: <Iconify icon={'mdi:report-bell-curve-cumulative'} width={1} height={1} />,
  },
  {
    title: 'Cursos',
    path: `/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.cursos}`,
    icon: <Iconify icon={'arcticons:studyplus'} width={1} height={1} />,
  },
];

export default navConfig;
