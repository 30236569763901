import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authSlice } from './clientUser/auth';
import { clientesSlice } from './clientes/clientesSlice';
import { contadorCreditosSlice } from './contadorCreditos';
import { creditosActivosSlice } from './creditosActivos';
import { facturasSlice } from './facturas';
import { historicosSlice } from './historicos';
import { cuentaSlice } from './clientUser';
import { creditoSlice } from './clientUser/credito/creditoSlice';
import { adminSlice } from './admin/AdminSlice';
import { contadorBienestarSlice } from './contadorBienestar';
import { bienestarSlice } from './clientUser/bienestar';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['contadorCreditos', 'userAdmin', 'clientes', 'usuarioCL', 'cuentaCL', 'creditoCL'],
};

// Combine all reducers into a single reducer
const rootReducer = {
  contadorCreditos: contadorCreditosSlice.reducer,
  contadorInfor: contadorBienestarSlice.reducer,
  creditosActivos: creditosActivosSlice.reducer,
  userAdmin: adminSlice.reducer,
  clientes: clientesSlice.reducer,
  historicos: historicosSlice.reducer,
  facturas: facturasSlice.reducer,
  usuarioCL: authSlice.reducer,
  cuentaCL: cuentaSlice.reducer,
  creditoCL: creditoSlice.reducer,
  informeFinancieroCL: bienestarSlice.reducer,
};

// Combine all reducers into a single reducer function
const combinedReducer = combineReducers(rootReducer);

// Apply the persistConfig to the combinedReducer to create the persistedReducer
const persistedReducer = persistReducer(persistConfig, combinedReducer);

// Configure the store with the persistedReducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['user.sessions'],
      },
    }),
  ],
});

// Create the persistor object and export it
export const persistor = persistStore(store);
