export const MAIN_PATH = '1n1c10';
export const PATH_PUBLIC = 'ea-auth-sy';
export const MAIN_PATH_CLIENTE = 'easy-20credito';

export const PATH_NAME = {
  gestor_de_clave: 'gestor-claves',
  home_admin: '202208261n1c10',
  gestor_de_compromisos: 'gestor-compromisos',
  gestor_de_creditos: 'gestor-creditos',
  gestor_de_creditos_atrazados: 'gestor-creditos-atrazados',
  gestor_de_datos: 'gestor-datos',
  gestor_de_pagos: 'gestor-pagos',
  gestor_de_pagos_manuales: 'gestor-pagos-manuales',
  listado_de_compromisos: 'listado-compromisos',
  listado_de_compromisos_por_vencer: 'listado-compromisos-por-vencer',
  listado_de_compromisos_vencidos: 'listado-compromisos-vencidos',
  informe_facturacion: 'informe-facturacion',
  informe_de_gestion: 'informe-gestion',
  informe_de_gestion_solicitados: 'informe-gestion/solicitados',
  informe_de_gestion_rechazados: 'informe-gestion/rechazados',
  informe_de_gestion_desembolsados: 'informe-gestion/desembolsados',
  informe_de_gestion_cancelados: 'informe-gestion/cancelados',
  informe_de_gestion_renovados: 'informe-gestion/renovados',
  informe_historico: 'informe-historico',
  informe_de_davivienda: 'informe-davivienda',
  informe_de_dataCredito: 'informe-datacredito',
  gestor_de_renovaciones: 'gestor-renovaciones',
  gestionar_credito: 'gestionar-credito',
  gestionar_informe: 'gestionar-infor-bienestar',
  gestionar_datos_cliente: 'gestionar-datos-cliente',
  gestionar_credito_vencido: 'gestionar-credito-vencido',
  gestionar_renovacion_credito: 'gestionar-renovacion-credito',
  gestionar_notas_usuario: 'gestionar-notas-usuario',
  gestionar_notas_credito: 'gestionar-notas-credito',
  historico_cliente: 'historico-creditos/cliente',
  contrato_credito: 'contrato/credito',
  renovar_credito: 'renovar-credito',
  cancelar_credito: 'cancelar-credito',
  visualizar_credito_historico: 'historico-credito',
  //  ====== Lista de creditos clasificados ======

  //   Usuarios antiguos
  solicitudes_de_usuarios_antiguos: 'solicitud-creditos-clientes-antiguos',
  solicitudes_de_renovaciones_de_usuarios_antiguos: 'solicitud-renovacion-creditos-clientes-antiguos',
  creditos_preaprobados_sin_documentos_de_usuarios_antiguos: 'creditos-preaprobados-sin-documentos-clientes-antiguos',
  creditos_preaprobados_con_documentos_de_usuarios_antiguos: 'creditos-preaprobados-con-documentos-clientes-antiguos',
  creditos_por_aprobar_de_usuarios_antiguos: 'creditos-por-aprobar-clientes-antiguos',
  creditos_por_desembolsar_de_usuarios_antiguos: 'creditos-por-desembolsar-clientes-antiguos',
  informe_bienestar_financiero_antiguos: 'informe-bienestar-financiero-ant',
  //   Usuarios nuevos
  solicitudes_de_usuarios_nuevos: 'solicitud-creditos-clientes-nuevos',
  solicitudes_de_renovaciones_de_usuarios_nuevos: 'solicitud-renovacion-creditos-clientes-nuevos',
  creditos_preaprobados_sin_documentos_de_usuarios_nuevos: 'creditos-preaprobados-sin-documentos-clientes-nuevos',
  creditos_preaprobados_con_documentos_de_usuarios_nuevos: 'creditos-preaprobados-con-documentos-clientes-nuevos',
  creditos_por_aprobar_de_usuarios_nuevos: 'creditos-por-aprobar-clientes-nuevos',
  creditos_por_desembolsar_de_usuarios_nuevos: 'creditos-por-desembolsar-clientes-nuevos',
  informe_bienestar_financiero_nuevos: 'informe-bienestar-financiero-nuevos',
  informe_bienestar_financiero: 'informe-bienestar-financiero',
  listado_informe_bienestar_financiero: 'listado-informe-bienestar-financiero',
  imprimir_bienestar_financiero: 'imprimir-bienestar-financiero',
};

export const PATH_NAME_CLIENTE = {
  home_cliente: 'h0inicio',
  contrato_cliente: 'contrato',
  liquidar_contrato_cliente: 'liquidar-contrato',
  historico_contrato_cliente: 'historico-creditos',
  renovar_credito: 'renovar-credito',
  abono_compromiso: 'abono-compromiso',
  perfil_cliente: 'perfil-262022',
  editar_perfil_cliente: 'editar-perfil-2620220426',
  cursos: 'cursos',
  bienestar_financiero: 'bienestar-financiero',
  solicitud_bienestar_financiero: 'solicitud-bienestar-financiero',
};
