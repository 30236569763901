import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import moment from 'moment';
import { formateador } from '../../../helper/Formateador';
import Epayco from './Epayco';
import {  pagoCompromiso } from '../../../store/clientUser/credito/thunk';
import { hoy } from '../../../helper/funtions';
import img from './efectivo.jpeg';

const OpcionPagoCompromiso = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const credito = useSelector((state) => state.creditoCL.creditoActivo);
  const usuario = useSelector((state) => state.infoUsuario)

  const siguienteCompromiso = credito.compromisoCreditos
    .filter((objeto) => objeto.cumplio_Compomiso === null) // Filtrar objetos con cumplio en null
    .sort((a, b) => moment(a.fecha_Compromiso) - moment(b.fecha_Compromiso)) // Ordenar por fecha ascendente
    .find(() => true); // Encontrar el primer objeto (el más antiguo)


  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    // dispatch(cancelarAbonoEpayco())
    setOpen(true);
  };

  const efectuarPago = (e) => {
    e.preventDefault();
    handleClose();

    Swal.fire({
      title: '¿Tu pago fue exitoso?',
      text: 'Recuerda que para realizar esta acción tu pago electronico debio ser exitoso, si tu pago fue en efectivo recuerda enviar el soporte',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Mi pago fue exitoso!',
      cancelButtonText: 'No, Cancelar',
    }).then(() => {
      dispatch(
        pagoCompromiso(siguienteCompromiso?.id, {
          ...credito,
          fecha_Pago_Compromiso: hoy,
        })
      );
    });
  };

  return (
    <>
      {credito?.fecha_Cancelado !== null && credito?.cancelado === null && (
        <Alert severity="warning">Tu pago esta siendo verificado.</Alert>
      )}
      {credito?.solicitud_Renovacion === true && <Alert severity="warning">Tu pago esta siendo verificado.</Alert>}
      <form onSubmit={efectuarPago}>
        <Grid container>
          <FormControl sx={{ mx: 'auto' }}>
            <Grid container sx={{ display: 'flex' }}>
              <Grid sx={{ margin: 2 }}>
                <h4>Detalle Pago:</h4>
                <Typography>{` El valor del próximo compromiso es por $ ${formateador(
                  siguienteCompromiso?.valor_Compromiso
                )}, con fecha programada para el ${moment(siguienteCompromiso?.fecha_Compromiso).format(
                  'l'
                )}.`}</Typography>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            marginTop: 3,
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{ width: '150px' }}
              disabled={
                (credito?.fecha_Cancelado !== null && credito?.cancelado === null) || credito?.solicitud_Renovacion
              }
            >
              Efectivo
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
            <Epayco
              abono={siguienteCompromiso?.valor_Compromiso}
              cuotaId={`C${credito?.id}`}
              celular={usuario?.celular}
              nombre={`${usuario?.primerNombre} ${usuario?.primerApellido}`}
              direccionResidencia={usuario?.direccion_residencia}
              monto={siguienteCompromiso?.valor_Compromiso}
              descripcion={`Crédito: C${credito?.id}`}
              cedula={`Cedula: ${usuario?.cedula}`}
              inactivo={credito?.fecha_Cancelado !== null && credito?.cancelado === null}
            />
          </Grid>
        </Grid>
      </form>
      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Vas a pagar en Efectivo?'}</DialogTitle>
        <DialogContent>
          <img src={img} alt="Logo Easy Credit" />
          <DialogContentText>
            Solo cuando efectues el pago selecciona la opción,
            <strong>REGISTRAR PAGO</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
          <Button type="submit" onClick={efectuarPago} color="primary" autoFocus>
            REGISTRAR PAGO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OpcionPagoCompromiso;
