/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Typography } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { startConsultarFacturas } from '../../store';
import { filtrarPorFecha } from '../../helper/funtions';
import TableList from '../table-list/TableList';

export const Facturacion = () => {
  const { facturas } = useSelector((state) => state.creditosActivos);
  const [desde, setDesde] = useState(null);
  const [hasta, setHasta] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startConsultarFacturas());
  }, []);

  const generarInfo = () => {
    setData(filtrarPorFecha(facturas, desde, hasta));
    setShowTable(true);
  };

  const columnas = [
    {
      label: 'N. Factura',
      name: 'id',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Crédito',
      name: 'creditoId',
      options: {
        filter: true,
      },
    },
    {
      label: 'Fecha Facturado',
      name: 'createdAt',
      options: {
        filter: true,
      },
    },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Card sx={{ marginBottom: 4 }}>
        <Typography variant="h4" sx={{ margin: 4 }}>
          Informes de Facturación:
        </Typography>
        <Box
          sx={{
            maxWidth: 600,
            margin: '50px auto',
          }}
        >
          <DemoContainer components={['DatePicker', 'DatePicker']}>
            <DatePicker label="Desde" value={desde} onChange={(newValue) => setDesde(newValue)} />
            <DatePicker label="Hasta" value={hasta} onChange={(newValue) => setHasta(newValue)} />
          </DemoContainer>
          <Button onClick={generarInfo} sx={{ marginTop: 2 }} disabled={desde === null && hasta === null}>
            Generar Informe
          </Button>
        </Box>
        {showTable && (
          <TableList titulo="Lista de Compromisos Atrasados" columnas={columnas} loading={false} data={data} />
        )}
      </Card>
    </LocalizationProvider>
  );
};
