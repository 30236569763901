import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Link as MuiLink, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Iconify from '../../../components/iconify';
import { startLogin } from '../../../store/clientUser/auth';
import { MAIN_PATH_CLIENTE, PATH_NAME_CLIENTE } from '../../../constants';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: null,
    password: null,
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = async () => {
    try {
      await dispatch(startLogin(data.email, data.password));
      navigate(`/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.home_cliente}`);
      window.history.pushState(null, '', window.location.href);
    } catch (error) {
      Swal.fire('Error', error.toString(), 'error');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Correo Electronico"
          onChange={({ target }) => setData({ ...data, email: target.value })}
        />

        <TextField
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          onChange={({ target }) => setData({ ...data, password: target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <MuiLink variant="subtitle2" underline="hover" component={RouterLink} to="/easy-auth/recuperar-cta">
          Olvidó la contraseña?
        </MuiLink>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Ingresar
      </LoadingButton>
    </>
  );
}
