/* eslint-disable no-unused-vars */
import { Chip, IconButton, Skeleton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Iconify from '../iconify/Iconify';

import TableList from '../table-list/TableList';
import { coleccionCreditosPorAsentarPagos, coleccionCreditosVencidos } from '../../helper/coleccionCreditos';
import { cargarCreditoAEditar } from '../../store';
import { MAIN_PATH, PATH_NAME } from '../../constants';
import AsentarPago from '../asentarPago/AsentarPago';

export const PagosPorAsentar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { creditos, loading, loadingClarisa } = useSelector((state) => state?.creditosActivos);
  const data = coleccionCreditosPorAsentarPagos(creditos);

  const redireccionarEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito_vencido}`, { replace: true });
  };

  const asentarPagoEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    dispatch(cargarCreditoAEditar(credito));
    setOpen(true);
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Aprobado',
      name: 'valorSolicitado',
      options: {
        filter: true,
      },
    },
    {
      label: 'Fecha de Pago',
      name: 'fechaPago',
      options: {
        filter: false,
      },
    },
    {
      label: 'Valor Cancelado',
      name: 'valorCancelado',
      options: {
        filter: true,
      },
    },
    {
      name: 'Asentar',
      options: {
        sort: false,
        customBodyRender: (_, tableMeta) => (
          <>
            <Tooltip title="Asentar Crédito">
              <IconButton onClick={() => asentarPagoEdicion(tableMeta.rowData[2])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
    {
      name: 'Ir al Crédito',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta, _updateValue) => (
          <>
            <Tooltip title="Ir al Crédito" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
                <Iconify icon="ic:outline-manage-search" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },

    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (_, tableMeta, _updateValue) => (
          <Tooltip title="Copiar y Pegar">
            <IconButton
              aria-label="Gestionar"
              onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1].replaceAll('.', ''))}
            >
              <Iconify icon="mdi-light:clipboard-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return (
    <>
      <AsentarPago open={open} setOpen={setOpen} />
      {loadingClarisa ? (
        <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 1.7 }}>
          <div style={{ paddingTop: '57%' }} />
        </Skeleton>
      ) : (
        <TableList titulo="Gestión de Pagos:" columnas={columnas} loading={loading} data={data} />
      )}
    </>
  );
};
