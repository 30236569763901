/* eslint-disable consistent-return */
import Swal from 'sweetalert2';
import clienteAxios from '../../../helper/axios';
import { cerrarSesion, login } from './authSlice';

export const startRegister = (email, password) => async (dispatch) => {
  try {
    await clienteAxios.post('usuario/registro', { email, password });
    dispatch(startLogin(email, password));
    return Swal.fire('success', 'Cuenta Creada', 'Bien Hecho');
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const startLogin = (email, password) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.post('usuario/login', { email, password });

    localStorage.setItem('token', respuesta.data.token);
    localStorage.setItem('id', respuesta.data.id);
    localStorage.setItem('censado', respuesta.data.censado);
    localStorage.setItem('token-init-date', new Date().getTime());
    dispatch(
      login({
        id: respuesta.data?.id,
        name: respuesta.data?.name,
        censado: respuesta.data?.censado,
        logueado: true,
      })
    );
    // dispatch(consultarUsuario(body.id));
    // dispatch(consultarCreditoActivo());
    // dispatch(consultarHistorico());
  } catch (error) {
    throw Error(error?.response?.data?.msg || 'correo o contraseña invalida');
  }
};

export const startCerrarSesion = () => async (dispatch) => {
  try {
    dispatch(cerrarSesion());
    localStorage.clear();
  } catch (error) {
    console.error(error);
  }
};

export const cambioPassword = (email) => async () => {
  try {
    const respuesta = await clienteAxios.post('usuario/reestablecer', { email });
    if (respuesta.data.ok !== true) {
      Swal.fire(respuesta.data.mensaje || 'Se envio un correo');
    } else {
      Swal.fire('Error', respuesta.data.errors.email.msg, 'error');
    }
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const resetPassword = (token, password) => async () => {
  try {
    await clienteAxios.post(`usuario/reestablecer/${token}`, { password });

    Swal.fire('Datos Actualizados');
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};
