/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import { coleccionInformeBienestar } from '../../../helper/coleccionCreditos';

import TableList from '../../../components/table-list';
import { MAIN_PATH, PATH_NAME } from '../../../constants';
import Iconify from '../../../components/iconify/Iconify';
import { cargarInformeAEditar } from '../../../store';

export function BienestarFinancieroAntiguos() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [solicitudes, setSolicitudes] = useState({
    loading: true,
    data: [],
  });

  const { informes } = useSelector((state) => state.contadorInfor);

  const informesAntiguos = informes?.filter((informe) => informe.cuentaUsuario.antiguo === true);

  useEffect(() => {
    setSolicitudes({
      loading: false,
      data: coleccionInformeBienestar(informesAntiguos),
    });
  }, []);

  const redireccionarEdicion = (idInforme) => {
    const informe = informes.filter((informe) => informe.id === idInforme)[0];

    dispatch(cargarInformeAEditar(informe));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_informe}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cédula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    // {
    //   label: 'Monto Aprobado',
    //   name: 'valorSolicitado',
    //   options: {
    //     filter: true,
    //   },
    // },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Gestionar" aria-label="Gestionar">
            <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
              <Iconify icon="material-symbols:edit-square-outline" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Copiar y Pegar">
            <IconButton
              aria-label="Gestionar"
              onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1].replaceAll('.', ''))}
            >
              <Iconify icon="mdi-light:clipboard-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];
  return (
    <>
      <Helmet>
        <title> Informes de Bienestar Financiero - Clientes Antiguos</title>
      </Helmet>

      <TableList
        titulo="Clientes Antiguos | Informe Bienestar Financiero"
        columnas={columnas}
        loading={solicitudes.loading}
        data={solicitudes.data}
      />
    </>
  );
}
