import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import { formateador } from '../../../helper/Formateador';
import Epayco from './Epayco';
import { calculosDelCredito } from '../../../helper/liquidarCredito';

import img from './efectivo.jpeg';

const OpcionPago = () => {
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const credito = useSelector((state) => state.creditoCL.creditoActivo);
  const usuario = useSelector((state) => state.infoUsuario);

  const {
    montoAprobando,
    valorAdministracion,
    valorPlataforma,
    valorPrimerCobranza,
    valorIva,
    interesCorriente,
    interesPorMora,
    valorSegundaCobranza,
    valorTotalParaPago,
  } = calculosDelCredito(credito);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const efectuarPago = (e) => {
    e.preventDefault();
    handleClose();

    Swal.fire({
      title: '¿Tu pago fue exitoso?',
      text: 'Recuerda que para realizar esta acción tu pago electrónico debió ser exitoso, si tu pago fue en efectivo recuerda enviar el soporte',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Mi pago fue exitoso!',
      cancelButtonText: 'No, Cancelar',
    }).then(() => {
      Swal.fire({
        text: 'Recuerda enviar el soporte de tu pago',
        icon: 'success',
        confirmButtonText: 'Ok',
      });
    });
  };

  return (
    <>
      {credito?.fecha_Cancelado !== null && credito?.cancelado === null && (
        <Alert severity="warning">Tu pago esta siendo verificado.</Alert>
      )}
      {credito?.solicitud_Renovacion === true && <Alert severity="warning">Tu pago esta siendo verificado.</Alert>}
      <form onSubmit={efectuarPago}>
        <Grid container>
          <FormControl sx={{ mx: 'auto' }}>
            <Grid container sx={{ display: 'flex' }}>
              <Grid sx={{ margin: 2 }}>
                <Box>
                  <Card sx={{ padding: 3, lineHeight: 'normal', fontSize: 12 }}>
                    <h4>Detalle Pago:</h4>
                    <p>Credito: $ {formateador(montoAprobando)}</p>
                    <p>Administración: $ {formateador(valorAdministracion)}</p>
                    <p>Plataforma: $ {formateador(valorPlataforma)}</p>
                    <p>IVA: $ {formateador(valorIva)}</p>
                    <p>Intereses: $ {formateador(interesCorriente)}</p>
                    {interesPorMora > 0 && <p>Intereses por mora: $ {formateador(interesPorMora)}</p>}
                    {valorPrimerCobranza > 0 && <p>Primera Cobranza: $ {formateador(valorPrimerCobranza)}</p>}
                    {valorSegundaCobranza > 0 && <p>Primera Cobranza: $ {formateador(valorSegundaCobranza)}</p>}
                    <p>Total: $ {formateador(valorTotalParaPago)}</p>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            marginTop: 3,
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '150px' }}
              onClick={handleClickOpen}
              disabled={
                (credito?.fecha_Cancelado !== null && credito?.cancelado === null) || credito?.solicitud_Renovacion
              }
            >
              Efectivo
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
            <Epayco
              abono={valorTotalParaPago}
              cuotaId={`C${credito?.id}`}
              celular={usuario?.celular}
              nombre={`${usuario?.primerNombre} ${usuario?.primerApellido}`}
              direccionResidencia={usuario?.direccion_residencia}
              monto={valorTotalParaPago}
              descripcion={`Crédito: C${credito?.id}`}
              cedula={`Cédula: ${usuario?.cedula}`}
              inactivo={credito?.fecha_Cancelado !== null && credito?.cancelado === null}
            />
          </Grid>
        </Grid>
      </form>

      <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Vas a pagar en Efectivo?'}</DialogTitle>
        <DialogContent>
          <img src={img} alt="Logo Easy Credit" />
          <DialogContentText>
            Solo cuando efectúes el pago selecciona la opción,
            <strong>REGISTRAR PAGO</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
          <Button type="submit" onClick={efectuarPago} color="primary" autoFocus>
            REGISTRAR PAGO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OpcionPago;
