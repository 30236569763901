/* eslint-disable react/prop-types */
import { FormControl, Grid, TextField } from '@mui/material';
import React from 'react';

const ComoDesempleado = ({ setDesempleado, clienteInfo }) => {
  const classes = {};
  const handleDatosChange = (e) => {
    setDesempleado({
      ...clienteInfo,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Tiempo de Inactividad"
            variant="filled"
            name="tiempo_desempleado"
            value={clienteInfo?.tiempo_desempleado}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ComoDesempleado;
