import clienteAxios from '../../helper/axios';
import { cargarHistoricoClienteEditable, cargarHistoricos } from './historicosSlice';

export const startConsultarCreditosHistorico = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get('/admin/historicos', {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    dispatch(cargarHistoricos(respuesta.data.historicos));
  } catch (error) {
    console.error(error);
  }
};

export const startHistoricoClienteEditable = (id) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get(`admin/historicos/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-token': localStorage.getItem('token') || '',
      },
    });
    dispatch(cargarHistoricoClienteEditable(respuesta.data.historicos));
  } catch (error) {
    console.error(error);
  }
};
