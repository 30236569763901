/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState } from 'react';

import { MenuItem, Box, Typography, Grid, Select, InputLabel } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { Empleado } from './Empleado';
import { Desempleado } from './Desempleado';
import { Independiente } from './Independiente';
import { Pensionado } from './Pensionado';

function InformacionLaboral({
  activeStep,
  setActiveStep,
  steps,
  preguntasEmpleados,
  setPreguntasEmpleados,
  PreguntasXIndependientes,
  setPreguntasXIndependientes,
  preguntasPensionados,
  setPreguntasPensionados,
  preguntasDesempleado,
  setPreguntasDesempleado,
}) {
  const [tipoTrabajo, setTipoTrabajo] = useState('');

  return (
    <Box component="form">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            marginY: 3,
          }}
        >
          Información Laboral
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel id="demo-simple-select-label">Tipo de Trabajador</InputLabel>
            <Select
              name="tipoTrabajo"
              value={tipoTrabajo}
              onChange={({ target }) => setTipoTrabajo(target.value)}
              labelId="demo-simple-select-label"
              fullWidth
            >
              <MenuItem value="Empleado">Empleado</MenuItem>
              <MenuItem value="Independiente">Independiente</MenuItem>
              <MenuItem value="Pensionado">Pensionado</MenuItem>
              <MenuItem value="Desempleado">Desempleado</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            {tipoTrabajo === 'Empleado' && (
              <Empleado
                preguntasEmpleados={preguntasEmpleados}
                setPreguntasEmpleados={setPreguntasEmpleados}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                steps={steps}
              />
            )}
          </Grid>
          {tipoTrabajo === 'Desempleado' && (
            <Desempleado
              preguntasDesempleado={preguntasDesempleado}
              setPreguntasDesempleado={setPreguntasDesempleado}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              steps={steps}
            />
          )}
          {tipoTrabajo === 'Independiente' && (
            <Independiente
              PreguntasXIndependientes={PreguntasXIndependientes}
              setPreguntasXIndependientes={setPreguntasXIndependientes}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              steps={steps}
            />
          )}
          {tipoTrabajo === 'Pensionado' && (
            <Pensionado
              preguntasPensionados={preguntasPensionados}
              setPreguntasPensionados={setPreguntasPensionados}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              steps={steps}
            />
          )}
        </Grid>
      </LocalizationProvider>
    </Box>
  );
}

export default InformacionLaboral;
