/* eslint-disable react/prop-types */
import React from 'react';
import { FormControl, Grid, TextField } from '@mui/material';

const ReferenciaPersonal = ({ setReferenciaPersonal, clienteInfo }) => {
  const classes = {};

  const handleDatosChange = (e) => {
    setReferenciaPersonal((anterior) => ({
      ...anterior,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Nombres"
            variant="filled"
            name="referencia_personal_nombres"
            value={clienteInfo?.referencia_personal_nombres}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Apellidos"
            variant="filled"
            name="referencia_personal_apellidos"
            value={clienteInfo?.referencia_personal_apellidos}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Ciudad"
            variant="filled"
            name="referencia_personal_ciudad"
            value={clienteInfo?.referencia_personal_ciudad}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Telefono"
            variant="filled"
            name="referencia_personal_telefono"
            value={clienteInfo?.referencia_personal_telefono}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ReferenciaPersonal;
