import { Grid } from '@mui/material';
import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const Requisitos = () => (
  <Grid item xs={12}>
    <div className="cajarequisitos" id="requisitos">
      <h2>Requisitos para obtener un crédito</h2>
    </div>
    <div className="requisitos">
      <div className="requisitoscaja">
        <PeopleAltIcon className="icono" fontSize="large" />
        <p className="requisitotexto">Ser mayor de edad colombiano residente</p>
      </div>
      <div className="requisitoscaja">
        <CreditCardIcon className="icono" fontSize="large" />
        <p className="requisitotexto">Tener cuenta bancaria a tu nombre</p>
      </div>
      <div className="requisitoscaja">
        <DraftsIcon className="icono" fontSize="large" />
        <p className="requisitotexto">Tener correo propio</p>
      </div>
      <div className="requisitoscaja">
        <PhoneIphoneIcon className="icono" fontSize="large" />
        <p className="requisitotexto">Tener línea de celular propia</p>
      </div>
      <div className="requisitoscaja">
        <EventAvailableIcon className="icono" fontSize="large" />
        <p className="requisitotexto">No estar reportado en centrales de riesgo</p>
      </div>
    </div>
  </Grid>
);

export default Requisitos;
