/* eslint-disable consistent-return */
import Swal from 'sweetalert2';
import clienteAxios from '../../helper/axios';
import { cargarAdmin, cerrarSesionAmd } from './AdminSlice';

const TokenInicioClarisa = (method = 'POST') => {
  // const urlPrueba = 'https://central.clarisacloud.com:8443/seguridad/rest/api/v1/login/';
  const url = `https://csi.clarisa.co:8443/seguridad/rest/api/v1/login/`;
  return fetch(url, {
    method,
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      // usuario: 'demo_api',
      // contrasenia: '12345678',
      usuario: 'rendon_200@hotmail.com',
      contrasenia: '1128423865sara',
    }),
  });
};

export const startRegisterAdmin = (email, password, nombre, rol, clarisaUser) => async () => {
  try {
    await clienteAxios.post('usuario-admin/registro-admin', { email, password, nombre, rol, clarisaUser });
    return Swal.fire('success', 'Cuenta Creada', 'Bien Hecho');
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const startLoginAdmin = (email, password) => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.post('usuario-admin/login-admin', { email, password });
    if (respuesta?.data?.success) {
      dispatch(cargarAdmin(respuesta?.data));
      localStorage.setItem('token', respuesta?.data?.token);
      localStorage.setItem('rol', respuesta?.data?.rol);
      localStorage.setItem('id', respuesta?.data?.id);
      localStorage.setItem('token-init-date', new Date().getTime());
    } else {
      Swal.fire(
        'Error',
        respuesta?.data?.errors?.email?.msg || respuesta?.data?.errors?.password?.msg || respuesta?.data?.msg,
        'error'
      );
    }

    if (respuesta?.data?.clarisaUser) {
      const clarisaResp = await TokenInicioClarisa();
      const clarisa = await clarisaResp.json();
      if (clarisa.success === false) {
        return Swal.fire('Error en Clarisa', clarisa.errores.errores[0].errorMessage, 'error');
      }
      localStorage.setItem('tokenClarisa', clarisa.data.token);
    }
  } catch (error) {
    throw Error(error?.response?.data?.msg);
  }
};

export const startCerrarSesionAdmin = () => async (dispatch) => {
  try {
    dispatch(cerrarSesionAmd());
    localStorage.clear();
  } catch (error) {
    console.error(error);
  }
};
