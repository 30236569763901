import React from 'react';

import { useSelector } from 'react-redux';

import { Card, CardActions, CardContent, Divider, Grid } from '@mui/material';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import logo from '../../components/logo/logoColor.png';
import { formateador } from '../../helper/Formateador';
import DetalleCancelarCredito from './DetalleCancelarCredito';

const fecha = new Date();
fecha.toString();

export default function CancelarCredito() {
  const credito = useSelector((state) => state?.creditosActivos?.creditoEditable);
  const infoPersonal = useSelector((state) => state?.creditosActivos?.creditoEditable?.cuentaUsuario);

  return (
    <Grid container>
      <Card className="rootLiquidar">
        <CardContent>
          <div className="Header">
            <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <img src={logo} alt="Logo Easy Credit" className="logo" />
            </Grid>

            <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <div className="subHeader">
                <p className="p">EASY CREDIT COLOMBIA</p>
                <p className="p">Calle 44 # 79-86 of 207</p>
                <p className="p">Nit: 901.011.172 </p>
              </div>
            </Grid>

            <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <div className="subHeader">
                <p className="p"> Medellin, Antioquía, Colombia</p>
                <strong>
                  <p className="p">Credito: C{credito?.id}</p>
                </strong>
              </div>
            </Grid>
          </div>

          <Divider
            variant="middle"
            className="espacio"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block', sx: 'block' },
            }}
          />

          <Grid container className="contenedorSubtitulo">
            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">
                  Nombre:
                  {` ${infoPersonal?.primerNombre} ${infoPersonal?.primerApellido}`}
                </p>
                <p className="p"> Cedula: {infoPersonal?.cedula}</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">Monto: $ {formateador(credito?.valor_Aprobado)}</p>
                <p className="p">
                  Plazo Crédito: {formateador(credito?.dias_Prestamo)} {credito?.dias_Prestamo === 1 ? 'día' : 'Dias'}
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">
                  Fecha Desembolso:
                  {` ${moment(credito?.fecha_Desembolsado).format('ll')}`}
                </p>
              </div>
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            className="espacio"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block', sx: 'block' },
            }}
          />

          <Grid container className="contenedor">
            <DetalleCancelarCredito />
          </Grid>
        </CardContent>

        <Divider variant="middle" className="espacio" />
        <Grid container>
          <Grid xs={12} sm={12} md={4}>
            <CardActions className="botones">
              <Grid direction="row" justify="center" alignItems="center">
                <Helmet>
                  <script src="https://checkout.epayco.co/checkout.js" />
                </Helmet>
                <Helmet>
                  <script src="https://checkout.epayco.co/checkout.js?version=1598838997349?version=1598838997397" />
                </Helmet>
              </Grid>
            </CardActions>
          </Grid>

          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
            <CardContent className="contenedorfooter">
              <div className="subFooter">
                <p className="pPrestamo">IVA Régimen Común:</p>
                <p className="pPrestamoDescripcion">
                  Resolución de Facturación 18764033523899, desde 3,645 hasta 5,000:
                </p>
                <p className="pPrestamoDescripcion">No somos grandes contribuyentes:</p>
                <p className="pPrestamoDescripcion">No somos autorretenedores de IVA:</p>
              </div>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
