import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { MAIN_PATH, PATH_NAME, TITLES } from '../constants';
import Iconify from '../components/iconify/Iconify';
import TableList from '../components/table-list';
import { coleccionCreditosActivosDesembolsados } from '../helper/coleccionCreditos';
import { cargarCreditoAEditar } from '../store';

export function GestorRenovaciones() {
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isInformeFacturacion = location?.pathname.includes(PATH_NAME.gestor_de_renovaciones);

  const { loading, creditos } = useSelector((state) => state.creditosActivos);

  const data = coleccionCreditosActivosDesembolsados(creditos);

  const redireccionarEdicion = (idCredito) => {
    const credito = creditos.filter((credito) => credito.id === idCredito)[0];
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_renovacion_credito}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Desembolsado',
      name: 'valor_Aprobado',
      options: {
        filter: true,
      },
    },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <>
            <Tooltip title="Gestionar" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (_value, tableMeta) => (
          <Tooltip title="Copiar y Pegar">
            <IconButton
              aria-label="Gestionar"
              onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1].replaceAll('.', ''))}
            >
              <Iconify icon="mdi-light:clipboard-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];
  return (
    <>
      <Helmet>
        <title> {isInformeFacturacion ? TITLES.gestor_de_renovaciones : TITLES.default} </title>
      </Helmet>

      <TableList titulo="Gestión de Renovaciones" columnas={columnas} data={data} loading={loading} />
    </>
  );
}
