/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PATH_NAME, TITLES } from '../constants';

import { CreditosAtrazados } from '../components/tables';
import { startConsultarCreditosVencidos } from '../store';

export function GestorCreditosAtrazados() {
  const location = useLocation();
  const isCreditosAtrazados = location?.pathname.includes(PATH_NAME.gestor_de_creditos_atrazados);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startConsultarCreditosVencidos());
  }, []);

  return (
    <>
      <Helmet>
        <title> {isCreditosAtrazados ? TITLES.gestor_de_creditos_atrazados : TITLES.default} </title>
      </Helmet>

      <CreditosAtrazados />
    </>
  );
}
