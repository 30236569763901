/* eslint-disable react/prop-types */
import { CssBaseline, Grid, Button, Box, Typography, Card } from '@mui/material';
import React from 'react';
import Enviar from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import { editarCredito } from '../../../store/clientUser/credito/thunk';

export const MensajeEstadoCreditoParaAdquirir = ({ titulo, subtitulo, mensaje, monto }) => {
  const dispatch = useDispatch();
  const credito = useSelector((state) => state.creditoCL?.creditoActivo);

  const cancelarSolicitud = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: '¿Estas seguro que deseas abandotar tu solicitud?',
      text: 'Si abandonas esta solicitud,  deberas realizar una nueva para acceder a tu crédito',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, abandonar!',
      cancelButtonText: 'No, cancelar',
    }).then((result) => {
      if (result.value) {
        dispatch(editarCredito(credito.id, { ...credito, adquirido: false, cancelado: true }));
      }
    });
  };

  const aceptar = async () => {
    dispatch(editarCredito(credito.id, { ...credito, adquirido: true }));
  };
  return (
    <Card
      sx={{
        mx: 'auto',
        width: '70%',
      }}
    >
      <Grid container component="main" sx={{ margin: 3 }}>
        <CssBaseline />
        <Grid item xs={12}>
          <Typography
            variant="h2"
            sx={{
              color: '#ffde2e',
              fontFamily: 'Raleway',
              fontSize: '40px',
              marginBottom: 2,
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {titulo}
          </Typography>
          <Typography
            variant="h4"
            sx={{ color: '#0074bc', fontFamily: 'Raleway', fontSize: '30px', textAlign: 'center' }}
          >
            {subtitulo} {monto}
          </Typography>
          <Typography variant="p" sx={{ color: '#0074bc', fontFamily: 'Raleway', textAlign: 'center' }}>
            {mensaje}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', marginY: 10 }}>
        <Button
          variant="contained"
          size="large"
          sx={{ marginRight: '10px !important' }}
          endIcon={<Enviar>Aceptar</Enviar>}
          onClick={aceptar}
        >
          Aceptar
        </Button>

        <Button
          color="error"
          variant="contained"
          size="large"
          sx={{ marginLeft: '10px !important' }}
          startIcon={<DeleteIcon />}
          onClick={cancelarSolicitud}
        >
          Abandonar
        </Button>
      </Box>
    </Card>
  );
};
