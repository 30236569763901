import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import React, { useState } from 'react';
import { formateador } from '../../../helper/Formateador';
import Epayco from './Epayco';
import { calculosDelCredito } from '../../../helper/liquidarCredito';
import { editarCredito } from '../../../store/clientUser/credito/thunk';
import img from './efectivo.jpeg';

const OpcionPagoRenovacion = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);

  const credito = useSelector((state) => state.creditoCL.creditoActivo);
  const usuario = useSelector((state) => state.infoUsuario);

  const { valorAdministracion, valorPlataforma, valorIva, interesCorriente, valorTotalPagoRenovacion } =
    calculosDelCredito(credito);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    // dispatch(cancelarAbonoEpayco())
    setOpen(true);
  };

  const efectuarPago = (e) => {
    e.preventDefault();
    handleClose();

    Swal.fire({
      title: '¿Tu pago fue exitoso?',
      text: 'Recuerda que para realizar esta acción tu pago electronico debio ser exitoso, si tu pago fue en efectivo recuerda enviar el soporte',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Mi pago fue exitoso!',
      cancelButtonText: 'No, Cancelar',
    }).then(() => {
      dispatch(
        editarCredito(credito?.id, {
          ...credito,
          plataforma: valorPlataforma,
          interes_Corriente: interesCorriente,
          administracion: valorAdministracion,
          iva: valorIva,
          solicitud_Renovacion: true,
          valor_Cancelado_Capital: null,
        })
      );
    });
  };

  return (
    <>
      {credito?.fecha_Cancelado !== null && credito?.cancelado === null && (
        <Alert severity="warning">Tu pago esta siendo verificado.</Alert>
      )}
      {credito?.solicitud_Renovacion === true && <Alert severity="warning">Tu pago esta siendo verificado.</Alert>}
      <form onSubmit={efectuarPago}>
        <Grid container>
          <FormControl sx={{ mx: 'auto' }}>
            <Grid container sx={{ display: 'flex' }}>
              <Grid sx={{ margin: 2 }}>
                <Box>
                  <Card sx={{ padding: 3, lineHeight: 'normal', fontSize: 12 }}>
                    <h4>Detalle Pago:</h4>

                    <p>Administración: $ {formateador(valorAdministracion)}</p>
                    <p>Plataforma: $ {formateador(valorPlataforma)}</p>
                    <p>IVA: $ {formateador(valorIva)}</p>
                    <p>Intereses: $ {formateador(interesCorriente)}</p>
                    <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '18px', with: '100%' }}>
                      Total: $ {formateador(valorTotalPagoRenovacion)}
                    </Typography>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </FormControl>
        </Grid>
        <Grid
          container
          sx={{
            display: 'flex',
            marginTop: 3,
            textAlign: 'center',
          }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '150px' }}
              onClick={handleClickOpen}
              disabled={
                (credito?.fecha_Cancelado !== null && credito?.cancelado === null) || credito?.solicitud_Renovacion
              }
            >
              Efectivo
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
            <Epayco
              abono={valorTotalPagoRenovacion}
              cuotaId={`C${credito?.id}`}
              celular={usuario?.celular}
              nombre={`${usuario?.primerNombre} ${usuario?.primerApellido}`}
              direccionResidencia={usuario?.direccion_residencia}
              monto={valorTotalPagoRenovacion}
              descripcion={`Crédito: C${credito?.id}`}
              cedula={`Cedula: ${usuario?.cedula}`}
              inactivo={credito?.fecha_Cancelado !== null && credito?.cancelado === null}
            />
          </Grid>
        </Grid>
      </form>
      <div>
        <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">{'Vas a pagar en Efectivo?'}</DialogTitle>
          <DialogContent>
            <img src={img} alt="Logo Easy Credit" />
            <DialogContentText>
              Solo cuando efectues el pago selecciona la opción,
              <strong>REGISTRAR PAGO</strong>.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
            <Button type="submit" onClick={efectuarPago} color="primary" autoFocus>
              REGISTRAR PAGO
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default OpcionPagoRenovacion;
