import { Card, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import Scrollbar from '../scrollbar/Scrollbar';
import HistoricoCompromiso from './HistoricoCompromiso';
import StepperCompromisos from './StepperCompromisos';

export const ListarCompromiso = () => {
  const { compromisoCreditos } = useSelector((state) => state.creditosActivos.creditoEditable);
  return (
    <Grid item xs={12} md={8} lg={8}>
      <Card sx={{ height: '424px' }}>
        <Scrollbar>
          <StepperCompromisos compromisoCreditos={compromisoCreditos} />
          <HistoricoCompromiso compromisoCreditos={compromisoCreditos} />
        </Scrollbar>
      </Card>
    </Grid>
  );
};
