/* eslint-disable react/prop-types */
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import Swal from 'sweetalert2';

const InformacionReferencias = ({
  registroInfoReferencias,
  setInformacionReferencias,
  setActiveStep,
  activeStep,
  steps,
  enviar,
}) => {
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setInformacion = ({ target }) => {
    setInformacionReferencias({
      ...registroInfoReferencias,
      [target.name]: target.value,
    });
  };
  const submit = (e) => {
    e.preventDefault();
    if (
      // registroInfoReferencias.referencia_familiar_nombres === null ||
      // registroInfoReferencias.referencia_familiar_apellidos === null ||
      // registroInfoReferencias.referencia_familiar_ciudad === null ||
      // registroInfoReferencias.referencia_familiar_telefono === null ||
      // registroInfoReferencias.referencia_familiar_parentesco === null ||
      registroInfoReferencias.referencia_personal_nombres === null ||
      registroInfoReferencias.referencia_personal_apellidos === null ||
      registroInfoReferencias.referencia_personal_ciudad === null ||
      registroInfoReferencias.referencia_personal_telefono === null
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    enviar();
  };
  return (
    <Box component="form">
      {/* <Typography
        variant="h4"
        component="h2"
        sx={{
          marginY: 3,
        }}
      >
        Información Referencias Familiares
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_familiar_nombres"
            value={registroInfoReferencias.referencia_familiar_nombres}
            onChange={setInformacion}
            label="Nombres"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_familiar_apellidos"
            value={registroInfoReferencias.referencia_familiar_apellidos}
            onChange={setInformacion}
            label="Apellidos"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_familiar_ciudad"
            value={registroInfoReferencias.referencia_familiar_ciudad}
            onChange={setInformacion}
            label="Ciudad"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_familiar_telefono"
            value={registroInfoReferencias.referencia_familiar_telefono}
            onChange={setInformacion}
            label="Telefono"
            fullWidth
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="referencia_familiar_parentesco"
            value={registroInfoReferencias.referencia_familiar_parentesco}
            onChange={setInformacion}
            label="Parentesco"
            fullWidth
          />
        </Grid>
      </Grid>
      <Divider light sx={{ mt: 2 }} />
      <br /> */}

      <Typography variant="h4" component="h2" sx={{ marginY: 3 }}>
        Información Referencias Personales
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_personal_nombres"
            value={registroInfoReferencias.referencia_personal_nombres}
            onChange={setInformacion}
            label="Nombres"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_personal_apellidos"
            value={registroInfoReferencias.referencia_personal_apellidos}
            onChange={setInformacion}
            label="Apellidos"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_personal_ciudad"
            value={registroInfoReferencias.referencia_personal_ciudad}
            onChange={setInformacion}
            label="Ciudad"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="referencia_personal_telefono"
            value={registroInfoReferencias.referencia_personal_telefono}
            onChange={setInformacion}
            label="Telefono"
            fullWidth
            type="number"
          />
        </Grid>
      </Grid>
      <Divider light sx={{ mt: 2 }} />

      <Box
        sx={{
          marginTop: 3,
        }}
      >
        {activeStep !== 0 && <Button onClick={handleBack}>Anterior</Button>}
        <Button onClick={submit} variant="contained" color="primary" sx={{ marginLeft: 2 }}>
          {activeStep === steps.length - 1 ? 'Actualizar' : 'Enviar'}
        </Button>
      </Box>
    </Box>
  );
};

export default InformacionReferencias;
