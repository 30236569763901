/* eslint-disable radix */
import moment from 'moment';
import Swal from 'sweetalert2';
import html2pdf from 'html2pdf.js';
import { NIT, NUMERO_RESOLUCION } from '../constants/constans';
import 'moment-timezone';

moment.tz.setDefault('America/Bogota');

export const hoy = moment().format();

export function ordenador(info = []) {
  return [...info].sort((a, b) => parseFloat(b?.id) - parseFloat(a?.id));
}

export function diasMoraFNH(fechaLimitePago) {
  const fecha = moment.tz(fechaLimitePago, 'America/Bogota').startOf('day');
  const nuevaFecha = moment(hoy).startOf('day');
  if (nuevaFecha.diff(fecha, 'days') > 0) {
    return nuevaFecha.diff(fecha, 'days');
  }
  return 0;
}

// export const filtrarByFecha = (creditos, desde, hasta) =>
//   creditos.filter(
//     (credito) => moment(credito?.createdAt).isAfter(moment(desde)) && moment(credito?.createdAt).isBefore(hasta)
//   );
export const filtrarByFecha = (creditos, desde, hasta) => {
  const desdeHoraInicio = moment(desde).startOf('day').tz('America/Bogota');
  const hastaHoraFin = moment(hasta).endOf('day').tz('America/Bogota');

  return creditos.filter(
    (credito) =>
      moment(credito?.createdAt).isAfter(desdeHoraInicio) && moment(credito?.createdAt).isBefore(hastaHoraFin)
  );
};

// export const filtrarByFechaCancelados = (creditos, desde, hasta) =>
//   creditos.filter(
//     (credito) =>
//       moment(credito?.fecha_Cancelado).isAfter(moment(desde)) && moment(credito?.fecha_Cancelado).isBefore(hasta)
//   );
export const filtrarByFechaCancelados = (creditos, desde, hasta) => {
  const desdeHoraInicio = moment(desde).startOf('day').tz('America/Bogota');
  const hastaHoraFin = moment(hasta).endOf('day').tz('America/Bogota');

  return creditos.filter(
    (credito) =>
      credito.cancelado === true &&
      moment(credito?.fecha_Cancelado).tz('America/Bogota').isAfter(desdeHoraInicio) &&
      moment(credito?.fecha_Cancelado).tz('America/Bogota').isBefore(hastaHoraFin)
  );
};

// export const filtrarByFechaDesembolsados = (creditos, desde, hasta) =>
//   creditos.filter(
//     (credito) =>
//       moment(credito?.fecha_Desembolsado).isAfter(moment(desde)) &&
//       moment(credito?.fecha_Desembolsado).isBefore(hasta) &&
//       credito?.desembolsado === true &&
//       credito?.credito_renovado === null
//   );

export const filtrarByFechaDesembolsados = (creditos, desde, hasta) => {
  const desdeHoraInicio = moment(desde).startOf('day').tz('America/Bogota');
  const hastaHoraFin = moment(hasta).endOf('day').tz('America/Bogota');

  return creditos.filter(
    (credito) =>
      moment(credito?.fecha_Desembolsado).isAfter(desdeHoraInicio) &&
      moment(credito?.fecha_Desembolsado).isBefore(hastaHoraFin) &&
      credito?.desembolsado === true &&
      credito?.credito_renovado === null
  );
};

// export const filtrarByFechaRenovados = (creditos, desde, hasta) =>
//   creditos.filter(
//     (credito) =>
//       moment(credito?.fecha_Desembolsado).isAfter(moment(desde)) &&
//       moment(credito?.fecha_Desembolsado).isBefore(hasta) &&
//       credito?.credito_renovado === true
//   );
export const filtrarByFechaRenovados = (creditos, desde, hasta) => {
  const desdeHoraInicio = moment(desde).startOf('day').tz('America/Bogota');
  const hastaHoraFin = moment(hasta).endOf('day').tz('America/Bogota');

  return creditos.filter(
    (credito) =>
      moment(credito?.fecha_Desembolsado).isAfter(desdeHoraInicio) &&
      moment(credito?.fecha_Desembolsado).isBefore(hastaHoraFin) &&
      credito?.credito_renovado === true
  );
};

export const filtroInformeGestion = (creditos, desde, hasta) => {
  const dataFiltrada = filtrarByFecha(creditos, desde, hasta);

  const rechazados = dataFiltrada.filter((credito) => credito?.rechazado === true);
  const adquiridos = dataFiltrada.filter((credito) => credito?.adquirido === true);
  const aprobados = dataFiltrada.filter((credito) => credito?.aprobado === true);
  const preAprobados = dataFiltrada.filter((credito) => credito?.preAprobado === true);
  const cancelados = filtrarByFechaCancelados(creditos, desde, hasta);
  const desembolsados = filtrarByFechaDesembolsados(creditos, desde, hasta);
  const renovados = filtrarByFechaRenovados(creditos, desde, hasta);
  const grafico = [
    {
      label: 'Solicitados',
      value: dataFiltrada.length,
    },
    {
      label: 'Rechazados',
      value: rechazados.length,
    },
    {
      label: 'Pre-Aprobados',
      value: preAprobados.length,
    },
    {
      label: 'Aprobados',
      value: aprobados.length,
    },
    {
      label: 'Adquiridos',
      value: adquiridos.length,
    },
    {
      label: 'Desembolsado',
      value: desembolsados.length,
    },
    {
      label: 'Cancelados',
      value: cancelados.length,
    },
    {
      label: 'Renovados',
      value: renovados.length,
    },
  ];

  return {
    totalSolicitados: dataFiltrada.length,
    totalRechazados: rechazados.length,
    totalDesembolsados: desembolsados.length,
    totalCancelados: cancelados.length,
    grafico,
    rechazadosData: rechazados,
    canceladosData: cancelados,
    solicitadosData: dataFiltrada,
    desembolsadosData: desembolsados,
    renovadosData: renovados,
  };
};

export const validarEstados = (estado) => {
  if (estado.desembolsado === true && estado.aprobado === null && estado.preAprobado === null) {
    Swal.fire({
      title: 'Error!',
      text: 'Aún no esta aprobado ni Pre-aprobado este credito',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    return { cancelar: true };
  }
  if (estado.preAprobado === true && estado.aprobado === null && estado.desembolsado === true) {
    Swal.fire({
      title: 'Error!',
      text: 'Aún no esta aprobado',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    return { cancelar: true };
  }

  if (estado.aprobado === true && estado.preAprobado === null) {
    Swal.fire({
      title: 'Error!',
      text: 'Este Crédito aún no esta Pre-aprobado',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    return { cancelar: true };
  }

  if (estado.cancelado === true && estado.desembolsado === null) {
    Swal.fire({
      title: 'Error!',
      text: 'Este Crédito aún no se ha desembolsado',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    return { cancelar: true };
  }

  if (estado.desembolsado === true && estado.fecha_Firma === null) {
    Swal.fire({
      title: 'Error!',
      text: 'Este Crédito aún no se ha Firmado',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    return { cancelar: true };
  }

  if (estado.aprobado === true && estado.adquirido === null) {
    Swal.fire({
      title: 'Error!',
      text: 'Este Crédito aún no ha sido adquirido por el cliente.',
      icon: 'error',
      confirmButtonText: 'Ok',
    });
    return { cancelar: true };
  }
  return { cancelar: false };
};

export const nuevaFecha = (numeroDias) => moment().add(numeroDias, 'days');

export const sumarDiasAFecha = (fechaInicial, dias) => moment(fechaInicial).add(dias, 'days');

export function generarPDF(titulo, elemento) {
  const opciones = {
    filename: `${titulo}.pdf`,
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    margin: [20, 20, 20, 20],
  };

  html2pdf().set(opciones).from(elemento).save();
}

export const valoresPagados = (credito) =>
  (
    parseInt(credito.valor_Cancelado_Capital || 0) +
    parseInt(credito.interes_Moratorio || 0) +
    parseInt(credito.interes_Corriente || 0) +
    parseInt(credito.plataforma || 0) +
    parseInt(credito.administracion || 0) +
    parseInt(credito.iva || 0) +
    parseInt(credito.cobranza_Inicial || 0) +
    parseInt(credito.cobranza_Segunda || 0)
  ).toFixed(0);
export const filtrarPorFecha = (datos, fechaInicial, fechaFinal) => {
  const fechaInicio = moment(fechaInicial);
  const fechaFin = moment(fechaFinal);

  const datosFiltrados = datos.filter((dato) => {
    const fecha = moment(dato.createdAt);
    return fecha.isBetween(fechaInicio, fechaFin, null, '[]');
  });

  return datosFiltrados;
};

export const clarisa = (credito, ultimafactura, administracion, cobroSistematizacion, cobroInteres) => ({
  nit: NIT,
  numeroResolucion: NUMERO_RESOLUCION,
  numeroDocumentoComercial: `FE${ultimafactura + 1}`,
  fechaGeneracion: moment(hoy).format('YYYY-MM-DD[T]HH:mm:ss'),
  mediosPago: credito?.metodo_Cancelacion,
  fechaVencimiento: moment(hoy).format('YYYY-MM-DD[T]HH:mm:ss'),
  observacion: '',
  formaPago: 1,
  total: Number(administracion) + Number(cobroSistematizacion) + Number(cobroInteres),
  cliente: {
    nombreRazonSocial: `${credito?.cuentaUsuario?.primerNombre} ${credito?.cuentaUsuario?.segundoNombre} ${credito?.cuentaUsuario?.primerApellido} ${credito?.cuentaUsuario?.segundoApellido}`,

    tipoIdentificacion: 'CC',
    numIdentificacion: credito?.cuentaUsuario?.cedula,
    naturaleza: 'NATURALES',
    direccion: credito?.cuentaUsuario?.direccion_residencia,
    ciudad: credito?.cuentaUsuario?.ciudad_residencia,
    telefono: credito?.cuentaUsuario?.celular,
    email: credito?.cuentaUsuario?.usuario?.email,
  },
  codigoDescuento: '',
  porcentajeDescuentoGeneral: 0,
  items: [
    {
      nombreItemVenta: 'Administración',
      precioVentaUnitario: Number(administracion),
      cantidad: 1,
      codigo: '',
      descuentoPorcentaje: 0,
      observacion: '',
      unidad: '94',
      codigoDescuento: '',
      impuesto: 'IVA_19',
    },

    {
      nombreItemVenta: 'Plataforma',
      precioVentaUnitario: Number(cobroSistematizacion),
      cantidad: 1,
      codigo: '',
      descuentoPorcentaje: 0,
      observacion: '',
      unidad: '94',
      codigoDescuento: '',
      impuesto: 'IVA_19',
    },

    {
      nombreItemVenta: 'Intereses',
      precioVentaUnitario: Number(cobroInteres),
      cantidad: 1,
      codigo: '',
      descuentoPorcentaje: 0,
      observacion: '',
      unidad: '94',
      codigoDescuento: '',
      impuesto: 'EXCENTO',
    },
  ],
});
