import { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Link as MuiLink, Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import { startLoginAdmin } from '../../../store/admin/thunk';
import Iconify from '../../iconify';
import { MAIN_PATH, PATH_NAME } from '../../../constants';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: null,
    password: null,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const mensajeAutenticacionClarisaExitosa = ['Autenticado en Clariza!', 'success'];
  const mensajeAutenticacionClarisaFallida = ['No logramos autenticarnos en Clariza!', 'error'];
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    setLoading(true);
    try {
      await dispatch(startLoginAdmin(data.email, data.password));
      navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
      window.history.pushState(null, '', window.location.href);
      // eslint-disable-next-line no-unused-expressions
      localStorage.getItem('tokenClarisa')
        ? enqueueSnackbar(mensajeAutenticacionClarisaExitosa[0], { variant: mensajeAutenticacionClarisaExitosa[1] })
        : enqueueSnackbar(mensajeAutenticacionClarisaFallida[0], { variant: mensajeAutenticacionClarisaFallida[1] });
    } catch (error) {
      Swal.fire('Error', error.toString(), 'error');
    }
    setLoading(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Correo Electronico"
          onChange={({ target }) => setData({ ...data, email: target.value })}
        />

        <TextField
          name="password"
          label="Contraseña"
          type={showPassword ? 'text' : 'password'}
          onChange={({ target }) => setData({ ...data, password: target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton
        loading={loading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleClick}
        sx={{ marginTop: 2 }}
      >
        Ingresar
      </LoadingButton>
    </>
  );
}
