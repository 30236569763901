/* eslint-disable consistent-return */
import { Button, Grid, Stack, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { resetPassword } from '../../store/clientUser/auth';

const ResetForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setpassword] = useState();

  const [confirmPassword, setconfirmPassword] = useState();
  const classes = {};

  const url = useParams();

  const handlePasswordChange = ({ target }) => {
    setpassword(target.value);
  };

  const handleConfirmPasswordChange = ({ target }) => {
    setconfirmPassword(target.value);
  };

  const handelCambioClaveSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return Swal.fire('Error', 'Las contraseñas no coinciden', 'error');
    }
    dispatch(resetPassword(url.token, password, navigate));

    navigate('/easy-auth/login');
  };

  return (
    <Stack
      spacing={2}
      noValidate
      autoComplete="off"
      sx={{
        width: '35ch',
      }}
    >
      <Grid container component="main">
        <form className={classes.form} noValidate onSubmit={handelCambioClaveSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handlePasswordChange}
            value={password}
            name="password"
            label="Contraseña"
            type="password"
            inputProps={{ minLength: 2 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleConfirmPasswordChange}
            name="confirmPassword"
            label="Confirmar contraseña"
            type="password"
            value={confirmPassword}
          />
          <br />
          <br />

          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Actualizar Contraseña
          </Button>
        </form>
      </Grid>
    </Stack>
  );
};

export default ResetForm;
