/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable vars-on-top */
import { Button } from '@mui/material';
import React from 'react';

const Epayco = ({ celular, inactivo, descripcion, monto, nombre, direccionResidencia, cuotaId, abono, cedula }) => {
  const openchekout = (e) => {
    e.preventDefault();

    const handler = window.ePayco.checkout.configure({
      key: 'eedf5f7c8a6ddab89492efbb6fd54ae0',
      test: false,
    });

    const data = {
      name: 'Pago crédito Libre Inversion',
      description: descripcion,
      currency: 'cop',
      amount: monto,
      country: 'co',
      lang: 'en',
      external: 'true',
      extra1: cedula,
      acepted: 'http://www.creditosegurocolombia/pago-aceptado',
      rejected: 'http://www.creditosegurocolombia/pago-rechazado',
      name_billing: nombre,
      address_billing: direccionResidencia,
      type_doc_billing: 'cc',
      mobilephone_billing: celular,
    };

    handler.open(data);
    // dispatch(valorParaAbonar({ abono, cuotaId }));
  };

  return (
    <Button disabled={inactivo}>
      <input
        style={{
          cursor: 'pointer',
          marginTop: '-7px',
          backgroundRepeat: 'no-repeat',
          height: '37px',
          width: '145px',
          backgroundPosition: 'center',
          borderColor: 'transparent',
          color: 'transparent',
          backgroundImage:
            'url(https://369969691f476073508a-60bf0867add971908d4f26a64519c2aa.ssl.cf5.rackcdn.com/btns/epayco/boton_de_cobro_epayco2.png)',
        }}
        type="submit"
        placeholder="pagar"
        onClick={openchekout}
      />
    </Button>
  );
};

export default Epayco;
