/* eslint-disable react/prop-types */
import React from 'react';
import './Sello.css';

const Sello = ({ clase, mensaje }) => (
  <div>
    <span className={clase}>{mensaje}</span>
  </div>
);

export default Sello;
