import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const PasosResponsive = () => (
  <Card>
    <CardContent className="root2">
      <Grid item xs={12}>
        <div className="cajapasos" id="pasos">
          <h1>8 Pasos para adquirir tu crédito</h1>
        </div>
        <br />
        <br />

        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          1. Calcular Crédito
        </Typography>

        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          2. Solicitar Crédito
        </Typography>
        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          3. Solicitud de Datos
        </Typography>

        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          4. Validación de Datos
        </Typography>

        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          5. Resultado de Solicitud
        </Typography>
        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          6. Firma de Contrato
        </Typography>
        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          7. Desembolso
        </Typography>
        <Typography className="titulo-8pasospararoot" component="h2">
          <AssignmentTurnedInIcon className="iconoamarillo" fontSize="large" />
          8. Pago
        </Typography>
      </Grid>
    </CardContent>
  </Card>
);

export default PasosResponsive;
