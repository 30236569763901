import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

const PrettoSlider = styled(Slider)({
  color: '#ffde2e',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 50,
    width: 50,
    // backgroundColor: '#fff',
    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    marginTop: 0,
    marginLeft: 0,

    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      '@media (hover: none)': {
        boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
      },
    },
  },
  '& .MuiSlider-valueLabel': {
    left: 'calc(10% + -9px)',
    margin: 10,
    '& *': {
      background: 'white',
      color: 'black',
    },
  },

  '& 	.MuiSlider-valueLabelCircle': {
    background: 'currentColor',
  },

  '& 	.MuiSlider-valueLabelOpen': {
    display: 'none',
  },

  '& 	.MuiSlider-track': {
    height: 20,
    borderRadius: 50,
  },
  '& .MuiSlider-rail': {
    width: '100%',
    height: 20,
    borderRadius: 50,
    padding: 0,
  },
  '&	.MuiSlider-mark': {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  '& .MuiSlider-markActive': {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
});

export default PrettoSlider;
