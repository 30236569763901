import Swal from 'sweetalert2';
import clienteAxios from '../../../helper/axios';
import { censado } from '../auth';
import { cargarInformacionCuenta } from './cuentaSlice';

export const crearInformacionUsuario = (data) => async (dispatch) => {
  const id = localStorage.getItem('id');
  const info = data;
  data.usuarioId = id;
  try {
    await clienteAxios.post(
      `cuenta-usuario/crear/${id}`,
      {
        info,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    dispatch(cargarInformacionCuenta(data));
    dispatch(censado());
  } catch (error) {
    console.error(error);
  }
};

export const consultarUsuario = () => async (dispatch) => {
  const id = localStorage.getItem('id');
  try {
    const respuesta = await clienteAxios.get(
      `cuenta-usuario/cuenta/${id}`,

      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    dispatch(cargarInformacionCuenta(respuesta.data));
  } catch (error) {
    console.error(error);
  }
};

export const actualizarUsuario = (data) => async (dispatch) => {
  const id = localStorage.getItem('id');
  try {
    await clienteAxios.put(
      `cuenta-usuario/cuenta/${id}`,
      { data },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    dispatch(cargarInformacionCuenta(data));
    Swal.fire('', 'Actualización exitosa', 'success');
  } catch (error) {
    console.error(error);
  }
};
