/* eslint-disable no-plusplus */
import moment from 'moment/moment';
import React from 'react';
import { useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';
import { AppWebsiteVisits } from '../../sections/@dashboard/app';

export const GraficoAnual = () => {
  const { creditos } = useSelector((state) => state.historicos);

  const fechaActual = moment();
  const creditosFiltradoPorAño = creditos?.filter(
    (objeto) => moment(objeto.fecha_Solicitado).year() === fechaActual.year()
  );

  const creditosAgrupadosPorMes = groupBy(creditosFiltradoPorAño, (objeto) =>
    moment(objeto.createdAt).format('MM-YYYY')
  );

  const fechasConvertidas = Object.keys(creditosAgrupadosPorMes).map((fecha) =>
    moment(`01-${fecha}`, 'DD-MM-YYYY').format('DD/MM/YYYY')
  );

  const mesesOrdenados = fechasConvertidas.sort((a, b) => moment(a, 'MM-YYYY') - moment(b, 'MM-YYYY'));

  const fechasFormateadas = mesesOrdenados.map((fecha) => {
    // Crear objeto Moment a partir de la fecha en formato 'DD/MM/YYYY'
    const momentFecha = moment(fecha, 'DD/MM/YYYY');

    // Formatear la fecha en formato 'MM/DD/YYYY'
    const fechaFormateada = momentFecha.format('MM/DD/YYYY');

    return fechaFormateada;
  });

  const result = Object.entries(creditosAgrupadosPorMes).map(([month, items]) => {
    const today = moment();
    const cancelados = items.reduce((acc, curr) => {
      if (curr.cancelado) {
        acc++;
      }
      return acc;
    }, 0);
    const desembolsados = items.reduce((acc, curr) => {
      if (!curr.cancelado && curr.desembolsado) {
        acc++;
      }
      return acc;
    }, 0);
    const vencidos = items.reduce((acc, curr) => {
      if (moment(curr.fecha_Limite_Pago).isBefore(today) && curr.cancelado === null) {
        acc++;
      }
      return acc;
    }, 0);
    return {
      month,
      cancelados,
      desembolsados,
      vencidos,
    };
  });

  result.sort((a, b) => {
    const dateA = new Date(`01-${a.month}`);
    const dateB = new Date(`01-${b.month}`);
    return dateA - dateB;
  });

  return (
    <AppWebsiteVisits
      title="Créditos efectuados"
      subheader="Del primero de Enero al 1 del mes actual."
      chartLabels={fechasFormateadas}
      chartData={[
        {
          name: 'Creditos Desembolsados',
          type: 'column',
          fill: 'solid',
          data: result.map((item) => item.desembolsados),
        },
        {
          name: 'Créditos Vencidos',
          type: 'area',
          fill: 'gradient',
          data: result.map((item) => item.vencidos),
        },
        {
          name: 'Créditos Cancelados',
          type: 'line',
          fill: 'solid',
          data: result.map((item) => item.cancelados),
        },
      ]}
    />
  );
};
