/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { startCerrarSesion } from '../../../store/clientUser/auth';

const timeout = 180000;
export const ClientRoute = ({ children }) => {
  const dispatch = useDispatch();
  const handleOnIdle = () => dispatch(startCerrarSesion());
  const { isIdle } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const { logueado } = useSelector((state) => state?.usuarioCL);
  return logueado ? children : <Navigate to="/" />;
};
