/* eslint-disable radix */
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { calculosDelCredito } from '../../helper/liquidarCredito';
import { formateador } from '../../helper/Formateador';
import Scrollbar from '../scrollbar/Scrollbar';
import { nuevaFecha } from '../../helper/funtions';

export const Renovacion = () => {
  const { creditoEditable } = useSelector((state) => state.creditosActivos);


  const { montoAprobando, interesCorriente, valorPlataforma, valorAdministracion, valorIva } =
    calculosDelCredito(creditoEditable);

  return (
    <Grid container spacing={3} sx={{ margin: '0 auto', width: '90%' }}>
      <Grid item xs={12} md={12} lg={12} sx={{ margin: '0 auto' }}>
        <Card sx={{ padding: 3 }}>
          <Scrollbar>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
                Renovación de Crédito:
              </Typography>
              <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                C{creditoEditable?.id}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Nombre:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  {`${creditoEditable?.cuentaUsuario?.primerNombre} ${creditoEditable?.cuentaUsuario?.primerApellido}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Plazo del crédito:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  {`${creditoEditable?.dias_Prestamo} ${creditoEditable?.dias_Prestamo > 1 ? 'días' : 'día'}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Monto Prestamo:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  $ {formateador(creditoEditable?.valor_Aprobado)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Cedula:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  {`${formateador(creditoEditable?.cuentaUsuario?.cedula)}`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Fecha limine del credito Inicial:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  {moment(creditoEditable?.fecha_Limite_Pago).format('ll')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Nuevo Plazo:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  30 días
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Fecha Desembolso:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  {moment(creditoEditable?.fecha_Desembolsado).format('ll')}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                  Nueva fecha limine del credito:
                </Typography>
                <Typography variant="p" gutterBottom sx={{ margin: 2 }}>
                  {moment(nuevaFecha(30)).format('ll')}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
              Detalle del Pago:
            </Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper', display: 'flex' }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <MonetizationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Administracion" secondary={`$ ${formateador(valorAdministracion)}`} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <MonetizationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Plataforma" secondary={`$ ${formateador(valorPlataforma)}`} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <MonetizationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="IVA" secondary={`$ ${formateador(valorIva)}`} />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <MonetizationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Interes" secondary={`$ ${formateador(interesCorriente)}`} />
              </ListItem>
              <ListItem sx={{ backgroundColor: '#CCCCCC', borderRadius: 3 }}>
                <ListItemAvatar>
                  <Avatar>
                    <MonetizationOnIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Credito" secondary={`$ ${formateador(montoAprobando)}`} />
              </ListItem>
            </List>
            <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
              Total a Pagar por Renovación: $
              {formateador(
                parseInt(interesCorriente) +
                  parseInt(valorPlataforma) +
                  parseInt(valorAdministracion) +
                  parseInt(valorIva)
              )}
            </Typography>
          </Scrollbar>
          <Button variant="contained" fullWidth>
            Renovar
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};
