import { Chip, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Iconify from '../iconify/Iconify';
import TableList from '../table-list/TableList';
import { coleccionCreditosVencidos } from '../../helper/coleccionCreditos';
import { cargarCreditoAEditar } from '../../store';
import { MAIN_PATH, PATH_NAME } from '../../constants';
import 'moment-timezone';

export const CreditosAtrazados = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vencidos, loading } = useSelector((state) => state?.creditosActivos?.creditosVencidos);

  const data = coleccionCreditosVencidos(vencidos);

  const redireccionarEdicion = (idCredito) => {
    const credito = vencidos.filter((credito) => credito.id === idCredito)[0];
    dispatch(cargarCreditoAEditar(credito));
    navigate(`/admin/${MAIN_PATH}/${PATH_NAME.gestionar_credito_vencido}`, { replace: true });
  };

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Aprobado',
      name: 'valorSolicitado',
      options: {
        filter: true,
      },
    },
    {
      label: 'Fecha Limite de Pago',
      name: 'limitePago',
      options: {
        filter: true,
      },
    },
    {
      label: 'Días de Mora',
      name: 'diasMora',
      options: {
        filter: false,
      },
    },
    {
      label: 'Estado',
      name: 'compromiso',
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Chip label={value ? 'Con Compromiso' : 'Sin Compromiso'} color={value ? 'warning' : 'error'} />
        ),
      },
    },
    {
      name: 'Gestionar',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            <Tooltip title="Gestionar" aria-label="Gestionar">
              <IconButton aria-label="Gestionar" onClick={() => redireccionarEdicion(tableMeta.rowData[2])}>
                <Iconify icon="material-symbols:edit-square-outline" />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
    {
      name: ' ',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Tooltip title="Copiar y Pegar">
            <IconButton
              aria-label="Gestionar"
              onClick={() => navigator.clipboard.writeText(tableMeta.rowData[1].replaceAll('.', ''))}
            >
              <Iconify icon="mdi-light:clipboard-text" />
            </IconButton>
          </Tooltip>
        ),
      },
    },
  ];

  return <TableList titulo="Lista de Créditos Atrasados:" columnas={columnas} loading={loading} data={data} />;
};
