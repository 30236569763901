// theme
import { useEffect } from 'react';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { AppRouter } from './routes/AppRouter';
import 'moment/min/locales';
import 'moment-timezone';

// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    moment.locale('es');
  }, []);
  useEffect(() => {
    moment.tz.setDefault('America/Bogota');
  }, []);
  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={3}>
        <ScrollToTop />
        <StyledChart />
        <AppRouter />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
