/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';

import { Box, CardActionArea, Grid } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import sesentaDias from '../assets/images/sesenta.jpeg';
import treintaDias from '../assets/images/treinta.jpeg';

export default function Ejemplos() {
  return (
    <Box sx={{ width: '100%', backgroundColor: 'white' }}>
      <div className="cajapasos" id="ejemplos">
        <h1>¡Ejemplo de tu crédito a 60 días y 90 días!</h1>
      </div>
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'space-around',
        }}
      >
        <Grid item xs={12} sm={5} sx={{ margin: 5, display: 'flex', justifyContent: 'center' }}>
          <Card
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: '300px',
              '@media screen and (min-width: 1200px)': {
                maxWidth: '50%',
              },
            }}
          >
            <CardActionArea>
              <CardMedia component="img" height="auto" image={treintaDias} alt="Ejemplo a 30 días" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
                  Tu crédito a 60 días
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={5} sx={{ margin: 5, display: 'flex', justifyContent: 'center' }}>
          <Card
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: '300px',
              '@media screen and (min-width: 1200px)': {
                maxWidth: '50%',
              },
            }}
          >
            <CardActionArea>
              <CardMedia component="img" height="auto" image={sesentaDias} alt="Ejemplo a 60 días" />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center' }}>
                  Tu crédito a 90 días
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
