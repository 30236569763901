/* eslint-disable react/jsx-no-bind */
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as MuiLink, Container, Typography, IconButton } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

// hooks
import useResponsive from '../hooks/useResponsive';

// sections
import { RegisterForm } from '../sections/auth/register';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  return (
    <>
      <Helmet>
        <title> Registro | Easy Credit </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hola, bienvenido
            </Typography>
            <img src="/assets/illustrations/modelo.png" alt="login" />
          </StyledSection>
        )}

        <IconButton
          onClick={handleClick}
          aria-label="delete"
          sx={{
            position: 'absolute',
            width: 60,
            height: 60,
            right: 40,
          }}
        >
          <KeyboardReturnIcon />
          Atrás
        </IconButton>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Easy Credit Colombia
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Ya tienes cuenta? {''}
              <MuiLink variant="subtitle2" underline="hover" component={RouterLink} to="/easy-auth/login">
                Entrar
              </MuiLink>
            </Typography>

            <RegisterForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
