import React from 'react';
import { useSelector } from 'react-redux';
import InformeCrediticio from './InformeBienestar';
import { Reporte } from '../../client/components/bienestarFinanciero/Reporte';

export const PageClient = () => {
  const { informeElaborado } = useSelector((state) => state.informeFinancieroCL);
  const { primerNombre } = useSelector((state) => state.cuentaCL.cuenta);
  const isDone = informeElaborado?.done === true;
  const data = { ...informeElaborado, cuentaUsuario: { primerNombre } };

  return isDone ? <InformeCrediticio informeAImprimir={data} /> : <Reporte />;
};
