import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useDispatch } from 'react-redux';

import ReCAPTCHA from 'react-google-recaptcha';
import Iconify from '../../../components/iconify';
import { startRegister } from '../../../store/clientUser/auth';
import { MAIN_PATH_CLIENTE, PATH_NAME_CLIENTE } from '../../../constants';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const captcha = useRef(null);

  const [reCaptcha, setReCaptcha] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [data, setData] = useState({
    email: null,
    password: null,
    email2: null,
    password2: null,
  });
  const onChange = () => {
    if (captcha.current.getValue()) {
      setReCaptcha(true);
    }
  };

  const handleSubmit = async () => {
    if (data.email !== data.data2 && data.password !== data.password2) {
      Swal.fire({
        title: 'Error!',
        text: 'Los datos deben ser iguales',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }

    try {
      await dispatch(startRegister(data.email, data.password));
      navigate(`/cliente/${MAIN_PATH_CLIENTE}/${PATH_NAME_CLIENTE.home_cliente}`);
    } catch (error) {
      Swal.fire('Error', error.toString(), 'error');
    }
  };

  const handelForm = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Stack spacing={3} component="form" onSubmit={handleSubmit}>
        <TextField name="email" label="Correo Electronico" onChange={handelForm} />
        <TextField name="email2" label="Confirmar Correo Electronico" onChange={handelForm} />

        <TextField
          onChange={handelForm}
          label="contraseña"
          name="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          onChange={handelForm}
          label="Confirmar contraseña"
          name="password2"
          type={showPassword2 ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword2(!showPassword2)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Box sx={{ marginX: 3, marginBottom: 3 }}>
          <ReCAPTCHA ref={captcha} sitekey="6LdxxYAhAAAAAIzewzzKzMci7TVjdq4sQwVcUvOo" onChange={onChange} />
        </Box>
      </Stack>

      <LoadingButton
        disabled={!reCaptcha}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit}
        sx={{ marginTop: 2 }}
      >
        Registrarme
      </LoadingButton>
    </>
  );
}
