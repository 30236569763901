/* eslint-disable react/prop-types */
import { faker } from '@faker-js/faker';
import { Card, Divider, Grid, Typography, Box, Button } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { MAIN_PATH, PATH_NAME } from '../../constants';
import { formateador } from '../../helper/Formateador';
import { hoy, sumarDiasAFecha } from '../../helper/funtions';
import { AppOrderTimeline } from '../../sections/@dashboard/app';
import { renovarCredito, startActualizarCreditos } from '../../store';

export const SolicitudRenovacion = ({ creditoEditable }) => {
  const {
    plataforma: valorPlataforma,
    administracion: valorAdministracion,
    iva: valorIva,
    interes_Corriente: interesCorriente,
  } = creditoEditable;
  const valorTotalPagoRenovacion = valorPlataforma + valorAdministracion + valorIva + interesCorriente;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rechazarRenovacion = () => {
    Swal.fire({
      title: '¿Esta seguro de rechazar esta renovación?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Rechazar la solicitud!',
      cancelButtonText: 'No, Cancelar',
    }).then((result) => {
      if (result.value) {
        dispatch(
          startActualizarCreditos({
            ...creditoEditable,
            fecha_Cancelado: null,
            plataforma: null,
            interes_Corriente: null,
            administracion: null,
            iva: null,
            solicitud_Renovacion: null,
          })
        );
        navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
      }
    });
  };

  const aprobarRenovacion = () => {
    Swal.fire({
      title: '¿Esta seguro de renovar este crédito?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No, Cancelar',
    }).then((result) => {
      if (result.value) {
        dispatch(
          renovarCredito({
            credito_renovado: true,
            administracion_renovado: valorAdministracion,
            administracion: null,
            creditoId: creditoEditable.id,
            dias_Prestamo_renovado: creditoEditable.dias_Prestamo,
            dias_Prestamo: 30,
            fecha_Cancelado: null,
            fecha_Desembolsado_renovado: creditoEditable.fecha_Desembolsado,
            fecha_Desembolsado: hoy,
            fecha_Limite_Pago: sumarDiasAFecha(hoy, 30),
            id: creditoEditable.id,
            interes_Corriente_renovado: interesCorriente,
            interes_Corriente: null,
            iva_renovado: valorIva,
            iva: null,
            paraRenovar: true,
            plataforma_renovado: valorPlataforma,
            plataforma: null,
            solicitud_Renovacion: null,
            valor_Cancelado_Capital: null,
            cuentaUsuario: {
              usuarioId: creditoEditable.cuentaUsuario.id,
            },
          })
        );
        navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
      }
    });
  };

  return (
    <>
      <Grid item xs={12} md={8} lg={8}>
        <Card sx={{ padding: 3 }}>
          <Typography variant="h4">Solicitud Renovación</Typography>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="p">
            Nueva Fecha Limite de Pago: {` ${moment(sumarDiasAFecha(hoy, 30)).format('l')}`}
          </Typography>
          <Grid sx={{ margin: 2, display: 'flex', justifyContent: 'space-around' }}>
            <Box>
              <Card sx={{ padding: 3, lineHeight: 'normal', fontSize: 12 }}>
                <h4>Detalle Pago:</h4>

                <p>- Administración: $ {formateador(valorAdministracion)}</p>
                <p>- Plataforma: $ {formateador(valorPlataforma)}</p>
                <p>- IVA: $ {formateador(valorIva)}</p>
                <p>- Intereses: $ {formateador(interesCorriente)}</p>
                <Typography variant="p" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  Total: $ {formateador(valorTotalPagoRenovacion)}
                </Typography>
              </Card>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Button onClick={aprobarRenovacion} size="large" color="success" sx={{ marginY: 2 }} variant="contained">
                Renovar Crédito
              </Button>
              <Button onClick={rechazarRenovacion} size="large" color="error" sx={{ marginY: 2 }} variant="contained">
                Rechazar Renovación
              </Button>
            </Box>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <AppOrderTimeline
          title="Linea de tiempo del Crédito"
          subheader={'Renovaciones'}
          list={[...Array(creditoEditable.renovacions.length)].map((_, index) => ({
            id: faker.datatype.uuid(),
            title: creditoEditable.renovacions.map(
              (renovacion) => `Aprobado el ${moment(renovacion?.updatedAtcreatedAt).format('l')}`
            )[index],
            type: `order${index + 1}`,
            time: faker.date.past(),
            aprobado: creditoEditable.renovacions.map(
              (renovacion) => `Solicitado el ${moment(renovacion?.createdAt).format('l')}`
            )[index],
          }))}
        />
      </Grid>
    </>
  );
};
