export const bloqueadeoMensaje = {
  subtitulo: 'Tu cuenta se encuentra bloqueada por 60 dias debido al crédito rechazado que tienes.',
};

export const preAprobadoConDocumentosMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: 'Tienes un Pre aprobado por $:',
  mensaje: `Estamos esperando el envio de los documentos solicitados.
    Una vez validados los documentos te enviaremos las firmas a tu correo electronico y tu celular`,
};

export const preAprobadoSinDocumentosMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: 'Tienes un Pre aprobado por $:',
};

export const porDesembolsaroMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: `Queremos informarte que tu desembolso está en proceso.

Estamos trabajando diligentemente para completar este proceso lo más rápido posible y asegurarnos de que recibas los fondos en el menor tiempo posible.

Te agradecemos tu paciencia y comprensión durante este período. Si tienes alguna pregunta o necesitas más información sobre el estado de tu desembolso, no dudes en contactarnos.

¡Gracias por confiar en nosotros!`,
};

export const aprobadoMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: `Muy pronto, el equipo de Easy Credit enviará las firmas a tu correo electrónico y celular a través de mensajes de texto. Te recomendamos revisar tu bandeja de entrada, incluida la carpeta de spam, para asegurarte de recibir correctamente estos mensajes.
Recuerda que la recepción y firma de estos documentos son pasos importantes en el proceso, por lo que te agradecemos tu pronta atención a este asunto.
Si necesitas asistencia adicional o tienes alguna pregunta, no dudes en ponerte en contacto con nuestro equipo de atención al cliente.

¡Gracias por elegir Easy Credit!`,
};

export const enEstudioMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: `Tu crédito se encuentra en estudio.`,
};

export const firmaContratoMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: `Estas a pocos pasos:`,
  mensaje: `Puedes ir a la opción CONTRATO y firmar con los códigos enviados.`,
};

export const preAprobadoMensaje = {
  titulo: '¡ H O L A !',
  subtitulo: `Tu crédito se encuentra en proceso de desembolso:`,
  mensaje: `Recuerda que si la firma del contrato se realizó después de las 15:00,
  tú dinero se verá reflejado al seguiente día.`,
};
