/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable default-case */
import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import shortid from 'shortid';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { startHistoricoClienteEditable } from '../store/historicos';
import { formateador } from '../helper/Formateador';
import Calificacion from '../components/calificacion/Calificacion';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { PATH_NAME, TITLES } from '../constants';
import { bancos } from '../helper/bancos';
import { NumericFormatCustom } from '../components/numericForm/NumericForm';

const fecha = moment().format();

export const GestionarCreditoHistorico = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isGestorCreditos = location?.pathname.includes(PATH_NAME.gestionar_credito);
  const { creditoEditable } = useSelector((state) => state.creditosActivos);
  const [, setDesembolsar] = React.useState(false);
  const [loading] = React.useState(false);
  const [estado, setEstado] = React.useState(creditoEditable);

  const handleChangeDias = (event) => {
    setEstado({
      ...estado,
      dias_Prestamo: event.target.value,
    });
  };

  const handleChange = (event) => {
    switch (event.target.name) {
      case 'preAprobado':
        setEstado({
          ...estado,
          fecha_Preaprobado: event.target.checked ? fecha : null,
          [event.target.name]: event.target.checked || null,
          paraPreaprobar: event.target.checked || null,
        });
        break;
      case 'rechazado':
        setEstado({
          ...estado,
          fecha_Rechazado: event.target.checked ? fecha : null,
          [event.target.name]: event.target.checked || null,
          paraRechazar: event.target.checked || null,
        });
        break;
      case 'aprobado':
        setEstado({
          ...estado,
          firma: event.target.checked || null,
          firma_Larga: event.target.checked ? uuid().slice(0, 15) : null,
          firma_Corta: event.target.checked ? shortid.generate() : null,
          fecha_Aprobado: event.target.checked ? fecha : null,
          [event.target.name]: event.target.checked || null,
          paraAprobar: event.target.checked || null,
        });
        break;
      case 'solicitar_Documentos':
        setEstado({
          ...estado,
          solicitar_Documentos: event.target.checked || null,
        });
        break;
      case 'desembolsado':
        setDesembolsar(event.target.checked || null);
        setEstado({
          ...estado,
          fecha_Desembolsado: event.target.checked ? fecha : null,
          fecha_Limite_Pago: event.target.checked ? moment(fecha).add(estado.dias_Prestamo, 'd') : null,
          [event.target.name]: event.target.checked || null,
          paraDesembolsar: event.target.checked || null,
        });
        break;
    }
  };

  useEffect(() => {
    dispatch(startHistoricoClienteEditable(creditoEditable.cuentaUsuarioId));
  }, []);

  return (
    <>
      <Helmet>
        <title> {isGestorCreditos ? TITLES.gestionar_credito : TITLES.default} </title>
      </Helmet>
      {loading === true ? (
        <Grid>
          <Grid display="flex" justifyContent="center" alignItems="end">
            <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box sx={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <form>
                  <Card>
                    {/* height: 432 */}
                    <Box
                      sx={{
                        alignItems: 'baseline',
                        height:
                          creditoEditable?.primer_cobro === null
                            ? 432
                            : creditoEditable?.tercer_cobro
                            ? 550
                            : creditoEditable?.segundo_cobro
                            ? 510
                            : 472,
                        margin: '0 auto',
                        maxWidth: 300,
                      }}
                    >
                      <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
                        Acciones
                      </Typography>

                      <FormControl fullWidth sx={{ margin: 1 }} variant="standard">
                        <InputLabel id="dias_solicitados">Dias Solicitados</InputLabel>
                        <Select
                          labelId="dias_solicitados"
                          value={estado?.dias_Prestamo}
                          label="Dias que se aprobarán"
                          disabled={creditoEditable?.preAprobado}
                          onChange={handleChangeDias}
                        >
                          <MenuItem value={1}>1 Día</MenuItem>
                          <MenuItem value={2}>2 Dias</MenuItem>
                          <MenuItem value={3}>3 Dias</MenuItem>
                          <MenuItem value={4}>4 Dias</MenuItem>
                          <MenuItem value={5}>5 Dias</MenuItem>
                          <MenuItem value={6}>6 Dias</MenuItem>
                          <MenuItem value={7}>7 Dias</MenuItem>
                          <MenuItem value={8}>8 Dias</MenuItem>
                          <MenuItem value={9}>9 Dias</MenuItem>
                          <MenuItem value={10}>10 Dias</MenuItem>
                          <MenuItem value={11}>11 Dias</MenuItem>
                          <MenuItem value={12}>12 Dias</MenuItem>
                          <MenuItem value={13}>13 Dias</MenuItem>
                          <MenuItem value={14}>14 Dias</MenuItem>
                          <MenuItem value={15}>15 Dias</MenuItem>
                          <MenuItem value={16}>16 Dias</MenuItem>
                          <MenuItem value={17}>17 Dias</MenuItem>
                          <MenuItem value={18}>18 Dias</MenuItem>
                          <MenuItem value={19}>19 Dias</MenuItem>
                          <MenuItem value={20}>20 Dias</MenuItem>
                          <MenuItem value={21}>21 Dias</MenuItem>
                          <MenuItem value={22}>22 Dias</MenuItem>
                          <MenuItem value={23}>23 Dias</MenuItem>
                          <MenuItem value={24}>24 Dias</MenuItem>
                          <MenuItem value={25}>25 Dias</MenuItem>
                          <MenuItem value={26}>26 Dias</MenuItem>
                          <MenuItem value={27}>27 Dias</MenuItem>
                          <MenuItem value={28}>28 Dias</MenuItem>
                          <MenuItem value={29}>29 Dias</MenuItem>
                          <MenuItem value={30}>30 Dias</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        fullWidth
                        disabled={creditoEditable?.preAprobado}
                        sx={{ margin: 1 }}
                        id="outlined-basic"
                        label="Monto para Aprobar"
                        variant="standard"
                        value={estado?.valor_Aprobado}
                        InputProps={{
                          inputComponent: NumericFormatCustom,
                        }}
                        onChange={(event) => {
                          setEstado({
                            ...estado,
                            valor_Aprobado: event.target.value,
                          });
                        }}
                      />

                      <FormGroup row sx={{ display: 'inline-block', textAlign: 'initial' }}>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          disabled
                          label="Solicitado"
                          name="solicitudCredito"
                        />
                        <FormControlLabel
                          label="Pre-Aprobado"
                          control={
                            <Checkbox
                              name="preAprobado"
                              checked={estado?.preAprobado}
                              disabled={creditoEditable?.preAprobado}
                              onChange={handleChange}
                              value={estado?.preAprobado || ''}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Aprobado"
                          control={
                            <Checkbox
                              onChange={handleChange}
                              name="aprobado"
                              checked={estado?.aprobado}
                              value={estado?.aprobado || ''}
                              disabled={creditoEditable?.aprobado}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Desembolsado"
                          control={
                            <Checkbox
                              onChange={handleChange}
                              name="desembolsado"
                              checked={estado?.desembolsado}
                              value={estado?.desembolsado || ''}
                              disabled={creditoEditable?.desembolsado}
                            />
                          }
                        />
                        <FormControlLabel
                          label="Rechazado"
                          control={
                            <Checkbox
                              onChange={handleChange}
                              name="rechazado"
                              checked={estado?.rechazado}
                              value={estado?.rechazado || ''}
                              disabled={creditoEditable?.rechazado || creditoEditable?.aprobado}
                            />
                          }
                        />
                      </FormGroup>
                      <FormControl sx={{ textAlign: 'initial', display: 'block' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <FormLabel id="demo-row-radio-buttons-group-label">Soliciar Documentos?</FormLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="solicitar_Documentos"
                                onChange={handleChange}
                                value={estado?.solicitar_Documentos || ''}
                                checked={estado?.solicitar_Documentos}
                              />
                            }
                            label="Sí"
                            disabled={creditoEditable?.preAprobado}
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </Card>
                </form>
              </Grid>
              <Grid item xs={12} md={8} lg={8}>
                <Card>
                  <Scrollbar>
                    <Box sx={{ width: '100%', maxWidth: 600, margin: '30px auto' }}>
                      <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                          <Typography variant="h5" gutterBottom>
                            Credito Solicitado por :
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            {` ${creditoEditable?.cuentaUsuario?.primerNombre} ${creditoEditable?.cuentaUsuario?.primerApellido}`}
                          </Typography>
                        </Box>

                        <Calificacion calificacion={creditoEditable?.calificacion} />
                      </Box>
                      <Divider sx={{ marginBottom: 6, marginTop: 2 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Box sx={{ width: '50%' }}>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Nombre Completo :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {` ${creditoEditable?.cuentaUsuario?.primerNombre} ${creditoEditable?.cuentaUsuario?.primerApellido}`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Codigo Credito :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {`E-${creditoEditable?.id}`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Dias del Crédito :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {`${creditoEditable?.dias_Prestamo} ${
                                creditoEditable?.dias_Prestamo > 1 ? 'Días' : 'Día'
                              }`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Monto Solicitado :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {`$ ${formateador(creditoEditable?.valor_Solicitado)}`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Firmo Contrato :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Firma === null ? 'No' : 'Sí'}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Pre-Aprobado :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Aprobado === null
                                ? 'Pendiente Aprobacíon'
                                : moment(creditoEditable?.fecha_Aprobado).format('l')}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Banco:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {
                                bancos.filter((banco) => banco.value === creditoEditable?.cuentaUsuario?.banco)[0]
                                  ?.nombre
                              }
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Desembolso:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Desembolsado === null
                                ? 'Pendiente Aprobacíon'
                                : moment(creditoEditable?.fecha_Desembolsado).format('l')}
                            </Typography>
                          </Box>

                          {creditoEditable?.primer_cobro && (
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                              <FormControlLabel
                                disabled
                                control={<Checkbox defaultChecked />}
                                label="Envio mensaje Pre-cobro"
                              />
                            </Box>
                          )}

                          {creditoEditable?.segundo_cobro && (
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                              <FormControlLabel
                                disabled
                                control={<Checkbox defaultChecked />}
                                label="Envio mensaje Cobro"
                              />
                            </Box>
                          )}

                          {creditoEditable?.tercer_cobro && (
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                              <FormControlLabel
                                disabled
                                control={<Checkbox defaultChecked />}
                                label="Envio mensaje Cobro por mora"
                              />
                            </Box>
                          )}
                        </Box>

                        <Box sx={{ width: '50%' }}>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Cedula :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {formateador(creditoEditable?.cuentaUsuario?.cedula)}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Solicitud Credito :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {moment(creditoEditable?.fecha_Solicitado).format('l')}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Limite de Pago :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Limite_Pago === null
                                ? 'Pendiente Desembolso'
                                : moment(creditoEditable?.fecha_Limite_Pago).format('l')}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Monto Aprobado :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.valor_Aprobado === null
                                ? 'Pendiente Aprobación'
                                : `$ ${formateador(creditoEditable?.valor_Aprobado)}`}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Contrato :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Firma === null
                                ? 'Pendiente Firmar Contrato'
                                : moment(creditoEditable?.fecha_Firma).format('l')}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Aprobado :
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Aprobado === null
                                ? 'Pendiente Aprobación'
                                : moment(creditoEditable?.fecha_Aprobado).format('l')}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Numero Cuenta:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.cuentaUsuario?.numero_cuenta}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Fecha Cancelacion:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              {creditoEditable?.fecha_Cancelado === null
                                ? 'Sin Cancelar'
                                : moment(creditoEditable?.fecha_Cancelado).format('l')}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Scrollbar>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
