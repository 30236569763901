/* eslint-disable react/prop-types */
import * as React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';

export default function Calificacion({ calificacion }) {
  return (
    <Box
      sx={{
        '& > legend': { mt: 2, mr: 4 },
      }}
    >
      {calificacion !== null ? (
        <>
          <Typography component="legend">Calificación Cliente:</Typography>
          <Rating name="read-only" value={calificacion} readOnly size="large" />
        </>
      ) : (
        <Typography component="legend" color="red">
          Cliente Pendiente por Calificación
        </Typography>
      )}
    </Box>
  );
}
