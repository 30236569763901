/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PATH_NAME, TITLES } from '../constants';
import { CompromisosPorVencer } from '../components/tables';
import { startConsultarCompromisos } from '../store';

export function ListadoCompromisosPorVencer() {
  const location = useLocation();
  const isListadoCompromisosPorVencer = location?.pathname.includes(PATH_NAME.listado_de_compromisos_por_vencer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startConsultarCompromisos());
  }, []);

  return (
    <>
      <Helmet>
        <title> {isListadoCompromisosPorVencer ? TITLES.listado_de_compromisos_por_vencer : TITLES.default} </title>
      </Helmet>

      <CompromisosPorVencer />
    </>
  );
}
