import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import { Icon } from '@iconify/react';
import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { MAIN_PATH, PATH_NAME, TITLES } from '../constants';
import { SideBar } from '../components/sideBar/SideBar';
import { ACCESOS_GESTOR_PAGOS_CREDITOS } from '../constants/permisos';
import { generarInforme } from '../store';

export const GestionarInfoB = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isGestorCreditos = location?.pathname.includes(PATH_NAME.gestionar_credito);
  const { informeEditable } = useSelector((state) => state.contadorInfor);

  const { rol } = useSelector((state) => state.userAdmin.userAdmin);
  const [loading, setLoading] = React.useState(false);

  const [informe, setInforme] = useState();

  const handleDatosChange = (e) => {
    setInforme({
      ...informe,
      [e.target.name]: Number(e.target.value),
    });
  };

  const handleEstadoChange = (e) => {
    setInforme({
      ...informe,
      [e.target.name]: e.target.checked,
    });
  };

  const generarInformeBienestar = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await dispatch(generarInforme(informeEditable?.id, informe));
      navigate(`/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title> {isGestorCreditos ? TITLES.gestionar_credito : TITLES.default} </title>
      </Helmet>
      {loading === true ? (
        <Grid>
          <Grid display="flex" justifyContent="center" alignItems="end">
            <Icon icon="line-md:loading-twotone-loop" color="#2a76d2" width="150" />
          </Grid>
        </Grid>
      ) : (
        <>
          <SideBar showNotas={false} showContrato={false} showRenovarContrato={false} />
          <Box sx={{ display: 'flex' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <form onSubmit={generarInformeBienestar} className="informeB">
                  <Card>
                    <Box
                      sx={{
                        alignItems: 'baseline',

                        margin: '0 auto',
                        maxWidth: 700,
                      }}
                    >
                      <Typography variant="h4" gutterBottom sx={{ margin: 2, textAlign: 'center' }}>
                        Generación de Informe de Bienestar Financiero
                      </Typography>

                      <Typography variant="h6" gutterBottom sx={{ margin: 2 }}>
                        * Cuentas Bancarias
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de cuentas bancarias"
                              name="num_ctas"
                              onChange={handleDatosChange}
                              value={informe?.num_ctas}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Antiguedad de la cuenta"
                              name="time_ctas"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_ctas}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider sx={{ margin: 2 }} />
                      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        * Tarjetas de Crédito
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de Tarjetas de Crédito"
                              name="num_tcs"
                              onChange={handleDatosChange}
                              value={informe?.num_tcs}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            label="Tiene Reporte negativo en TC"
                            control={
                              <Checkbox
                                value={informe?.has_negative_report_tcs}
                                checked={informe?.has_negative_report_tcs}
                                onChange={handleEstadoChange}
                                name="has_negative_report_tcs"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de años con el reporte negativo"
                              name="time_negative_tcs"
                              type="number"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_negative_tcs}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Divider sx={{ margin: 2 }} />
                      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        * Créditos Bancarios
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de Créditos Bancarios"
                              type="number"
                              name="num_credit_banks"
                              onChange={handleDatosChange}
                              value={informe?.num_credit_banks}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            label="Tiene Reporte negativo en Créditos Bancarios"
                            control={
                              <Checkbox
                                name="has_negative_report_credit_banks"
                                onChange={handleEstadoChange}
                                checked={informe?.has_negative_report_credit_banks}
                                value={informe?.has_negative_report_credit_banks}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de años con el reporte negativo"
                              name="time_negative_credit_banks"
                              type="number"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_negative_credit_banks}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Divider sx={{ margin: 2 }} />
                      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        * Créditos en Cooperativa
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de Créditos en Cooperativas"
                              name="num_credit_coo"
                              type="number"
                              onChange={handleDatosChange}
                              value={informe?.num_credit_coo}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            label="Tiene Reporte negativo en Créditos en Cooperativas"
                            control={
                              <Checkbox
                                name="has_negative_report_credit_coo"
                                checked={informe?.has_negative_report_credit_coo}
                                value={informe?.has_negative_report_credit_coo}
                                onChange={handleEstadoChange}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de años con el reporte negativo"
                              name="time_negative_credit_coo"
                              type="number"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_negative_credit_coo}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Divider sx={{ margin: 2 }} />
                      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        * Créditos en Sector Real
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de Créditos en Sector Real"
                              name="num_credit_real"
                              type="number"
                              onChange={handleDatosChange}
                              value={informe?.num_credit_real}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            label="Tiene Reporte negativo en Créditos en Sector Real"
                            control={
                              <Checkbox
                                name="has_negative_report_credit_real"
                                checked={informe?.has_negative_report_credit_real}
                                value={informe?.has_negative_report_credit_real}
                                onChange={handleEstadoChange}
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              label="Numero de años con el reporte negativo"
                              name="time_negative_credit_real"
                              type="number"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_negative_credit_real}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Divider sx={{ margin: 2 }} />
                      <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                        * Información Personal
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Antigüedad en el domicilio"
                              name="time_addresses"
                              type="number"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_addresses}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Antigüedad con el numero de celular"
                              name="time_cellphone"
                              type="number"
                              helperText="Numero en años"
                              onChange={handleDatosChange}
                              value={informe?.time_cellphone}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Numero de Emails"
                              name="num_emails"
                              type="number"
                              onChange={handleDatosChange}
                              value={informe?.num_emails}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth variant="filled">
                            <TextField
                              id="filled-read-only-input"
                              label="Calificación"
                              name="score"
                              type="number"
                              value={informe?.score}
                              onChange={handleDatosChange}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {ACCESOS_GESTOR_PAGOS_CREDITOS.includes(rol) && (
                        <Button
                          fullWidth
                          variant="contained"
                          type="submit"
                          sx={{
                            margin: 4,
                          }}
                        >
                          Actualizar
                        </Button>
                      )}
                    </Box>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
