const mainTitle = 'Easy Credit | ';

export const TITLES = {
  gestor_de_claves: `${mainTitle} Gestor de claves`,
  home_admin: `${mainTitle} Inicio`,
  gestor_de_compromisos: `${mainTitle} Gestor de Compromisos`,
  gestor_de_renovaciones: `${mainTitle} Gestor de Renovaciones`,
  gestor_de_creditos: `${mainTitle} Gestor de Créditos`,
  gestor_de_creditos_atrazados: `${mainTitle} Gestor de Créditos atrasados`,
  gestor_de_datos: `${mainTitle} Gestor de Datos`,
  gestor_de_pagos: `${mainTitle} Gestor de Pagos`,
  gestor_de_pagos_manuales: `${mainTitle} Gestor de Pagos Manuales`,
  listado_de_compromisos: `${mainTitle} Listado de compromisos`,
  listado_de_compromisos_por_vencer: `${mainTitle} Listado de compromisos por vencer`,
  listado_de_compromisos_vencidos: `${mainTitle} Listado de compromisos vencidos`,
  informe_facturacion: `${mainTitle} Informe Facturación`,
  informe_de_gestion: `${mainTitle} Informe de Gestión`,
  informe_historico: `${mainTitle} Informe Historico`,
  informe_de_davivienda: `${mainTitle} Informe para Davivienda`,
  informe_de_dataCredito: `${mainTitle} Informe para Datacredito`,
  gestionar_credito: 'gestionar Credito',

  default: mainTitle,
};
