/* eslint-disable array-callback-return */

import { usuriosAntiguos } from './usuariosAntiguos';
import { clientesNuevos } from './usuariosNuevos';

export const contadorTiles = (total = []) => {
  const antiguos = usuriosAntiguos(total);

  const nuevos = clientesNuevos(total);

  const solicitudesNuevos = nuevos.filter(
    (credito) => credito?.solicitud_Credito === true && credito?.aprobado === null && credito?.preAprobado === null
  );

  const solicitudesAntiguos = antiguos.filter(
    (credito) => credito?.solicitud_Credito === true && credito?.aprobado === null && credito?.preAprobado === null
  );

  const preAprobadosAntiguosSin = antiguos.filter(
    (credito) =>
      (credito?.aprobado === null || credito?.aprobado === undefined) &&
      (credito?.adquirido === null || credito?.adquirido === undefined) &&
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      (credito?.solicitar_Documentos === null || credito?.solicitar_Documentos === undefined) &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true
  );

  const preAprobadosAntiguosCon = antiguos.filter(
    (credito) =>
      credito?.aprobado === null &&
      credito?.adquirido === null &&
      credito?.cancelado === null &&
      credito?.desembolsado === null &&
      credito?.preAprobado === true &&
      credito?.rechazado === null &&
      credito?.solicitar_Documentos === true &&
      credito?.solicitud_Credito === true
  );

  const preAprobadosNuevos = nuevos.filter(
    (credito) =>
      (credito?.aprobado === null || credito?.aprobado === undefined) &&
      (credito?.adquirido === null || credito?.adquirido === undefined) &&
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      (credito?.solicitar_Documentos === null || credito?.solicitar_Documentos === undefined) &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true
  );

  const preAprobadosNuevosConDocumentos = nuevos.filter(
    (credito) =>
      (credito?.aprobado === null || credito?.aprobado === undefined) &&
      (credito?.adquirido === null || credito?.adquirido === undefined) &&
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.preAprobado === true &&
      credito?.solicitar_Documentos === true &&
      credito?.solicitud_Credito === true
  );

  const paraAprobarNuevos = nuevos.filter(
    (credito) =>
      (credito?.aprobado === null || credito?.aprobado === undefined) &&
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.adquirido === true &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true
  );
  const paraAprobarAntiguos = antiguos.filter(
    (credito) =>
      (credito?.aprobado === null || credito?.aprobado === undefined) &&
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.adquirido === true &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true
  );

  const porDesembolsarNuevos = nuevos.filter(
    (credito) =>
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.fecha_Firma !== null &&
      credito?.aprobado === true &&
      credito?.adquirido === true &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true &&
      credito?.solicitud_Renovacion === null
  );

  const porDesembolsarAntiguos = antiguos.filter(
    (credito) =>
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.aprobado === true &&
      credito?.fecha_Firma !== null &&
      credito?.adquirido === true &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true &&
      credito?.solicitud_Renovacion === null
  );

  const renovacionAntiguos = antiguos.filter(
    (credito) =>
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      credito?.desembolsado === true &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.aprobado === true &&
      credito?.fecha_Firma !== null &&
      credito?.adquirido === true &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true &&
      credito?.solicitud_Renovacion === true
  );
  const renovacionNuevos = nuevos.filter(
    (credito) =>
      (credito?.cancelado === null || credito?.cancelado === undefined) &&
      credito?.desembolsado === true &&
      (credito?.rechazado === null || credito?.rechazado === undefined) &&
      credito?.aprobado === true &&
      credito?.fecha_Firma !== null &&
      credito?.adquirido === true &&
      credito?.preAprobado === true &&
      credito?.solicitud_Credito === true &&
      credito?.solicitud_Renovacion === true
  );
  const creditosActivos = total.filter(
    (credito) =>
      credito?.aprobado === true &&
      credito?.adquirido === true &&
      credito?.fecha_Firma !== null &&
      credito?.cancelado === null &&
      credito?.desembolsado === true &&
      credito?.preAprobado === true &&
      credito?.rechazado === null &&
      credito?.solicitud_Credito === true
  );
  //   const creditosVencidos = creditosActivos.filter((credito) => {
  //     const fechaLimite = moment(formatoFecha(credito?.fecha_Desembolsado)).add(credito?.dias_Prestamo - 2, 'd');
  //     const flag = fechaLimite.diff(now, 'days');
  //     if (flag <= 0) {
  //       return credito;
  //     }
  //   });

  return {
    solicitudesNuevos,
    nuevos,
    preAprobadosAntiguosSin,
    preAprobadosNuevos,
    paraAprobarNuevos,
    paraAprobarAntiguos,
    porDesembolsarNuevos,
    porDesembolsarAntiguos,
    creditosActivos,
    renovacionNuevos,
    solicitudesAntiguos,
    renovacionAntiguos,
    preAprobadosAntiguosCon,
    preAprobadosNuevosConDocumentos,
    // creditosVencidos,
  };
};
