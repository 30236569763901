/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import { CssBaseline, Paper } from '@mui/material';
import { useDispatch } from 'react-redux';

import { crearInformacionUsuario } from '../../store';

import { hoy } from '../../helper/funtions';
import InformacionPersonal from '../components/questionario/InformacionPersonal';
import InformacionResidencial from '../components/questionario/InformacionResidencial';
import InformacionFinanciera from '../components/questionario/InformacionFinanciera';
import InformacionLaboral from '../components/questionario/InformacionLaboral';
import InformacionReferencias from '../components/questionario/InformacionReferencias';

const steps = ['Personal - 1', 'Personal - 2', 'Financiera', 'Laboral', 'Referencias'];

export default function EditarQuestionario() {
  const [informacionPersonal, setInformacionPersonal] = useState({
    primerNombre: '',
    segundoNombre: '',
    primerApellido: '',
    segundoApellido: '',
    cedula: '',
    celular: '',
    genero: '',
    nivel_de_estudios: '',
    ciudad_expedicion_cedula: '',
    fecha_de_nacimiento: '',
    fecha_de_expedicion_cedula: '',
    numero_personas_a_cargo: '',
  });

  const [informacionResidencial, setInformacionResidencial] = useState({
    direccion_residencia: '',
    ciudad_residencia: '',
    estrato: '',
    tiempo_en_vivienda: '',
    tipo_vivienda: '',
  });

  const [preguntasEmpleados, setPreguntasEmpleados] = useState({
    nombre_empresa: '',
    cargo: '',
    tipo_contrato: '',
    ciudad_trabajo: '',
    direccion_trabajo: '',
    telefono_trabajo: '',
    fecha_de_ingreso_empresa: '',
    eps: '',
  });

  const [informacionFinanciera, setInformacionFinanciera] = useState({
    banco: '',
    tipo_cuenta: '',
    // tiene_tarjeta: '',
    ingresos_Mensuales: '',
    gastos_Mensuales: '',
    numero_cuenta: '',
  });

  const [PreguntasXIndependientes, setPreguntasXIndependientes] = useState({
    actividad_economica: '',
    nombre_empresa_independiente: '',
    ciudad_empresa_independiente: '',
    direccion_empresa_independiente: '',
    telefono_empresa_independiente: '',
    tiempo_empresa_independiente: '',
  });

  const [preguntasPensionados, setPreguntasPensionados] = useState({
    fecha_resolucion_pension: hoy,
    fondo_pensional: '',
    causa_pension: '',
  });

  const [preguntasDesempleado, setPreguntasDesempleado] = useState({
    tiempo_desempleado: '',
  });

  const [informacionReferencias, setInformacionReferencias] = useState({
    // referencia_familiar_nombres: '',
    // referencia_familiar_apellidos: '',
    // referencia_familiar_ciudad: '',
    // referencia_familiar_telefono: '',
    // referencia_familiar_parentesco: '',
    referencia_personal_nombres: '',
    referencia_personal_apellidos: '',
    referencia_personal_ciudad: '',
    referencia_personal_telefono: '',
  });

  const dispatch = useDispatch();

  const enviar = () => {
    dispatch(
      crearInformacionUsuario({
        ...informacionPersonal,
        ...informacionResidencial,
        ...preguntasEmpleados,
        ...informacionFinanciera,
        ...PreguntasXIndependientes,
        ...PreguntasXIndependientes,
        ...preguntasPensionados,
        ...preguntasDesempleado,
        ...informacionReferencias,
      })
    );
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <InformacionPersonal
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            steps={steps}
            registroInfoPersonal={informacionPersonal}
            setInformacionPersonal={setInformacionPersonal}
          />
        );
      case 1:
        return (
          <InformacionResidencial
            informacionResidencial={informacionResidencial}
            setInformacionResidencial={setInformacionResidencial}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            steps={steps}
            setActiveStep={setActiveStep}
          />
        );
      case 2:
        return (
          <InformacionFinanciera
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            registroInfoFinanciera={informacionFinanciera}
            setInformacionFinanciera={setInformacionFinanciera}
          />
        );
      case 3:
        return (
          <InformacionLaboral
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            preguntasEmpleados={preguntasEmpleados}
            setPreguntasEmpleados={setPreguntasEmpleados}
            PreguntasXIndependientes={PreguntasXIndependientes}
            setPreguntasXIndependientes={setPreguntasXIndependientes}
            preguntasPensionados={preguntasPensionados}
            setPreguntasPensionados={setPreguntasPensionados}
            preguntasDesempleado={preguntasDesempleado}
            setPreguntasDesempleado={setPreguntasDesempleado}
          />
        );
      case 4:
        return (
          <InformacionReferencias
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            steps={steps}
            registroInfoReferencias={informacionReferencias}
            setInformacionReferencias={setInformacionReferencias}
            enviar={enviar}
          />
        );

      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <CssBaseline />

      <main>
        <Paper sx={{ padding: 3 }}>
          <Typography component="h1" variant="h4" align="center">
            Información
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep !== steps.length && <>{getStepContent(activeStep)}</>}
        </Paper>
      </main>
    </>
  );
}
