import React from 'react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DraftsIcon from '@mui/icons-material/Drafts';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Card, CardContent, Typography } from '@mui/material';

const RequisitosResponsive = () => (
  <Card>
    <CardContent className="cajapasos h1">
      <Typography className="tituloporque2">
        <h2>Requisitos para obtener un crédito</h2>

        <p className="requisitotexto1">
          <PeopleAltIcon className="icono" fontSize="large" />
          Ser mayor de edad colombiano residente.
        </p>

        <p className="requisitotexto1">
          <CreditCardIcon className="icono" fontSize="large" />
          Tener cuenta bancaria a tu nombre
        </p>

        <p className="requisitotexto1">
          <DraftsIcon className="icono" fontSize="large" />
          Tener correo propio
        </p>

        <p className="requisitotexto1">
          <PhoneIphoneIcon fontSize="large" className="iconPhone" />
          Tener línea de celular propia
        </p>
        <p className="requisitotexto1">
          <EventAvailableIcon className="icono" fontSize="large" />
          No estar reportado en centrales de riesgo
        </p>
      </Typography>
    </CardContent>
  </Card>
);

export default RequisitosResponsive;
