import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { startConsultarCreditosHistorico } from '../store/historicos';
import { Gestion } from '../components/informes';
import { PATH_NAME, TITLES } from '../constants';

export function InformeGestion() {
  const location = useLocation();
  const dispatch = useDispatch();
  const isInformeGestion = location?.pathname.includes(PATH_NAME.informe_de_gestion);

  dispatch(startConsultarCreditosHistorico());
  return (
    <>
      <Helmet>
        <title> {isInformeGestion ? TITLES.informe_de_gestion : TITLES.default} </title>
      </Helmet>

      <Gestion />
    </>
  );
}
