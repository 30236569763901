/* eslint-disable react/jsx-no-bind */
/* eslint-disable radix */
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useDispatch } from 'react-redux';
import ReplyIcon from '@mui/icons-material/Reply';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { agregarComentarioAlUsuario } from '../../store';

export const FormNotasUsuario = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nota, setNota] = useState({
    nota: '',
    createdAt: moment(),
  });

  const agregarCompromisos = (e) => {
    e.preventDefault();
    if (!nota.nota) {
      Swal.fire({
        title: 'Error!',
        text: 'No hay comentario',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    dispatch(agregarComentarioAlUsuario(nota));
    setNota({
      nota: '',
      createdAt: moment(),
    });
  };

  function handleClick() {
    navigate(-1);
  }

  return (
    <Grid item xs={12} md={4} lg={4}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Card
          component="form"
          onSubmit={agregarCompromisos}
          sx={{ display: 'flex', flexDirection: 'column', padding: 3, height: '424px' }}
        >
          <Typography variant="h5" gutterBottom sx={{ margin: 2 }}>
            Crear Observaciones:
          </Typography>
          <TextField
            onChange={(newValue) => setNota({ ...nota, nota: newValue.target.value })}
            name="nota"
            id="outlined-multiline-static"
            label="Observación"
            multiline
            rows={8}
            value={nota.nota}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              sx={{
                width: '40%',
                marginBottom: 4,
                marginTop: 5,
              }}
              color="warning"
              onClick={handleClick}
              variant="contained"
              type="submit"
              startIcon={<ReplyIcon />}
            >
              Atras
            </Button>
            <Button
              sx={{
                width: '40%',
                marginBottom: 4,
                marginTop: 5,
              }}
              variant="contained"
              type="submit"
            >
              Agregar
            </Button>
          </Box>
        </Card>
      </LocalizationProvider>
    </Grid>
  );
};
