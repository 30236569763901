/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography, Alert, Card } from '@mui/material';
// components
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { consultarUsuario } from '../../store';
import { PATH_NAME, TITLES } from '../../constants';
// sections

import Calculadora from '../components/calculadora/Calculadora';
import { consultarBloqueo, consultarCreditoActivo } from '../../store/clientUser/credito/thunk';
import { MensajeEstadoCredito } from '../components/mensajeEstados/MensajeEstadoCredito';
import {
  aprobadoMensaje,
  enEstudioMensaje,
  firmaContratoMensaje,
  porDesembolsaroMensaje,
  preAprobadoConDocumentosMensaje,
  preAprobadoSinDocumentosMensaje,
} from './MensajeDeEstadoCredito';
import { MensajeEstadoCreditoParaAdquirir } from '../components/mensajeEstados/MensajeEstadoCreditoParaAdquirir';
import { formateador } from '../../helper/Formateador';
import Contador from '../components/Contador/Contador';
import { consultarInforme } from '../../store/clientUser/bienestar';

// ----------------------------------------------------------------------

const ParaAdquirir = (credito) => {
  if (
    credito?.solicitud_Credito === true &&
    credito?.adquirido === null &&
    (credito?.aprobado === null || credito?.aprobado === undefined) &&
    credito?.preAprobado === true &&
    (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
    (credito?.rechazado === null || credito?.rechazado === undefined) &&
    (credito?.solicitar_Documentos === false ||
      credito?.solicitar_Documentos === null ||
      credito?.solicitar_Documentos === undefined) &&
    (credito?.fecha_Firma === null || credito?.fecha_Firma === undefined)
  ) {
    return (
      <MensajeEstadoCreditoParaAdquirir
        titulo={preAprobadoSinDocumentosMensaje.titulo}
        subtitulo={preAprobadoSinDocumentosMensaje.subtitulo}
        mensaje={preAprobadoSinDocumentosMensaje.mensaje}
        monto={formateador(credito?.valor_Aprobado)}
      />
    );
  }
};

const EnEstudio = (credito) => {
  if (
    credito?.solicitud_Credito === true &&
    (credito?.adquirido === null || credito?.adquirido === undefined) &&
    (credito?.preAprobado === null || credito?.preAprobado === undefined) &&
    (credito?.aprobado === null || credito?.aprobado === undefined)
  ) {
    return (
      // <h1>en estudiio</h1>
      <MensajeEstadoCredito titulo={enEstudioMensaje.titulo} subtitulo={enEstudioMensaje.subtitulo} />
    );
  }
};

const preAprobadoConDocumentos = (credito) => {
  if (
    credito?.solicitud_Credito === true &&
    credito?.adquirido === null &&
    (credito?.aprobado === null || credito?.aprobado === undefined) &&
    credito?.preAprobado === true &&
    (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
    (credito?.rechazado === null || credito?.rechazado === undefined) &&
    credito?.solicitar_Documentos === true &&
    (credito?.fecha_Firma === null || credito?.fecha_Firma === undefined)
  ) {
    return (
      <MensajeEstadoCredito
        titulo={preAprobadoConDocumentosMensaje.titulo}
        subtitulo={preAprobadoConDocumentosMensaje.subtitulo}
        mensaje={preAprobadoConDocumentosMensaje.mensaje}
        monto={formateador(credito?.valor_Aprobado)}
      />
    );
  }
};

const AprobadoMensaje = (credito) => {
  if (
    credito?.solicitud_Credito === true &&
    credito?.adquirido === true &&
    (credito?.aprobado === null || credito?.aprobado === undefined) &&
    credito?.preAprobado === true &&
    (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
    (credito?.rechazado === null || credito?.rechazado === undefined) &&
    (credito?.solicitar_Documentos === false ||
      credito?.solicitar_Documentos === null ||
      credito?.solicitar_Documentos === undefined) &&
    (credito?.fecha_Firma === null || credito?.fecha_Firma === undefined)
  ) {
    return (
      <MensajeEstadoCredito
        titulo={aprobadoMensaje.titulo}
        subtitulo={aprobadoMensaje.subtitulo}
        mensaje={aprobadoMensaje.mensaje}
      />
    );
  }
};

const contador = (credito) => {
  if (
    credito?.solicitud_Credito === true &&
    credito?.adquirido === true &&
    credito?.aprobado === true &&
    credito?.preAprobado === true &&
    credito?.desembolsado === true &&
    (credito?.rechazado === null || credito?.rechazado === undefined) &&
    credito?.fecha_Firma !== null
  ) {
    return <Contador />;
  }
};

const FirmaContratoMensaje = (credito) => {
  if (
    credito?.solicitud_Credito === true &&
    credito?.adquirido === true &&
    credito?.aprobado === true &&
    credito?.preAprobado === true &&
    (credito?.desembolsado === null || credito?.desembolsado === undefined) &&
    (credito?.rechazado === null || credito?.rechazado === undefined) &&
    credito?.fecha_Firma === null
  ) {
    return (
      <MensajeEstadoCredito
        titulo={firmaContratoMensaje.titulo}
        subtitulo={firmaContratoMensaje.subtitulo}
        mensaje={firmaContratoMensaje.mensaje}
      />
    );
  }
};

const PorDesembolsaroMensaje = (credito) => {
  if (
    credito?.adquirido === true &&
    credito?.aprobado === true &&
    credito?.desembolsado === null &&
    credito?.fecha_Firma !== null &&
    credito?.preAprobado === true &&
    credito?.rechazado === null &&
    credito?.solicitud_Credito === true
  ) {
    return <MensajeEstadoCredito titulo={porDesembolsaroMensaje.titulo} subtitulo={porDesembolsaroMensaje.subtitulo} />;
  }
};

export default function DashboardAppPageClient() {
  const location = useLocation();
  const cuenta = useSelector((state) => state?.cuentaCL?.cuenta);
  const { creditoActivo, bloqueadoPorRechazo } = useSelector((state) => state?.creditoCL);
  const isAdminHome = location?.pathname.includes(PATH_NAME.home_admin);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(consultarUsuario());
    dispatch(consultarCreditoActivo());
    dispatch(consultarBloqueo());
    dispatch(consultarInforme());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> {isAdminHome ? TITLES.home_admin : TITLES.default} </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hola {cuenta?.primerNombre}, bienvenido de nuevo!
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {creditoActivo?.solicitud_Renovacion === true && (
              <Alert severity="warning">Tu pago esta siendo verificado.</Alert>
            )}
            {bloqueadoPorRechazo !== undefined && bloqueadoPorRechazo && (
              <Card sx={{ width: '50%', margin: '0 auto', padding: 3 }}>
                <Typography sx={{ color: 'red', textAlign: 'center', fontWeight: 600 }}>
                  TU CUENTA ESTA BLOQUEADA POR 60 DÍAS
                </Typography>
              </Card>
            )}
            {creditoActivo === null && bloqueadoPorRechazo === false && <Calculadora />}
            {ParaAdquirir(creditoActivo)}
            {EnEstudio(creditoActivo)}
            {preAprobadoConDocumentos(creditoActivo)}
            {AprobadoMensaje(creditoActivo)}
            {contador(creditoActivo)}
            {FirmaContratoMensaje(creditoActivo)}
            {PorDesembolsaroMensaje(creditoActivo)}
            {/* <MensajeEstadoCredito subtitulo={bloqueadeoMensaje.subtitulo} /> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
