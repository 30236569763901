import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creditoActivo: null,
  bloqueadoPorRechazo: true,
  historicos: [],
};

export const creditoSlice = createSlice({
  name: 'credito',
  initialState,
  reducers: {
    cargarCreditoCL: (state, action) => {
      state.creditoActivo = action.payload;
    },
    actualizarCreditoCL: (state, action) => {
      state.creditoActivo = action.payload;
    },
    consultarHistoricoCL: (state, action) => {
      state.historicos = action.payload;
    },
    consultarBloqueadoPorRechazo: (state, action) => {
      state.bloqueadoPorRechazo = action.payload;
    },
  },
});

export const { cargarCreditoCL, actualizarCreditoCL, consultarHistoricoCL, consultarBloqueadoPorRechazo } =
  creditoSlice.actions;
