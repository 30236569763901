import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { PATH_NAME, TITLES } from '../constants';

import { PagosPorAsentar } from '../components/tables/PagosPorAsentar';

export function GestorPagos() {
  const location = useLocation();
  const isGestorPagos = location?.pathname.includes(PATH_NAME.gestor_de_pagos);
  return (
    <>
      <Helmet>
        <title> {isGestorPagos ? TITLES.gestor_de_pagos : TITLES.default} </title>
      </Helmet>

      <PagosPorAsentar />
    </>
  );
}
