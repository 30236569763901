/* eslint-disable react/prop-types */
import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { formateador } from '../../../helper/Formateador';

export const DatosHistorico = ({ credito, index }) => (
  <TableRow>
    <TableCell>{index + 1}</TableCell>
    <TableCell>C {credito?.id}</TableCell>
    <TableCell>{moment(credito?.fecha_Solicitado).locale('es').format('ll')}</TableCell>
    <TableCell>$ {formateador(credito?.valor_Solicitado || 0)}</TableCell>
    <TableCell>$ {formateador(credito?.valor_Aprobado || 0)}</TableCell>
    <TableCell>{`${credito?.dias_Prestamo} ${credito?.dias_Prestamo === 1 ? 'Día' : 'Dias'}`}</TableCell>
  </TableRow>
);
