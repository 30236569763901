/* eslint-disable camelcase */
import React from 'react';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { formateador } from '../../../helper/Formateador';

export default function CardUser() {
  const { cuenta } = useSelector((state) => state.cuentaCL);
  const credito = useSelector((state) => state.creditoCL.creditoActivo);
  const { censado } = useSelector((state) => state.usuarioCL);

  return (
    <Grid item xs={12} sx={{ margin: 2 }}>
      <Card
        sx={{ minWidth: 275, textAlign: 'center', fontSize: 15, fontFamily: 'raleway', fontWeight: 900 }}
        elevation={3}
      >
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', marginTop: '10px' }}>
            <Avatar
              src="/broken-image.jpg"
              sx={{ width: '80px', height: '80px', border: '3px solid #ffde2e', background: '#fff', color: '#0074bc' }}
            />
          </Box>
          <Typography variant="h5" component="h2" sx={{ fontSize: 20, fontWeight: 600, color: 'rgba(0, 0, 0, 0.54)' }}>
            {cuenta?.primerNombre} {cuenta?.primerApellido}
          </Typography>

          <br />
          <Divider variant="middle" />
          <br />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              marginBottom: '10px',
              width: '100%',
            }}
          >
            <Typography variant="body2" component="p" sx={{ width: '50%', fontSize: 12, marginRight: 5 }}>
              Valor Crédito
            </Typography>
            <Typography
              variant="body2"
              component="p"
              sx={{
                background: '#0074bc',
                color: '#fff',
                padding: ' 2px 15px 2px 20px',
                borderRadius: 10,
                fontSize: 12,
                width: '50%',
              }}
            >
              $ {formateador(credito?.valor_Aprobado)}
            </Typography>
          </Box>

          {!censado || credito?.solicitud_Credito ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline',
                  marginBottom: '10px',
                  width: '100%',
                }}
              >
                <Typography variant="body2" component="p" sx={{ width: '50%', fontSize: 12, marginRight: 5 }}>
                  Tiempo solicitado para el crédito
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  sx={{
                    background: '#0074bc',
                    color: '#fff',
                    padding: ' 2px 15px 2px 20px',
                    borderRadius: 10,
                    fontSize: 12,
                    width: '50%',
                  }}
                >
                  {credito.dias_Prestamo}
                  {credito.dias_Prestamo === 1 ? ' día' : ' días'}
                </Typography>
              </Box>
            </>
          ) : (
            <Link to="/cliente/easy-20credito/h0inicio">Solicita tu crédito</Link>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
