import { useState } from 'react';
import Swal from 'sweetalert2';
// @mui
import {  Stack, IconButton, InputAdornment, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { useDispatch } from 'react-redux';
import Iconify from '../iconify/Iconify';
import { startRegisterAdmin } from '../../store/admin/thunk';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: null,
    password: null,
    rol: null,
    clarisaUser: null,
    nombre: null,
  });





  const handleSubmit = async () => {

    try {
      await dispatch(startRegisterAdmin(data.email, data.password, data.nombre, data.rol, data.clarisaUser));
    } catch (error) {
      Swal.fire('Error', error.toString(), 'error');
    }
  };

  const handelForm = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Stack spacing={3} component="form" onSubmit={handleSubmit}>
        <TextField name="nombre" label="Nombre empleado" onChange={handelForm} />
        <TextField name="email" label="Correo Electronico" onChange={handelForm} />
        <TextField
          onChange={handelForm}
          label="contraseña"
          name="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Usuario Clarisa</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data.clarisaUser}
          label="Usuario Clarisa"
          name='clarisaUser'
          onChange={handelForm}
          sx={{
            marginBottom: 2
          }}
        >
          <MenuItem value>Sí</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </Select>
      </FormControl>
        <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Rol</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data.rol}
          label="Rol"
          name='rol'
          onChange={handelForm}
          sx={{
            marginBottom: 2
          }}
        >
          <MenuItem value={'super'}>super</MenuItem>
          <MenuItem value={'cobro'}>cobro</MenuItem>
        </Select>
      </FormControl>
      </Stack>


      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
        Registrarme
      </LoadingButton>
    </>
  );
}
