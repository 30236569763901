export const ciudadesSelect = [
  { codigo: "11001", nombre: "Bogotá, D.C." },
  { codigo: "05001", nombre: "Medellín" },
  { codigo: "76001", nombre: "Cali" },
  { codigo: "08001", nombre: "Barranquilla" },
  { codigo: "05002", nombre: "Abejorral" },
  { codigo: "05004", nombre: "Abriaquí" },
  { codigo: "54003", nombre: "Abrego" },
  { codigo: "13006", nombre: "Achí" },
  { codigo: "27006", nombre: "Acandí" },
  { codigo: "05021", nombre: "Alejandría" },
  { codigo: "05030", nombre: "Amagá" },
  { codigo: "05031", nombre: "Amalfi" },
  { codigo: "05034", nombre: "Andes" },
  { codigo: "05036", nombre: "Angelópolis" },
  { codigo: "05038", nombre: "Angostura" },
  { codigo: "05040", nombre: "Anorí" },
  { codigo: "05044", nombre: "Anza" },
  { codigo: "05045", nombre: "Apartadó" },
  { codigo: "05051", nombre: "Arboletes" },
  { codigo: "05055", nombre: "Argelia" },
  { codigo: "05059", nombre: "Armenia" },
  { codigo: "13030", nombre: "Altos Del Rosario" },
  { codigo: "13042", nombre: "Arenal" },
  { codigo: "13052", nombre: "Arjona" },
  { codigo: "13062", nombre: "Arroyohondo" },
  { codigo: "15022", nombre: "Almeida" },
  { codigo: "15047", nombre: "Aquitania" },
  { codigo: "15051", nombre: "Arcabuco" },
  { codigo: "18029", nombre: "Albania" },
  { codigo: "20011", nombre: "Aguachica" },
  { codigo: "20013", nombre: "Agustín Codazzi" },
  { codigo: "20032", nombre: "Astrea" },
  { codigo: "23068", nombre: "Ayapel" },
  { codigo: "25035", nombre: "Anapoima" },
  { codigo: "25040", nombre: "Anolaima" },
  { codigo: "25053", nombre: "Arbeláez" },
  { codigo: "25599", nombre: "Apulo" },
  { codigo: "27025", nombre: "Alto Baudó" },
  { codigo: "27050", nombre: "Atrato" },
  { codigo: "41006", nombre: "Acevedo" },
  { codigo: "41013", nombre: "Agrado" },
  { codigo: "41016", nombre: "Aipe" },
  { codigo: "41020", nombre: "Algeciras" },
  { codigo: "41026", nombre: "Altamira" },
  { codigo: "44035", nombre: "Albania" },
  { codigo: "47030", nombre: "Algarrobo" },
  { codigo: "47053", nombre: "Aracataca" },
  { codigo: "47058", nombre: "Ariguaní" },
  { codigo: "54051", nombre: "Arboledas" },
  { codigo: "66045", nombre: "Apía" },
  { codigo: "68013", nombre: "Aguada" },
  { codigo: "68020", nombre: "Albania" },
  { codigo: "68051", nombre: "Aratoca" },
  { codigo: "76020", nombre: "Alcalá" },
  { codigo: "76036", nombre: "Andalucía" },
  { codigo: "76041", nombre: "Ansermanuevo" },
  { codigo: "76054", nombre: "Argelia" },
  { codigo: "85010", nombre: "Aguazul" },
  { codigo: "52019", nombre: "Albán" },
  { codigo: "52022", nombre: "Aldana" },
  { codigo: "52036", nombre: "Ancuyá" },
  { codigo: "52051", nombre: "Arboleda" },
  { codigo: "17013", nombre: "Aguadas" },
  { codigo: "17042", nombre: "Anserma" },
  { codigo: "17050", nombre: "Aranzazu" },
  { codigo: "19022", nombre: "Almaguer" },
  { codigo: "19050", nombre: "Argelia" },
  { codigo: "81001", nombre: "Arauca" },
  { codigo: "81065", nombre: "Arauquita" },
  { codigo: "73024", nombre: "Alpujarra" },
  { codigo: "73026", nombre: "Alvarado" },
  { codigo: "73030", nombre: "Ambalema" },
  { codigo: "73043", nombre: "Anzoátegui" },
  { codigo: "73055", nombre: "Armero" },
  { codigo: "73067", nombre: "Ataco" },
  { codigo: "50006", nombre: "Acacías" },
  { codigo: "05079", nombre: "Barbosa" },
  { codigo: "05086", nombre: "Belmira" },
  { codigo: "05088", nombre: "Bello" },
  { codigo: "05091", nombre: "Betania" },
  { codigo: "05093", nombre: "Betulia" },
  { codigo: "05107", nombre: "Briceño" },
  { codigo: "05113", nombre: "Buriticá" },
  { codigo: "13074", nombre: "Barranco De Loba" },
  { codigo: "15087", nombre: "Belén" },
  { codigo: "15090", nombre: "Berbeo" },
  { codigo: "15092", nombre: "Betéitiva" },
  { codigo: "15097", nombre: "Boavita" },
  { codigo: "15104", nombre: "Boyacá" },
  { codigo: "15106", nombre: "Briceño" },
  { codigo: "15109", nombre: "Buenavista" },
  { codigo: "15114", nombre: "Busbanzá" },
  { codigo: "18094", nombre: "Belén De Los Andaquíes" },
  { codigo: "20045", nombre: "Becerril" },
  { codigo: "20060", nombre: "Bosconia" },
  { codigo: "23079", nombre: "Buenavista" },
  { codigo: "25086", nombre: "Beltrán" },
  { codigo: "25095", nombre: "Bituima" },
  { codigo: "25099", nombre: "Bojacá" },
  { codigo: "27073", nombre: "Bagadó" },
  { codigo: "27075", nombre: "Bahía Solano" },
  { codigo: "27077", nombre: "Bajo Baudó" },
  { codigo: "27086", nombre: "Belén De Bajirá" },
  { codigo: "27099", nombre: "Bojaya" },
  { codigo: "41078", nombre: "Baraya" },
  { codigo: "44078", nombre: "Barrancas" },
  { codigo: "54099", nombre: "Bochalema" },
  { codigo: "54109", nombre: "Bucarasica" },
  { codigo: "66075", nombre: "Balboa" },
  { codigo: "66088", nombre: "Belén De Umbría" },
  { codigo: "68001", nombre: "Bucaramanga" },
  { codigo: "68077", nombre: "Barbosa" },
  { codigo: "68079", nombre: "Barichara" },
  { codigo: "68081", nombre: "Barrancabermeja" },
  { codigo: "68092", nombre: "Betulia" },
  { codigo: "68101", nombre: "Bolívar" },
  { codigo: "76109", nombre: "Buenaventura" },
  { codigo: "76113", nombre: "Bugalagrande" },
  { codigo: "94343", nombre: "Barranco Minas" },
  { codigo: "52079", nombre: "Barbacoas" },
  { codigo: "52083", nombre: "Belén" },
  { codigo: "52110", nombre: "Buesaco" },
  { codigo: "08078", nombre: "Baranoa" },
  { codigo: "17088", nombre: "Belalcázar" },
  { codigo: "19075", nombre: "Balboa" },
  { codigo: "19100", nombre: "Bolívar" },
  { codigo: "19110", nombre: "Buenos Aires" },
  { codigo: "70110", nombre: "Buenavista" },
  { codigo: "50110", nombre: "Barranca De Upía" },
  { codigo: "05120", nombre: "Cáceres" },
  { codigo: "05125", nombre: "Caicedo" },
  { codigo: "05129", nombre: "Caldas" },
  { codigo: "05134", nombre: "Campamento" },
  { codigo: "05138", nombre: "Cañasgordas" },
  { codigo: "05142", nombre: "Caracolí" },
  { codigo: "05145", nombre: "Caramanta" },
  { codigo: "05147", nombre: "Carepa" },
  { codigo: "05150", nombre: "Carolina" },
  { codigo: "05154", nombre: "Caucasia" },
  { codigo: "05172", nombre: "Chigorodó" },
  { codigo: "05190", nombre: "Cisneros" },
  { codigo: "05197", nombre: "Cocorná" },
  { codigo: "05206", nombre: "Concepción" },
  { codigo: "05209", nombre: "Concordia" },
  { codigo: "05212", nombre: "Copacabana" },
  { codigo: "13001", nombre: "Cartagena" },
  { codigo: "13140", nombre: "Calamar" },
  { codigo: "13160", nombre: "Cantagallo" },
  { codigo: "13188", nombre: "Cicuco" },
  { codigo: "13212", nombre: "Córdoba" },
  { codigo: "13222", nombre: "Clemencia" },
  { codigo: "15131", nombre: "Caldas" },
  { codigo: "15135", nombre: "Campohermoso" },
  { codigo: "15162", nombre: "Cerinza" },
  { codigo: "15172", nombre: "Chinavita" },
  { codigo: "15176", nombre: "Chiquinquirá" },
  { codigo: "15180", nombre: "Chiscas" },
  { codigo: "15183", nombre: "Chita" },
  { codigo: "15185", nombre: "Chitaraque" },
  { codigo: "15187", nombre: "Chivatá" },
  { codigo: "15189", nombre: "Ciénega" },
  { codigo: "15204", nombre: "Cómbita" },
  { codigo: "15212", nombre: "Coper" },
  { codigo: "15215", nombre: "Corrales" },
  { codigo: "15218", nombre: "Covarachía" },
  { codigo: "15223", nombre: "Cubará" },
  { codigo: "15224", nombre: "Cucaita" },
  { codigo: "15226", nombre: "Cuítiva" },
  { codigo: "15232", nombre: "Chíquiza" },
  { codigo: "15236", nombre: "Chivor" },
  { codigo: "18150", nombre: "Cartagena Del Chairá" },
  { codigo: "18205", nombre: "Curillo" },
  { codigo: "20175", nombre: "Chimichagua" },
  { codigo: "20178", nombre: "Chiriguaná" },
  { codigo: "20228", nombre: "Curumaní" },
  { codigo: "23090", nombre: "Canalete" },
  { codigo: "23162", nombre: "Cereté" },
  { codigo: "23168", nombre: "Chimá" },
  { codigo: "23182", nombre: "Chinú" },
  { codigo: "23189", nombre: "Ciénaga De Oro" },
  { codigo: "23300", nombre: "Cotorra" },
  { codigo: "25120", nombre: "Cabrera" },
  { codigo: "25123", nombre: "Cachipay" },
  { codigo: "25126", nombre: "Cajicá" },
  { codigo: "25148", nombre: "Caparrapí" },
  { codigo: "25151", nombre: "Caqueza" },
  { codigo: "25154", nombre: "Carmen De Carupa" },
  { codigo: "25168", nombre: "Chaguaní" },
  { codigo: "25175", nombre: "Chía" },
  { codigo: "25178", nombre: "Chipaque" },
  { codigo: "25181", nombre: "Choachí" },
  { codigo: "25183", nombre: "Chocontá" },
  { codigo: "25200", nombre: "Cogua" },
  { codigo: "25214", nombre: "Cota" },
  { codigo: "25224", nombre: "Cucunubá" },
  { codigo: "27150", nombre: "Carmen Del Darien" },
  { codigo: "27160", nombre: "Cértegui" },
  { codigo: "27205", nombre: "Condoto" },
  { codigo: "41132", nombre: "Campoalegre" },
  { codigo: "41206", nombre: "Colombia" },
  { codigo: "47161", nombre: "Cerro San Antonio" },
  { codigo: "47170", nombre: "Chibolo" },
  { codigo: "47189", nombre: "Ciénaga" },
  { codigo: "47205", nombre: "Concordia" },
  { codigo: "54125", nombre: "Cácota" },
  { codigo: "54128", nombre: "Cachirá" },
  { codigo: "54172", nombre: "Chinácota" },
  { codigo: "54174", nombre: "Chitagá" },
  { codigo: "54206", nombre: "Convención" },
  { codigo: "54223", nombre: "Cucutilla" },
  { codigo: "54001", nombre: "Cúcuta" },
  { codigo: "68121", nombre: "Cabrera" },
  { codigo: "68132", nombre: "California" },
  { codigo: "68147", nombre: "Capitanejo" },
  { codigo: "68152", nombre: "Carcasí" },
  { codigo: "68160", nombre: "Cepitá" },
  { codigo: "68162", nombre: "Cerrito" },
  { codigo: "68167", nombre: "Charalá" },
  { codigo: "68169", nombre: "Charta" },
  { codigo: "68176", nombre: "Chima" },
  { codigo: "68179", nombre: "Chipatá" },
  { codigo: "68190", nombre: "Cimitarra" },
  { codigo: "68207", nombre: "Concepción" },
  { codigo: "68209", nombre: "Confines" },
  { codigo: "68211", nombre: "Contratación" },
  { codigo: "68217", nombre: "Coromoro" },
  { codigo: "68229", nombre: "Curití" },
  { codigo: "76122", nombre: "Caicedonia" },
  { codigo: "76126", nombre: "Calima" },
  { codigo: "76130", nombre: "Candelaria" },
  { codigo: "76147", nombre: "Cartago" },
  { codigo: "85015", nombre: "Chameza" },
  { codigo: "86219", nombre: "Colón" },
  { codigo: "94886", nombre: "Cacahual" },
  { codigo: "95015", nombre: "Calamar" },
  { codigo: "97161", nombre: "Caruru" },
  { codigo: "99773", nombre: "Cumaribo" },
  { codigo: "52207", nombre: "Consaca" },
  { codigo: "52210", nombre: "Contadero" },
  { codigo: "52215", nombre: "Córdoba" },
  { codigo: "52224", nombre: "Cuaspud" },
  { codigo: "52227", nombre: "Cumbal" },
  { codigo: "52233", nombre: "Cumbitara" },
  { codigo: "52240", nombre: "Chachagüí" },
  { codigo: "08137", nombre: "Campo De La Cruz" },
  { codigo: "08141", nombre: "Candelaria" },
  { codigo: "17174", nombre: "Chinchiná" },
  { codigo: "19130", nombre: "Cajibío" },
  { codigo: "19137", nombre: "Caldono" },
  { codigo: "19142", nombre: "Caloto" },
  { codigo: "19212", nombre: "Corinto" },
  { codigo: "70124", nombre: "Caimito" },
  { codigo: "70204", nombre: "Coloso" },
  { codigo: "70215", nombre: "Corozal" },
  { codigo: "70221", nombre: "Coveñas" },
  { codigo: "70230", nombre: "Chalán" },
  { codigo: "81220", nombre: "Cravo Norte" },
  { codigo: "73124", nombre: "Cajamarca" },
  { codigo: "73148", nombre: "Carmen De Apicalá" },
  { codigo: "73152", nombre: "Casabianca" },
  { codigo: "73168", nombre: "Chaparral" },
  { codigo: "73200", nombre: "Coello" },
  { codigo: "73217", nombre: "Coyaima" },
  { codigo: "73226", nombre: "Cunday" },
  { codigo: "99773", nombre: "Cumaribo" },
  { codigo: "50124", nombre: "Cabuyaro" },
  { codigo: "50150", nombre: "Castilla La Nueva" },
  { codigo: "50223", nombre: "Cubarral" },
  { codigo: "50226", nombre: "Cumaral" },
  { codigo: "05234", nombre: "Dabeiba" },
  { codigo: "05237", nombre: "Don Matías" },
  { codigo: "15238", nombre: "Duitama" },
  { codigo: "44090", nombre: "Dibulla" },
  { codigo: "44098", nombre: "Distracción" },
  { codigo: "54239", nombre: "Durania" },
  { codigo: "66170", nombre: "Dosquebradas" },
  { codigo: "76233", nombre: "Dagua" },
  { codigo: "73236", nombre: "Dolores" },
  { codigo: "05148", nombre: "El Carmen De Viboral" },
  { codigo: "05240", nombre: "Ebéjico" },
  { codigo: "05250", nombre: "El Bagre" },
  { codigo: "05264", nombre: "Entrerrios" },
  { codigo: "05266", nombre: "Envigado" },
  { codigo: "05697", nombre: "El Santuario" },
  { codigo: "13244", nombre: "El Carmen De Bolívar" },
  { codigo: "13248", nombre: "El Guamo" },
  { codigo: "13268", nombre: "El Peñón" },
  { codigo: "15244", nombre: "El Cocuy" },
  { codigo: "15248", nombre: "El Espino" },
  { codigo: "18247", nombre: "El Doncello" },
  { codigo: "18256", nombre: "El Paujil" },
  { codigo: "20238", nombre: "El Copey" },
  { codigo: "20250", nombre: "El Paso" },
  { codigo: "25245", nombre: "El Colegio" },
  { codigo: "25258", nombre: "El Peñón" },
  { codigo: "25260", nombre: "El Rosal" },
  { codigo: "27245", nombre: "El Carmen De Atrato" },
  { codigo: "27250", nombre: "El Litoral Del San Juan" },
  { codigo: "27135", nombre: "El Cantón Del San Pablo" },
  { codigo: "41244", nombre: "Elías" },
  { codigo: "44110", nombre: "El Molino" },
  { codigo: "47245", nombre: "El Banco" },
  { codigo: "47258", nombre: "El Piñon" },
  { codigo: "47268", nombre: "El Retén" },
  { codigo: "54245", nombre: "El Carmen" },
  { codigo: "54250", nombre: "El Tarra" },
  { codigo: "54261", nombre: "El Zulia" },
  { codigo: "68235", nombre: "El Carmen De Chucurí" },
  { codigo: "68245", nombre: "El Guacamayo" },
  { codigo: "68250", nombre: "El Peñón" },
  { codigo: "68255", nombre: "El Playón" },
  { codigo: "68264", nombre: "Encino" },
  { codigo: "68266", nombre: "Enciso" },
  { codigo: "76243", nombre: "El Águila" },
  { codigo: "76246", nombre: "El Cairo" },
  { codigo: "76248", nombre: "El Cerrito" },
  { codigo: "76250", nombre: "El Dovio" },
  { codigo: "91263", nombre: "El Encanto" },
  { codigo: "95025", nombre: "El Retorno" },
  { codigo: "52254", nombre: "El Peñol" },
  { codigo: "52256", nombre: "El Rosario" },
  { codigo: "52258", nombre: "El Tablón De Gómez" },
  { codigo: "19256", nombre: "El Tambo" },
  { codigo: "70233", nombre: "El Roble" },
  { codigo: "73268", nombre: "Espinal" },
  { codigo: "50245", nombre: "El Calvario" },
  { codigo: "50251", nombre: "El Castillo" },
  { codigo: "50270", nombre: "El Dorado" },
  { codigo: "52250", nombre: "El Charco" },
  { codigo: "05282", nombre: "Fredonia" },
  { codigo: "05284", nombre: "Frontino" },
  { codigo: "15272", nombre: "Firavitoba" },
  { codigo: "15276", nombre: "Floresta" },
  { codigo: "18001", nombre: "Florencia" },
  { codigo: "25269", nombre: "Facatativá" },
  { codigo: "25279", nombre: "Fomeque" },
  { codigo: "25281", nombre: "Fosca" },
  { codigo: "25286", nombre: "Funza" },
  { codigo: "25288", nombre: "Fúquene" },
  { codigo: "25290", nombre: "Fusagasugá" },
  { codigo: "44279", nombre: "Fonseca" },
  { codigo: "47288", nombre: "Fundación" },
  { codigo: "68271", nombre: "Florián" },
  { codigo: "68276", nombre: "Floridablanca" },
  { codigo: "76275", nombre: "Florida" },
  { codigo: "52287", nombre: "Funes" },
  { codigo: "52520", nombre: "Francisco Pizarro" },
  { codigo: "17272", nombre: "Filadelfia" },
  { codigo: "19290", nombre: "Florencia" },
  { codigo: "81300", nombre: "Fortul" },
  { codigo: "73270", nombre: "Falan" },
  { codigo: "73275", nombre: "Flandes" },
  { codigo: "73283", nombre: "Fresno" },
  { codigo: "50287", nombre: "Fuente De Oro" },
  { codigo: "05306", nombre: "Giraldo" },
  { codigo: "05308", nombre: "Girardota" },
  { codigo: "05310", nombre: "Gómez Plata" },
  { codigo: "05313", nombre: "Granada" },
  { codigo: "05315", nombre: "Guadalupe" },
  { codigo: "05318", nombre: "Guarne" },
  { codigo: "05321", nombre: "Guatape" },
  { codigo: "15293", nombre: "Gachantivá" },
  { codigo: "15296", nombre: "Gameza" },
  { codigo: "15299", nombre: "Garagoa" },
  { codigo: "15317", nombre: "Guacamayas" },
  { codigo: "15322", nombre: "Guateque" },
  { codigo: "15325", nombre: "Guayatá" },
  { codigo: "15332", nombre: "Güicán" },
  { codigo: "20295", nombre: "Gamarra" },
  { codigo: "20310", nombre: "González" },
  { codigo: "25293", nombre: "Gachala" },
  { codigo: "25295", nombre: "Gachancipá" },
  { codigo: "25297", nombre: "Gachetá" },
  { codigo: "25299", nombre: "Gama" },
  { codigo: "25307", nombre: "Girardot" },
  { codigo: "25312", nombre: "Granada" },
  { codigo: "25317", nombre: "Guachetá" },
  { codigo: "25320", nombre: "Guaduas" },
  { codigo: "25322", nombre: "Guasca" },
  { codigo: "25324", nombre: "Guataquí" },
  { codigo: "25326", nombre: "Guatavita" },
  { codigo: "25328", nombre: "Guayabal De Siquima" },
  { codigo: "25335", nombre: "Guayabetal" },
  { codigo: "25339", nombre: "Gutiérrez" },
  { codigo: "41298", nombre: "Garzón" },
  { codigo: "41306", nombre: "Gigante" },
  { codigo: "41319", nombre: "Guadalupe" },
  { codigo: "47318", nombre: "Guamal" },
  { codigo: "54313", nombre: "Gramalote" },
  { codigo: "66318", nombre: "Guática" },
  { codigo: "68296", nombre: "Galán" },
  { codigo: "68298", nombre: "Gambita" },
  { codigo: "68307", nombre: "Girón" },
  { codigo: "68318", nombre: "Guaca" },
  { codigo: "68320", nombre: "Guadalupe" },
  { codigo: "68322", nombre: "Guapotá" },
  { codigo: "68324", nombre: "Guavatá" },
  { codigo: "68327", nombre: "Güepsa" },
  { codigo: "76111", nombre: "Guadalajara De Buga" },
  { codigo: "76306", nombre: "Ginebra" },
  { codigo: "76318", nombre: "Guacarí" },
  { codigo: "52317", nombre: "Guachucal" },
  { codigo: "52320", nombre: "Guaitarilla" },
  { codigo: "52323", nombre: "Gualmatán" },
  { codigo: "08296", nombre: "Galapa" },
  { codigo: "19300", nombre: "Guachené" },
  { codigo: "19318", nombre: "Guapi" },
  { codigo: "70235", nombre: "Galeras" },
  { codigo: "70265", nombre: "Guaranda" },
  { codigo: "73319", nombre: "Guamo" },
  { codigo: "50313", nombre: "Granada" },
  { codigo: "50318", nombre: "Guamal" },
  { codigo: "05347", nombre: "Heliconia" },
  { codigo: "05353", nombre: "Hispania" },
  { codigo: "13300", nombre: "Hatillo De Loba" },
  { codigo: "41349", nombre: "Hobo" },
  { codigo: "44378", nombre: "Hato Nuevo" },
  { codigo: "54344", nombre: "Hacarí" },
  { codigo: "54347", nombre: "Herrán" },
  { codigo: "68344", nombre: "Hato" },
  { codigo: "85125", nombre: "Hato Corozal" },
  { codigo: "73347", nombre: "Herveo" },
  { codigo: "73349", nombre: "Honda" },
  { codigo: "05360", nombre: "Itagui" },
  { codigo: "05361", nombre: "Ituango" },
  { codigo: "15362", nombre: "Iza" },
  { codigo: "27361", nombre: "Istmina" },
  { codigo: "41357", nombre: "Iquira" },
  { codigo: "41359", nombre: "Isnos" },
  { codigo: "94001", nombre: "Inírida" },
  { codigo: "52352", nombre: "Iles" },
  { codigo: "52354", nombre: "Imués" },
  { codigo: "52356", nombre: "Ipiales" },
  { codigo: "19355", nombre: "Inzá" },
  { codigo: "73001", nombre: "Ibagué" },
  { codigo: "73352", nombre: "Icononzo" },
  { codigo: "05364", nombre: "Jardín" },
  { codigo: "05368", nombre: "Jericó" },
  { codigo: "15367", nombre: "Jenesano" },
  { codigo: "15368", nombre: "Jericó" },
  { codigo: "25368", nombre: "Jerusalén" },
  { codigo: "25372", nombre: "Junín" },
  { codigo: "27372", nombre: "Juradó" },
  { codigo: "68368", nombre: "Jesús María" },
  { codigo: "68370", nombre: "Jordán" },
  { codigo: "76364", nombre: "Jamundí" },
  { codigo: "08372", nombre: "Juan De Acosta" },
  { codigo: "19364", nombre: "Jambaló" },
  { codigo: "05376", nombre: "La Ceja" },
  { codigo: "05380", nombre: "La Estrella" },
  { codigo: "05390", nombre: "La Pintada" },
  { codigo: "05400", nombre: "La Unión" },
  { codigo: "05411", nombre: "Liborina" },
  { codigo: "15377", nombre: "Labranzagrande" },
  { codigo: "15380", nombre: "La Capilla" },
  { codigo: "15401", nombre: "La Victoria" },
  { codigo: "15403", nombre: "La Uvita" },
  { codigo: "18410", nombre: "La Montañita" },
  { codigo: "20383", nombre: "La Gloria" },
  { codigo: "20400", nombre: "La Jagua De Ibirico" },
  { codigo: "20621", nombre: "La Paz" },
  { codigo: "23350", nombre: "La Apartada" },
  { codigo: "23417", nombre: "Lorica" },
  { codigo: "23419", nombre: "Los Córdobas" },
  { codigo: "25377", nombre: "La Calera" },
  { codigo: "25386", nombre: "La Mesa" },
  { codigo: "25394", nombre: "La Palma" },
  { codigo: "25398", nombre: "La Peña" },
  { codigo: "25402", nombre: "La Vega" },
  { codigo: "25407", nombre: "Lenguazaque" },
  { codigo: "27413", nombre: "Lloró" },
  { codigo: "41378", nombre: "La Argentina" },
  { codigo: "41396", nombre: "La Plata" },
  { codigo: "44420", nombre: "La Jagua Del Pilar" },
  { codigo: "54377", nombre: "Labateca" },
  { codigo: "54385", nombre: "La Esperanza" },
  { codigo: "54398", nombre: "La Playa" },
  { codigo: "54405", nombre: "Los Patios" },
  { codigo: "54418", nombre: "Lourdes" },
  { codigo: "66383", nombre: "La Celia" },
  { codigo: "66400", nombre: "La Virginia" },
  { codigo: "68377", nombre: "La Belleza" },
  { codigo: "68385", nombre: "Landázuri" },
  { codigo: "68397", nombre: "La Paz" },
  { codigo: "68406", nombre: "Lebrija" },
  { codigo: "68418", nombre: "Los Santos" },
  { codigo: "76377", nombre: "La Cumbre" },
  { codigo: "76400", nombre: "La Unión" },
  { codigo: "76403", nombre: "La Victoria" },
  { codigo: "85136", nombre: "La Salina" },
  { codigo: "86573", nombre: "Leguízamo" },
  { codigo: "91001", nombre: "Leticia" },
  { codigo: "91405", nombre: "La Chorrera" },
  { codigo: "91407", nombre: "La Pedrera" },
  { codigo: "91430", nombre: "La Victoria" },
  { codigo: "94885", nombre: "La Guadalupe" },
  { codigo: "99524", nombre: "La Primavera" },
  { codigo: "52378", nombre: "La Cruz" },
  { codigo: "52381", nombre: "La Florida" },
  { codigo: "52385", nombre: "La Llanada" },
  { codigo: "52390", nombre: "La Tola" },
  { codigo: "52399", nombre: "La Unión" },
  { codigo: "52405", nombre: "Leiva" },
  { codigo: "52411", nombre: "Linares" },
  { codigo: "52418", nombre: "Los Andes" },
  { codigo: "08421", nombre: "Luruaco" },
  { codigo: "17380", nombre: "La Dorada" },
  { codigo: "17388", nombre: "La Merced" },
  { codigo: "19392", nombre: "La Sierra" },
  { codigo: "19397", nombre: "La Vega" },
  { codigo: "19418", nombre: "López" },
  { codigo: "70400", nombre: "La Unión" },
  { codigo: "70418", nombre: "Los Palmitos" },
  { codigo: "73408", nombre: "Lérida" },
  { codigo: "73411", nombre: "Líbano" },
  { codigo: "99524", nombre: "La Primavera" },
  { codigo: "50350", nombre: "La Macarena" },
  { codigo: "50400", nombre: "Lejanías" },
  { codigo: "05425", nombre: "Maceo" },
  { codigo: "05440", nombre: "Marinilla" },
  { codigo: "05467", nombre: "Montebello" },
  { codigo: "05475", nombre: "Murindó" },
  { codigo: "05480", nombre: "Mutatá" },
  { codigo: "13430", nombre: "Magangué" },
  { codigo: "13433", nombre: "Mahates" },
  { codigo: "13440", nombre: "Margarita" },
  { codigo: "13442", nombre: "María La Baja" },
  { codigo: "13458", nombre: "Montecristo" },
  { codigo: "13468", nombre: "Mompós" },
  { codigo: "13473", nombre: "Morales" },
  { codigo: "15425", nombre: "Macanal" },
  { codigo: "15442", nombre: "Maripí" },
  { codigo: "15455", nombre: "Miraflores" },
  { codigo: "15464", nombre: "Mongua" },
  { codigo: "15466", nombre: "Monguí" },
  { codigo: "15469", nombre: "Moniquirá" },
  { codigo: "15476", nombre: "Motavita" },
  { codigo: "15480", nombre: "Muzo" },
  { codigo: "18460", nombre: "Milán" },
  { codigo: "18479", nombre: "Morelia" },
  { codigo: "20443", nombre: "Manaure" },
  { codigo: "23001", nombre: "Montería" },
  { codigo: "23464", nombre: "Momil" },
  { codigo: "23466", nombre: "Montelíbano" },
  { codigo: "23500", nombre: "Moñitos" },
  { codigo: "25426", nombre: "Macheta" },
  { codigo: "25430", nombre: "Madrid" },
  { codigo: "25436", nombre: "Manta" },
  { codigo: "25438", nombre: "Medina" },
  { codigo: "25473", nombre: "Mosquera" },
  { codigo: "27425", nombre: "Medio Atrato" },
  { codigo: "27430", nombre: "Medio Baudó" },
  { codigo: "27450", nombre: "Medio San Juan" },
  { codigo: "44430", nombre: "Maicao" },
  { codigo: "44560", nombre: "Manaure" },
  { codigo: "54480", nombre: "Mutiscua" },
  { codigo: "66440", nombre: "Marsella" },
  { codigo: "66456", nombre: "Mistrató" },
  { codigo: "68425", nombre: "Macaravita" },
  { codigo: "68432", nombre: "Málaga" },
  { codigo: "68444", nombre: "Matanza" },
  { codigo: "68464", nombre: "Mogotes" },
  { codigo: "68468", nombre: "Molagavita" },
  { codigo: "85139", nombre: "Maní" },
  { codigo: "85162", nombre: "Monterrey" },
  { codigo: "86001", nombre: "Mocoa" },
  { codigo: "91460", nombre: "Miriti,Paraná" },
  { codigo: "94663", nombre: "Mapiripana" },
  { codigo: "94888", nombre: "Morichal" },
  { codigo: "95200", nombre: "Miraflores" },
  { codigo: "97001", nombre: "Mitú" },
  { codigo: "52427", nombre: "Magüi" },
  { codigo: "52435", nombre: "Mallama" },
  { codigo: "52473", nombre: "Mosquera" },
  { codigo: "08433", nombre: "Malambo" },
  { codigo: "08436", nombre: "Manatí" },
  { codigo: "17001", nombre: "Manizales" },
  { codigo: "17433", nombre: "Manzanares" },
  { codigo: "17442", nombre: "Marmato" },
  { codigo: "17444", nombre: "Marquetalia" },
  { codigo: "17446", nombre: "Marulanda" },
  { codigo: "19450", nombre: "Mercaderes" },
  { codigo: "19455", nombre: "Miranda" },
  { codigo: "19473", nombre: "Morales" },
  { codigo: "70429", nombre: "Majagual" },
  { codigo: "70473", nombre: "Morroa" },
  { codigo: "73443", nombre: "Mariquita" },
  { codigo: "73449", nombre: "Melgar" },
  { codigo: "73461", nombre: "Murillo" },
  { codigo: "50325", nombre: "Mapiripán" },
  { codigo: "50330", nombre: "Mesetas" },
  { codigo: "05101", nombre: "Nombre Bolívar" },
  { codigo: "05483", nombre: "Nariño" },
  { codigo: "05490", nombre: "Necoclí" },
  { codigo: "05495", nombre: "Nechí" },
  { codigo: "15491", nombre: "Nobsa" },
  { codigo: "15494", nombre: "Nuevo Colón" },
  { codigo: "25483", nombre: "Nariño" },
  { codigo: "25486", nombre: "Nemocón" },
  { codigo: "25488", nombre: "Nilo" },
  { codigo: "25489", nombre: "Nimaima" },
  { codigo: "25491", nombre: "Nocaima" },
  { codigo: "27491", nombre: "Nóvita" },
  { codigo: "27495", nombre: "Nuquí" },
  { codigo: "41001", nombre: "Neiva" },
  { codigo: "41483", nombre: "Nátaga" },
  { codigo: "47460", nombre: "Nueva Granada" },
  { codigo: "85225", nombre: "Nunchía" },
  { codigo: "52480", nombre: "Nariño" },
  { codigo: "17486", nombre: "Neira" },
  { codigo: "17495", nombre: "Norcasia" },
  { codigo: "73483", nombre: "Natagaima" },
  { codigo: "05501", nombre: "Olaya" },
  { codigo: "15500", nombre: "Oicatá" },
  { codigo: "15507", nombre: "Otanche" },
  { codigo: "41503", nombre: "Oporapa" },
  { codigo: "54498", nombre: "Ocaña" },
  { codigo: "68498", nombre: "Ocamonte" },
  { codigo: "68500", nombre: "Oiba" },
  { codigo: "68502", nombre: "Onzaga" },
  { codigo: "76497", nombre: "Obando" },
  { codigo: "85230", nombre: "Orocué" },
  { codigo: "86320", nombre: "Orito" },
  { codigo: "52490", nombre: "Olaya Herrera" },
  { codigo: "52506", nombre: "Ospina" },
  { codigo: "70508", nombre: "Ovejas" },
  { codigo: "73504", nombre: "Ortega" },
  { codigo: "05541", nombre: "Peñol" },
  { codigo: "05543", nombre: "Peque" },
  { codigo: "05576", nombre: "Pueblorrico" },
  { codigo: "05579", nombre: "Puerto Berrío" },
  { codigo: "05585", nombre: "Puerto Nare" },
  { codigo: "05591", nombre: "Puerto Triunfo" },
  { codigo: "13549", nombre: "Pinillos" },
  { codigo: "15511", nombre: "Pachavita" },
  { codigo: "15514", nombre: "Páez" },
  { codigo: "15516", nombre: "Paipa" },
  { codigo: "15518", nombre: "Pajarito" },
  { codigo: "15522", nombre: "Panqueba" },
  { codigo: "15531", nombre: "Pauna" },
  { codigo: "15533", nombre: "Paya" },
  { codigo: "15537", nombre: "Paz De Río" },
  { codigo: "15542", nombre: "Pesca" },
  { codigo: "15550", nombre: "Pisba" },
  { codigo: "15572", nombre: "Puerto Boyacá" },
  { codigo: "18592", nombre: "Puerto Rico" },
  { codigo: "20517", nombre: "Pailitas" },
  { codigo: "20550", nombre: "Pelaya" },
  { codigo: "20570", nombre: "Pueblo Bello" },
  { codigo: "23555", nombre: "Planeta Rica" },
  { codigo: "23570", nombre: "Pueblo Nuevo" },
  { codigo: "23574", nombre: "Puerto Escondido" },
  { codigo: "23580", nombre: "Puerto Libertador" },
  { codigo: "23586", nombre: "Purísima" },
  { codigo: "25513", nombre: "Pacho" },
  { codigo: "25518", nombre: "Paime" },
  { codigo: "25524", nombre: "Pandi" },
  { codigo: "25530", nombre: "Paratebueno" },
  { codigo: "25535", nombre: "Pasca" },
  { codigo: "25572", nombre: "Puerto Salgar" },
  { codigo: "25580", nombre: "Pulí" },
  { codigo: "41518", nombre: "Paicol" },
  { codigo: "41524", nombre: "Palermo" },
  { codigo: "41530", nombre: "Palestina" },
  { codigo: "41548", nombre: "Pital" },
  { codigo: "41551", nombre: "Pitalito" },
  { codigo: "47541", nombre: "Pedraza" },
  { codigo: "47545", nombre: "Pijiño Del Carmen" },
  { codigo: "47551", nombre: "Pivijay" },
  { codigo: "47555", nombre: "Plato" },
  { codigo: "47570", nombre: "Puebloviejo" },
  { codigo: "54518", nombre: "Pamplona" },
  { codigo: "54520", nombre: "Pamplonita" },
  { codigo: "54553", nombre: "Puerto Santander" },
  { codigo: "66001", nombre: "Pereira" },
  { codigo: "66572", nombre: "Pueblo Rico" },
  { codigo: "68522", nombre: "Palmar" },
  { codigo: "68524", nombre: "Palmas Del Socorro" },
  { codigo: "68533", nombre: "Páramo" },
  { codigo: "68547", nombre: "Piedecuesta" },
  { codigo: "68549", nombre: "Pinchote" },
  { codigo: "68572", nombre: "Puente Nacional" },
  { codigo: "68573", nombre: "Puerto Parra" },
  { codigo: "68575", nombre: "Puerto Wilches" },
  { codigo: "76520", nombre: "Palmira" },
  { codigo: "76563", nombre: "Pradera" },
  { codigo: "85250", nombre: "Paz De Ariporo" },
  { codigo: "85263", nombre: "Pore" },
  { codigo: "86568", nombre: "Puerto Asís" },
  { codigo: "86569", nombre: "Puerto Caicedo" },
  { codigo: "86571", nombre: "Puerto Guzmán" },
  { codigo: "91530", nombre: "Puerto Alegría" },
  { codigo: "91536", nombre: "Puerto Arica" },
  { codigo: "91540", nombre: "Puerto Nariño" },
  { codigo: "91669", nombre: "Puerto Santander" },
  { codigo: "94884", nombre: "Puerto Colombia" },
  { codigo: "94887", nombre: "Pana Pana" },
  { codigo: "97511", nombre: "Pacoa" },
  { codigo: "97777", nombre: "Papunaua" },
  { codigo: "99001", nombre: "Puerto Carreño" },
  { codigo: "52001", nombre: "Pasto" },
  { codigo: "52540", nombre: "Policarpa" },
  { codigo: "52560", nombre: "Potosí" },
  { codigo: "52565", nombre: "Providencia" },
  { codigo: "52573", nombre: "Puerres" },
  { codigo: "52585", nombre: "Pupiales" },
  { codigo: "08520", nombre: "Palmar De Varela" },
  { codigo: "08549", nombre: "Piojó" },
  { codigo: "08558", nombre: "Polonuevo" },
  { codigo: "08560", nombre: "Ponedera" },
  { codigo: "08573", nombre: "Puerto Colombia" },
  { codigo: "17513", nombre: "Pácora" },
  { codigo: "17524", nombre: "Palestina" },
  { codigo: "17541", nombre: "Pensilvania" },
  { codigo: "19001", nombre: "Popayán" },
  { codigo: "19513", nombre: "Padilla" },
  { codigo: "19517", nombre: "Paez" },
  { codigo: "19532", nombre: "Patía" },
  { codigo: "19533", nombre: "Piamonte" },
  { codigo: "19548", nombre: "Piendamó" },
  { codigo: "19573", nombre: "Puerto Tejada" },
  { codigo: "19585", nombre: "Puracé" },
  { codigo: "70523", nombre: "Palmito" },
  { codigo: "81591", nombre: "Puerto Rondón" },
  { codigo: "88564", nombre: "Providencia" },
  { codigo: "73520", nombre: "Palocabildo" },
  { codigo: "73547", nombre: "Piedras" },
  { codigo: "73555", nombre: "Planadas" },
  { codigo: "73563", nombre: "Prado" },
  { codigo: "73585", nombre: "Purificación" },
  { codigo: "99001", nombre: "Puerto Carreño" },
  { codigo: "50450", nombre: "Puerto Concordia" },
  { codigo: "50568", nombre: "Puerto Gaitán" },
  { codigo: "50573", nombre: "Puerto López" },
  { codigo: "50577", nombre: "Puerto Lleras" },
  { codigo: "50590", nombre: "Puerto Rico" },
  { codigo: "15580", nombre: "Quípama" },
  { codigo: "25592", nombre: "Quebradanegra" },
  { codigo: "25594", nombre: "Quetame" },
  { codigo: "25596", nombre: "Quipile" },
  { codigo: "27001", nombre: "Quibdó" },
  { codigo: "66594", nombre: "Quinchía" },
  { codigo: "05604", nombre: "Remedios" },
  { codigo: "05607", nombre: "Retiro" },
  { codigo: "05615", nombre: "Rionegro" },
  { codigo: "13580", nombre: "Regidor" },
  { codigo: "13600", nombre: "Río Viejo" },
  { codigo: "15599", nombre: "Ramiriquí" },
  { codigo: "15600", nombre: "Ráquira" },
  { codigo: "15621", nombre: "Rondón" },
  { codigo: "20614", nombre: "Río De Oro" },
  { codigo: "25612", nombre: "Ricaurte" },
  { codigo: "27580", nombre: "Río Iró" },
  { codigo: "27600", nombre: "Río Quito" },
  { codigo: "27615", nombre: "Riosucio" },
  { codigo: "41615", nombre: "Rivera" },
  { codigo: "44001", nombre: "Riohacha" },
  { codigo: "47605", nombre: "Remolino" },
  { codigo: "54599", nombre: "Ragonvalia" },
  { codigo: "68615", nombre: "Rionegro" },
  { codigo: "76606", nombre: "Restrepo" },
  { codigo: "76616", nombre: "Riofrío" },
  { codigo: "76622", nombre: "Roldanillo" },
  { codigo: "85279", nombre: "Recetor" },
  { codigo: "52612", nombre: "Ricaurte" },
  { codigo: "52621", nombre: "Roberto Payán" },
  { codigo: "08606", nombre: "Repelón" },
  { codigo: "17614", nombre: "Riosucio" },
  { codigo: "17616", nombre: "Risaralda" },
  { codigo: "19622", nombre: "Rosas" },
  { codigo: "73616", nombre: "Rioblanco" },
  { codigo: "73622", nombre: "Roncesvalles" },
  { codigo: "73624", nombre: "Rovira" },
  { codigo: "50606", nombre: "Restrepo" },
  { codigo: "05042", nombre: "Santafé De Antioquia" },
  { codigo: "05628", nombre: "Sabanalarga" },
  { codigo: "05631", nombre: "Sabaneta" },
  { codigo: "05642", nombre: "Salgar" },
  { codigo: "05647", nombre: "San Andrés De Cuerquía" },
  { codigo: "05649", nombre: "San Carlos" },
  { codigo: "05652", nombre: "San Francisco" },
  { codigo: "05656", nombre: "San Jerónimo" },
  { codigo: "05658", nombre: "San José De La Montaña" },
  { codigo: "05659", nombre: "San Juan De Urabá" },
  { codigo: "05660", nombre: "San Luis" },
  { codigo: "05664", nombre: "San Pedro De Los Milagros" },
  { codigo: "05665", nombre: "San Pedro De Uraba" },
  { codigo: "05667", nombre: "San Rafael" },
  { codigo: "05670", nombre: "San Roque" },
  { codigo: "05674", nombre: "San Vicente" },
  { codigo: "05679", nombre: "Santa Bárbara" },
  { codigo: "05686", nombre: "Santa Rosa De Osos" },
  { codigo: "05690", nombre: "Santo Domingo" },
  { codigo: "05736", nombre: "Segovia" },
  { codigo: "05756", nombre: "Sonson" },
  { codigo: "05761", nombre: "Sopetrán" },
  { codigo: "13620", nombre: "San Cristóbal" },
  { codigo: "13647", nombre: "San Estanislao" },
  { codigo: "13650", nombre: "San Fernando" },
  { codigo: "13654", nombre: "San Jacinto" },
  { codigo: "13655", nombre: "San Jacinto Del Cauca" },
  { codigo: "13657", nombre: "San Juan Nepomuceno" },
  { codigo: "13667", nombre: "San Martín De Loba" },
  { codigo: "13670", nombre: "San Pablo" },
  { codigo: "13673", nombre: "Santa Catalina" },
  { codigo: "13683", nombre: "Santa Rosa" },
  { codigo: "13688", nombre: "Santa Rosa Del Sur" },
  { codigo: "13744", nombre: "Simití" },
  { codigo: "13760", nombre: "Soplaviento" },
  { codigo: "15632", nombre: "Saboyá" },
  { codigo: "15638", nombre: "Sáchica" },
  { codigo: "15646", nombre: "Samacá" },
  { codigo: "15660", nombre: "San Eduardo" },
  { codigo: "15664", nombre: "San José De Pare" },
  { codigo: "15667", nombre: "San Luis De Gaceno" },
  { codigo: "15673", nombre: "San Mateo" },
  { codigo: "15676", nombre: "San Miguel De Sema" },
  { codigo: "15681", nombre: "San Pablo De Borbur" },
  { codigo: "15686", nombre: "Santana" },
  { codigo: "15690", nombre: "Santa María" },
  { codigo: "15693", nombre: "Santa Rosa De Viterbo" },
  { codigo: "15696", nombre: "Santa Sofía" },
  { codigo: "15720", nombre: "Sativanorte" },
  { codigo: "15723", nombre: "Sativasur" },
  { codigo: "15740", nombre: "Siachoque" },
  { codigo: "15753", nombre: "Soatá" },
  { codigo: "15755", nombre: "Socotá" },
  { codigo: "15757", nombre: "Socha" },
  { codigo: "15759", nombre: "Sogamoso" },
  { codigo: "15761", nombre: "Somondoco" },
  { codigo: "15762", nombre: "Sora" },
  { codigo: "15763", nombre: "Sotaquirá" },
  { codigo: "15764", nombre: "Soracá" },
  { codigo: "15774", nombre: "Susacón" },
  { codigo: "15776", nombre: "Sutamarchán" },
  { codigo: "15778", nombre: "Sutatenza" },
  { codigo: "18610", nombre: "San José Del Fragua" },
  { codigo: "18753", nombre: "San Vicente Del Caguán" },
  { codigo: "18756", nombre: "Solano" },
  { codigo: "18785", nombre: "Solita" },
  { codigo: "20710", nombre: "San Alberto" },
  { codigo: "20750", nombre: "San Diego" },
  { codigo: "20770", nombre: "San Martín" },
  { codigo: "23660", nombre: "Sahagún" },
  { codigo: "23670", nombre: "San Andrés Sotavento" },
  { codigo: "23672", nombre: "San Antero" },
  { codigo: "23675", nombre: "San Bernardo Del Viento" },
  { codigo: "23678", nombre: "San Carlos" },
  { codigo: "23682", nombre: "San Pelayo" },
  { codigo: "25645", nombre: "San Antonio Del Tequendama" },
  { codigo: "25649", nombre: "San Bernardo" },
  { codigo: "25653", nombre: "San Cayetano" },
  { codigo: "25658", nombre: "San Francisco" },
  { codigo: "25662", nombre: "San Juan De Río Seco" },
  { codigo: "25718", nombre: "Sasaima" },
  { codigo: "25736", nombre: "Sesquilé" },
  { codigo: "25740", nombre: "Sibaté" },
  { codigo: "25743", nombre: "Silvania" },
  { codigo: "25745", nombre: "Simijaca" },
  { codigo: "25754", nombre: "Soacha" },
  { codigo: "25758", nombre: "Sopó" },
  { codigo: "25769", nombre: "Subachoque" },
  { codigo: "25772", nombre: "Suesca" },
  { codigo: "25777", nombre: "Supatá" },
  { codigo: "25779", nombre: "Susa" },
  { codigo: "25781", nombre: "Sutatausa" },
  { codigo: "27660", nombre: "San José Del Palmar" },
  { codigo: "27745", nombre: "Sipí" },
  { codigo: "41660", nombre: "Saladoblanco" },
  { codigo: "41668", nombre: "San Agustín" },
  { codigo: "41676", nombre: "Santa María" },
  { codigo: "41770", nombre: "Suaza" },
  { codigo: "44650", nombre: "San Juan Del Cesar" },
  { codigo: "47001", nombre: "Santa Marta" },
  { codigo: "47660", nombre: "Sabanas De San Angel" },
  { codigo: "47675", nombre: "Salamina" },
  { codigo: "47692", nombre: "San Sebastián De Buenavista" },
  { codigo: "47703", nombre: "San Zenón" },
  { codigo: "47707", nombre: "Santa Ana" },
  { codigo: "47720", nombre: "Santa Bárbara De Pinto" },
  { codigo: "47745", nombre: "Sitionuevo" },
  { codigo: "54660", nombre: "Salazar" },
  { codigo: "54670", nombre: "San Calixto" },
  { codigo: "54673", nombre: "San Cayetano" },
  { codigo: "54680", nombre: "Santiago" },
  { codigo: "54720", nombre: "Sardinata" },
  { codigo: "54743", nombre: "Silos" },
  { codigo: "66682", nombre: "Santa Rosa De Cabal" },
  { codigo: "66687", nombre: "Santuario" },
  { codigo: "68655", nombre: "Sabana De Torres" },
  { codigo: "68669", nombre: "San Andrés" },
  { codigo: "68673", nombre: "San Benito" },
  { codigo: "68679", nombre: "San Gil" },
  { codigo: "68682", nombre: "San Joaquín" },
  { codigo: "68684", nombre: "San José De Miranda" },
  { codigo: "68686", nombre: "San Miguel" },
  { codigo: "68689", nombre: "San Vicente De Chucurí" },
  { codigo: "68705", nombre: "Santa Bárbara" },
  { codigo: "68720", nombre: "Santa Helena Del Opón" },
  { codigo: "68745", nombre: "Simacota" },
  { codigo: "68755", nombre: "Socorro" },
  { codigo: "68770", nombre: "Suaita" },
  { codigo: "68773", nombre: "Sucre" },
  { codigo: "68780", nombre: "Suratá" },
  { codigo: "76670", nombre: "San Pedro" },
  { codigo: "76736", nombre: "Sevilla" },
  { codigo: "85300", nombre: "Sabanalarga" },
  { codigo: "85315", nombre: "Sácama" },
  { codigo: "85325", nombre: "San Luis De Palenque" },
  { codigo: "86749", nombre: "Sibundoy" },
  { codigo: "86755", nombre: "San Francisco" },
  { codigo: "86757", nombre: "San Miguel" },
  { codigo: "86760", nombre: "Santiago" },
  { codigo: "94883", nombre: "San Felipe" },
  { codigo: "95001", nombre: "San José Del Guaviare" },
  { codigo: "99624", nombre: "Santa Rosalía" },
  { codigo: "52678", nombre: "Samaniego" },
  { codigo: "52683", nombre: "Sandoná" },
  { codigo: "52685", nombre: "San Bernardo" },
  { codigo: "52687", nombre: "San Lorenzo" },
  { codigo: "52693", nombre: "San Pablo" },
  { codigo: "52694", nombre: "San Pedro De Cartago" },
  { codigo: "52696", nombre: "Santa Bárbara" },
  { codigo: "52699", nombre: "Santacruz" },
  { codigo: "52720", nombre: "Sapuyes" },
  { codigo: "52835", nombre: "San Andres De Tumaco" },
  { codigo: "08634", nombre: "Sabanagrande" },
  { codigo: "08638", nombre: "Sabanalarga" },
  { codigo: "08675", nombre: "Santa Lucía" },
  { codigo: "08685", nombre: "Santo Tomás" },
  { codigo: "08758", nombre: "Soledad" },
  { codigo: "08770", nombre: "Suan" },
  { codigo: "17653", nombre: "Salamina" },
  { codigo: "17662", nombre: "Samaná" },
  { codigo: "17665", nombre: "San José" },
  { codigo: "17777", nombre: "Supía" },
  { codigo: "19693", nombre: "San Sebastián" },
  { codigo: "19698", nombre: "Santander De Quilichao" },
  { codigo: "19701", nombre: "Santa Rosa" },
  { codigo: "19743", nombre: "Silvia" },
  { codigo: "19760", nombre: "Sotará" },
  { codigo: "19760", nombre: "Sotara" },
  { codigo: "19780", nombre: "Suárez" },
  { codigo: "19785", nombre: "Sucre" },
  { codigo: "70001", nombre: "Sincelejo" },
  { codigo: "70670", nombre: "Sampués" },
  { codigo: "70678", nombre: "San Benito Abad" },
  { codigo: "70702", nombre: "San Juan De Betulia" },
  { codigo: "70708", nombre: "San Marcos" },
  { codigo: "70713", nombre: "San Onofre" },
  { codigo: "70717", nombre: "San Pedro" },
  { codigo: "70742", nombre: "San Luis De Sincé" },
  { codigo: "70771", nombre: "Sucre" },
  { codigo: "70820", nombre: "Santiago De Tolú" },
  { codigo: "81736", nombre: "Saravena" },
  { codigo: "88001", nombre: "San Andrés" },
  { codigo: "73671", nombre: "Saldaña" },
  { codigo: "73675", nombre: "San Antonio" },
  { codigo: "73678", nombre: "San Luis" },
  { codigo: "73686", nombre: "Santa Isabel" },
  { codigo: "73770", nombre: "Suárez" },
  { codigo: "99624", nombre: "Santa Rosalía" },
  { codigo: "50680", nombre: "San Carlos De Guaroa" },
  { codigo: "50683", nombre: "San Juan De Arama" },
  { codigo: "50686", nombre: "San Juanito" },
  { codigo: "50689", nombre: "San Martín" },
  { codigo: "23815", nombre: "Tuchín" },
  { codigo: "05789", nombre: "Támesis" },
  { codigo: "05790", nombre: "Tarazá" },
  { codigo: "05792", nombre: "Tarso" },
  { codigo: "05809", nombre: "Titiribí" },
  { codigo: "05819", nombre: "Toledo" },
  { codigo: "05837", nombre: "Turbo" },
  { codigo: "13780", nombre: "Talaigua Nuevo" },
  { codigo: "13810", nombre: "Tiquisio" },
  { codigo: "13836", nombre: "Turbaco" },
  { codigo: "13838", nombre: "Turbaná" },
  { codigo: "15001", nombre: "Tunja" },
  { codigo: "15790", nombre: "Tasco" },
  { codigo: "15798", nombre: "Tenza" },
  { codigo: "15804", nombre: "Tibaná" },
  { codigo: "15806", nombre: "Tibasosa" },
  { codigo: "15808", nombre: "Tinjacá" },
  { codigo: "15810", nombre: "Tipacoque" },
  { codigo: "15814", nombre: "Toca" },
  { codigo: "15816", nombre: "Togüí" },
  { codigo: "15820", nombre: "Tópaga" },
  { codigo: "15822", nombre: "Tota" },
  { codigo: "15832", nombre: "Tununguá" },
  { codigo: "15835", nombre: "Turmequé" },
  { codigo: "15837", nombre: "Tuta" },
  { codigo: "15839", nombre: "Tutazá" },
  { codigo: "20787", nombre: "Tamalameque" },
  { codigo: "23807", nombre: "Tierralta" },
  { codigo: "23815", nombre: "Tuchín" },
  { codigo: "25785", nombre: "Tabio" },
  { codigo: "25793", nombre: "Tausa" },
  { codigo: "25797", nombre: "Tena" },
  { codigo: "25799", nombre: "Tenjo" },
  { codigo: "25805", nombre: "Tibacuy" },
  { codigo: "25807", nombre: "Tibirita" },
  { codigo: "25815", nombre: "Tocaima" },
  { codigo: "25817", nombre: "Tocancipá" },
  { codigo: "25823", nombre: "Topaipí" },
  { codigo: "27787", nombre: "Tadó" },
  { codigo: "41791", nombre: "Tarqui" },
  { codigo: "41797", nombre: "Tesalia" },
  { codigo: "41799", nombre: "Tello" },
  { codigo: "41801", nombre: "Teruel" },
  { codigo: "41807", nombre: "Timaná" },
  { codigo: "47798", nombre: "Tenerife" },
  { codigo: "54800", nombre: "Teorama" },
  { codigo: "54810", nombre: "Tibú" },
  { codigo: "54820", nombre: "Toledo" },
  { codigo: "68820", nombre: "Tona" },
  { codigo: "76823", nombre: "Toro" },
  { codigo: "76828", nombre: "Trujillo" },
  { codigo: "76834", nombre: "Tuluá" },
  { codigo: "85400", nombre: "Támara" },
  { codigo: "85410", nombre: "Tauramena" },
  { codigo: "85430", nombre: "Trinidad" },
  { codigo: "91798", nombre: "Tarapacá" },
  { codigo: "97666", nombre: "Taraira" },
  { codigo: "52786", nombre: "Taminango" },
  { codigo: "52788", nombre: "Tangua" },
  { codigo: "52838", nombre: "Túquerres" },
  { codigo: "08832", nombre: "Tubará" },
  { codigo: "19807", nombre: "Timbío" },
  { codigo: "19809", nombre: "Timbiquí" },
  { codigo: "19821", nombre: "Toribio" },
  { codigo: "19824", nombre: "Totoró" },
  { codigo: "70823", nombre: "Tolú Viejo" },
  { codigo: "81794", nombre: "Tame" },
  { codigo: "05842", nombre: "Uramita" },
  { codigo: "05847", nombre: "Urrao" },
  { codigo: "15842", nombre: "Umbita" },
  { codigo: "25839", nombre: "Ubalá" },
  { codigo: "25841", nombre: "Ubaque" },
  { codigo: "25845", nombre: "Une" },
  { codigo: "27800", nombre: "Unguía" },
  { codigo: "27810", nombre: "Unión Panamericana" },
  { codigo: "44847", nombre: "Uribia" },
  { codigo: "44855", nombre: "Urumita" },
  { codigo: "76845", nombre: "Ulloa" },
  { codigo: "08849", nombre: "Usiacurí" },
  { codigo: "50370", nombre: "Uribe" },
  { codigo: "05854", nombre: "Valdivia" },
  { codigo: "05856", nombre: "Valparaíso" },
  { codigo: "05858", nombre: "Vegachí" },
  { codigo: "05861", nombre: "Venecia" },
  { codigo: "05873", nombre: "Vigía Del Fuerte" },
  { codigo: "13873", nombre: "Villanueva" },
  { codigo: "15407", nombre: "Villa De Leyva" },
  { codigo: "15861", nombre: "Ventaquemada" },
  { codigo: "15879", nombre: "Viracachá" },
  { codigo: "18860", nombre: "Valparaíso" },
  { codigo: "20001", nombre: "Valledupar" },
  { codigo: "23855", nombre: "Valencia" },
  { codigo: "25506", nombre: "Venecia" },
  { codigo: "25843", nombre: "Villa De San Diego De Ubate" },
  { codigo: "25862", nombre: "Vergara" },
  { codigo: "25867", nombre: "Vianí" },
  { codigo: "25871", nombre: "Villagómez" },
  { codigo: "25873", nombre: "Villapinzón" },
  { codigo: "25875", nombre: "Villeta" },
  { codigo: "25878", nombre: "Viotá" },
  { codigo: "41872", nombre: "Villavieja" },
  { codigo: "44874", nombre: "Villanueva" },
  { codigo: "54871", nombre: "Villa Caro" },
  { codigo: "54874", nombre: "Villa Del Rosario" },
  { codigo: "68855", nombre: "Valle De San José" },
  { codigo: "68861", nombre: "Vélez" },
  { codigo: "68867", nombre: "Vetas" },
  { codigo: "68872", nombre: "Villanueva" },
  { codigo: "76863", nombre: "Versalles" },
  { codigo: "76869", nombre: "Vijes" },
  { codigo: "85440", nombre: "Villanueva" },
  { codigo: "86865", nombre: "Valle Del Guamuez" },
  { codigo: "86885", nombre: "Villagarzón" },
  { codigo: "17867", nombre: "Victoria" },
  { codigo: "17873", nombre: "Villamaría" },
  { codigo: "17877", nombre: "Viterbo" },
  { codigo: "19845", nombre: "Villa Rica" },
  { codigo: "73854", nombre: "Valle De San Juan" },
  { codigo: "73861", nombre: "Venadillo" },
  { codigo: "73870", nombre: "Villahermosa" },
  { codigo: "73873", nombre: "Villarrica" },
  { codigo: "50001", nombre: "Villavicencio" },
  { codigo: "50711", nombre: "Vista Hermosa" },
  { codigo: "05885", nombre: "Yalí" },
  { codigo: "05887", nombre: "Yarumal" },
  { codigo: "05890", nombre: "Yolombó" },
  { codigo: "05893", nombre: "Yondó" },
  { codigo: "25885", nombre: "Yacopí" },
  { codigo: "41885", nombre: "Yaguará" },
  { codigo: "76890", nombre: "Yotoco" },
  { codigo: "76892", nombre: "Yumbo" },
  { codigo: "85001", nombre: "Yopal" },
  { codigo: "97889", nombre: "Yavaraté" },
  { codigo: "52885", nombre: "Yacuanquer" },
  { codigo: "05895", nombre: "Zaragoza" },
  { codigo: "13894", nombre: "Zambrano" },
  { codigo: "15897", nombre: "Zetaquira" },
  { codigo: "25898", nombre: "Zipacón" },
  { codigo: "25899", nombre: "Zipaquirá" },
  { codigo: "47960", nombre: "Zapayán" },
  { codigo: "47980", nombre: "Zona Bananera" },
  { codigo: "68895", nombre: "Zapatoca" },
  { codigo: "76895", nombre: "Zarzal" }
];
