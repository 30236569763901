import { Helmet } from 'react-helmet-async';

import TableList from '../../../components/table-list';

export function PreAprobadosConDocumentosAntiguos() {
  return (
    <>
      <Helmet>
        <title> Pre-aprobados con documentos de clientes antiguos</title>
      </Helmet>

      <TableList titulo="Clientes Antiguos | Créditos pre-aprobados con documentos " />
    </>
  );
}
