import React, { useState } from 'react';
// import './liquidar.css';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import logo from '../components/logo/logoColor.png';
import img from './efectivo.jpeg';
import { consultarHistoricos } from '../store/clientUser/credito/thunk';

import Epayco from '../client/components/opcionPago/Epayco';
import { formateador } from '../helper/Formateador';
import { solicitudInforme } from '../store/clientUser/bienestar';
// import OpcionPago from '../opcionPago/OpcionPago';

const fecha = new Date();
fecha.toString();

export default function PagoInformeBienestar() {
  const infoPersonal = useSelector((state) => state?.cuentaCL?.cuenta);
  const usuario = useSelector((state) => state.infoUsuario);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    dispatch(consultarHistoricos());
  }, []);
  const { historicos: historico } = useSelector((state) => state.creditoCL);

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);

  const algunoCancelado = historico.some((elemento) => elemento.cancelado === true);

  const costo = algunoCancelado ? 35000 : 70000;
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const solicitar = () => {
    Swal.fire({
      title: '¿Esta seguro de solicitar el estudio Financiero?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, Solicitar!',
      cancelButtonText: 'No, Cancelar',
    }).then((result) => {
      if (result.value) {
        setShow(true);
        dispatch(solicitudInforme());
      }
    });
  };

  return (
    <>
      <Grid container>
        <Card className="rootLiquidar">
          <CardContent>
            <div className="Header">
              <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
                <img src={logo} alt="Logo Easy Credit" className="logo" />
              </Grid>

              <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
                <div className="subHeader">
                  <p className="p">EASY CREDIT COLOMBIA</p>
                  <p className="p">Calle 44 # 79-86 of 207</p>
                  <p className="p">Nit: 901.011.172 </p>
                </div>
              </Grid>

              <Grid item lg={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
                <div className="subHeader">
                  <p className="p"> Medellin, Antioquía, Colombia</p>
                  <strong>
                    <p className="p">Solicitud Informe Bienestar Financiero</p>
                  </strong>
                </div>
              </Grid>
            </div>

            <Divider
              variant="middle"
              className="espacio"
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block', sx: 'block' },
              }}
            />

            <Grid container className="contenedorSubtitulo">
              <Grid item xs={12} sm={12} md={4}>
                <div className="subHeader">
                  <p className="p">
                    Nombre:
                    {` ${infoPersonal?.primerNombre} ${infoPersonal?.primerApellido}`}
                  </p>
                  <p className="p"> Cédula: {infoPersonal?.cedula}</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div style={{ textAlign: 'right' }}>
                  <Typography variant="body1" style={{ fontStyle: 'italic' }}>
                    Recibirás un puntaje similar al de Datacredito, no se compartirá información privada como números de
                    cuenta o detalles de deudas. En lugar de eso, recibirás recomendaciones personalizadas para mejorar
                    tu puntaje y lograr una mejor salud financiera, basadas en tu situación actual.
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Divider
              variant="middle"
              className="espacio"
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block', sx: 'block' },
              }}
            />

            <Grid item xs={12} sm={12} md={4}>
              <div className="subHeader">
                <p className="p">Costo: {formateador(costo)}</p>
              </div>
            </Grid>

            <Grid container className="contenedor">
              <Grid
                container
                sx={{
                  display: 'flex',
                  marginTop: 3,
                  textAlign: 'center',
                }}
              >
                {!show ? (
                  <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
                    <Button variant="contained" color="primary" onClick={solicitar} sx={{ width: '150px' }}>
                      Solicitar Estudio Financiero
                    </Button>
                  </Grid>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
                      <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ width: '150px' }}>
                        Efectivo
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} sx={{ marginY: 2 }}>
                      <Epayco
                        abono={costo}
                        cuotaId={`InformeBienestarFinanciero ${usuario?.cedula}`}
                        celular={usuario?.celular}
                        nombre={`${usuario?.primerNombre} ${usuario?.primerApellido}`}
                        direccionResidencia={usuario?.direccion_residencia}
                        monto={costo}
                        descripcion={`Informe Bienestar Financiero`}
                        cedula={`Cedula: ${usuario?.cedula}`}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>

          <Divider variant="middle" className="espacio" />
          <Grid container>
            <Grid xs={12} sm={12} md={4}>
              <CardActions className="botones">
                <Grid direction="row" justify="center" alignItems="center">
                  <Helmet>
                    <script src="https://checkout.epayco.co/checkout.js" />
                  </Helmet>
                  <Helmet>
                    <script src="https://checkout.epayco.co/checkout.js?version=1598838997349?version=1598838997397" />
                  </Helmet>
                </Grid>
              </CardActions>
            </Grid>

            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block', sx: 'block' } }}>
              <CardContent className="contenedorfooter">
                <div className="subFooter">
                  <p className="pPrestamo">IVA Régimen Común:</p>
                  <p className="pPrestamoDescripcion">
                    Resolución de Facturación 18764033523899, desde 3,645 hasta 5,000:
                  </p>
                  <p className="pPrestamoDescripcion">No somos grandes contribuyentes:</p>
                  <p className="pPrestamoDescripcion">No somos autorretenedores de IVA:</p>
                </div>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Vas a pagar en Efectivo?'}</DialogTitle>
        <DialogContent>
          <img src={img} alt="Logo Easy Credit" />
          <DialogContentText>
            Recuerda escribirnos al WhatsApp para, <strong>REGISTRAR tu PAGO</strong>.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
