import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

export default function SimplePaper() {
  const classes = {};
  const { cuenta } = useSelector((state) => state?.cuentaCL);
  const correo = useSelector((state) => state.usuarioCL?.email);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '20px',
        fontSize: '15px',
        fontFamily: 'raleway',
        fontWeight: 900,
      }}
    >
      <Grid item xs={12}>
        <Card elevation={3} sx={{ width: '100% !important', backgroundColor: '#ffdf00' }}>
          <CardContent>
            <Typography
              variant="h5"
              component="h2"
              sx={{
                marginTop: '15px',
              }}
            >
              Información
              <EditIcon className={classes.editIcon} sx={{ marginLeft: '10px' }} />
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                marginTop: '15px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '4px',
                paddingLeft: '8px',
              }}
            >
              Nombre: {cuenta.primerNombre} {cuenta.primerApellido}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                marginTop: '15px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '4px',
                paddingLeft: '8px',
              }}
            >
              Indentificación: {cuenta.cedula}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                marginTop: '15px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '4px',
                paddingLeft: '8px',
              }}
            >
              Celular: {cuenta.celular}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              sx={{
                marginTop: '15px',
                backgroundColor: 'white',
                borderRadius: '10px',
                padding: '4px',
                paddingLeft: '8px',
              }}
            >
              Correo Electronico: {correo}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
}
