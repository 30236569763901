// component

import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { MAIN_PATH, PATH_NAME } from '../../../constants';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Inicio',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.home_admin}`,
    icon: <Iconify icon={'carbon:dashboard-reference'} width={1} height={1} />,
  },
  {
    title: 'Gestor de Créditos',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.gestor_de_creditos}`,
    icon: <Iconify icon={'fluent:wallet-credit-card-32-regular'} width={1} height={1} />,
  },
  {
    title: 'Gestor de datos',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.gestor_de_datos}`,
    icon: <Iconify icon={'eos-icons:data-scientist-outlined'} width={1} height={1} />,
  },
  {
    title: 'Gestor de Claves',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.gestor_de_clave}`,
    icon: <Iconify icon={'arcticons:avast-passwords'} width={1} height={1} />,
  },
  {
    title: 'Gestor de Créditos Atrasados',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.gestor_de_creditos_atrazados}`,
    icon: <Iconify icon={'wpf:past'} width={1} height={1} />,
  },
  // {
  //   title: 'Gestor de Renovaciones',
  //   path: `/${MAIN_PATH}/${PATH_NAME.gestor_de_renovaciones}`,
  //   icon: <Iconify icon={'line-md:uploading-loop'} width={1} height={1} />,
  //
  // },
  {
    title: 'Gestor de Pagos',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.gestor_de_pagos}`,
    icon: <Iconify icon={'material-symbols:payments-outline-rounded'} width={1} height={1} />,
  },
  // {
  //   title: 'Gestor de Compromisos',
  //   path: `/admin/${MAIN_PATH}/${PATH_NAME.gestor_de_compromisos}`,
  //   icon: <Iconify icon={'fluent-mdl2:reservation-orders'} width={1} height={1} />,
  // },
  {
    title: 'Gestor Bienestar Financiero',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.informe_bienestar_financiero}`,
    icon: <Iconify icon={'mdi:report-bell-curve-cumulative'} width={1} height={1} />,
  },
  {
    title: 'Listado Informe Bienestar Financiero',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.listado_informe_bienestar_financiero}`,
    icon: <Iconify icon={'material-symbols:format-list-bulleted-rounded'} width={1} height={1} />,
  },
  // {
  //   title: 'Gestor de Pagos Manuales',
  //   path: `/${MAIN_PATH}/${PATH_NAME.gestor_de_pagos_manuales}`,
  //   icon: <Iconify icon={'arcticons:writeilypro'} width={1} height={1} />,
  //
  // },
  // {
  //   title: 'Listado de compromisos',
  //   path: `/admin/${MAIN_PATH}/${PATH_NAME.listado_de_compromisos}`,
  //   icon: <Iconify icon={'material-symbols:format-list-bulleted-rounded'} width={1} height={1} />,
  // },
  // {
  //   title: 'Listado de compromisos por vencer',
  //   path: `/admin/${MAIN_PATH}/${PATH_NAME.listado_de_compromisos_por_vencer}`,
  //   icon: <Iconify icon={'cil:list-low-priority'} width={1} height={1} />,
  // },
  // {
  //   title: 'Listado de compromisos vencidos',
  //   path: `/admin/${MAIN_PATH}/${PATH_NAME.listado_de_compromisos_vencidos}`,
  //   icon: <Iconify icon={'cil:list-high-priority'} width={1} height={1} />,
  // },
  {
    title: 'Informe de Facturación',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.informe_facturacion}`,
    icon: <Iconify icon={'fluent:clipboard-task-list-rtl-20-regular'} width={1} height={1} />,
  },
  {
    title: 'Informe de Gestión',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.informe_de_gestion}`,
    icon: <Iconify icon={'fluent:people-list-16-regular'} width={1} height={1} />,
  },
  {
    title: 'Informe Histórico',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.informe_historico}`,
    icon: <Iconify icon={'fluent-mdl2:favorite-list'} width={1} height={1} />,
  },
  {
    title: 'Informe Davivienda',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.informe_de_davivienda}`,
    icon: <Iconify icon={'bxs:bank'} width={1} height={1} />,
  },
  {
    title: 'Informe Datacredito',
    path: `/admin/${MAIN_PATH}/${PATH_NAME.informe_de_dataCredito}`,
    icon: <ReportProblemIcon />,
  },
];

export default navConfig;
