/* eslint-disable react/prop-types */
import React from 'react';

import { FormControl, Grid, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ComoEmpleado = ({ setEmpleado, clienteInfo }) => {
  const classes = {};

  const handleFechaIngresoEmpresa = (e) => {
    setEmpleado((anterior) => ({
      ...anterior,
      fecha_de_ingreso_empresa: e,
    }));
  };

  const handleDatosChange = (e) => {
    setEmpleado((anterior) => ({
      ...anterior,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Nombres de la Empresa"
            variant="filled"
            name="nombre_empresa"
            value={clienteInfo?.nombre_empresa}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Nit"
            variant="filled"
            name="nit"
            value={clienteInfo?.nit}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Ciudad donde se encuenta ubicada la empresa"
            variant="filled"
            name="ciudad_trabajo"
            value={clienteInfo?.ciudad_trabajo}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Direccion de la empresa"
            variant="filled"
            name="direccion_trabajo"
            value={clienteInfo?.direccion_trabajo}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Telefono de la empresa"
            variant="filled"
            name="telefono_trabajo"
            value={clienteInfo?.telefono_trabajo}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Tipo de Contrato"
            variant="filled"
            name="tipo_contrato"
            value={clienteInfo?.tipo_contrato}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="Cargo"
            variant="filled"
            name="cargo"
            value={clienteInfo?.cargo}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl fullWidth className={classes.margin} variant="filled">
          <TextField
            id="filled-read-only-input"
            label="EPS"
            variant="filled"
            name="eps"
            value={clienteInfo?.eps}
            onChange={handleDatosChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={6} className={classes.form}>
        <FormControl className={classes.margin} variant="filled">
          <div className={classes.date}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Fecha de Ingreso"
                name="fecha_de_ingreso_empresa"
                value={clienteInfo?.fecha_de_ingreso_empresa}
                onChange={handleFechaIngresoEmpresa}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ComoEmpleado;
