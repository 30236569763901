/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const credito = useSelector((state) => state.creditoCL.creditoActivo);
  const { title, path, icon, info } = item;

  const isDisabled = (title, credito) => {
    if (title === 'Inicio') return true;
    if (title === 'Historico Créditos') return true;
    if (title === 'Cursos') return true;
    if (title === 'Bienestar Financiero') return true;
    if (title === 'Contrato' && credito?.aprobado === true) return true;
    if (title === 'Liquidar Credito' && credito?.desembolsado === true) return true;
    if (title === 'Renovar Credito' && credito?.desembolsado === true) return true;
    if (title === 'Compromisos' && credito?.compromisoCreditos?.length > 0) return true;
    return false;
  };

  useEffect(() => {
    isDisabled();
  }, [credito]);
  const isNew = title === 'Cursos' || title === 'Bienestar Financiero';
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        ...(isNew && {
          '&::after': {
            content: '"Nuevo"',
            position: 'absolute',
            top: '50%',
            right: '0',
            transform: 'translateY(-50%)',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            color: '#333',
            bgcolor: 'primary.light',
            borderRadius: '50%',
            padding: '0.25em 0.5em',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          },
        }),
      }}
      disabled={!isDisabled(title, credito)}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
