/* eslint-disable react/jsx-no-bind */
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReplyIcon from '@mui/icons-material/Reply';
import { Button } from '@mui/material';
import { PATH_NAME, TITLES } from '../../constants';
import { coleccionCreditos } from '../../helper/coleccionCreditos';

import TableList from '../../components/table-list';

export function InformeDesembolsados() {
  const location = useLocation();
  const { data, desde, hasta } = useSelector((state) => state.historicos.informe);
  const isGestorPagos = location?.pathname.includes(PATH_NAME.gestor_de_pagos);

  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }

  const datos = coleccionCreditos(data.dataDesembolsados);

  const columnas = [
    {
      label: 'Nombre',
      name: 'nombre',
      options: {
        filter: true,
      },
    },
    {
      label: 'Cedula',
      name: 'cedula',
      options: {
        filter: true,
      },
    },
    {
      label: 'N. Solicitud',
      name: 'id',
      options: {
        filter: true,
      },
    },
    {
      label: 'Monto Aprobado',
      name: 'valorSolicitado',
      options: {
        filter: true,
      },
    },
    {
      label: 'Fecha Desembolsado',
      name: 'fechaDesembolsados',
      options: {
        filter: true,
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title> {isGestorPagos ? TITLES.gestor_de_pagos : TITLES.default} </title>
      </Helmet>

      <TableList
        titulo={`Informe Créditos Desembolsados desde ${moment(desde).format('l')} hasta ${moment(hasta).format('l')}`}
        columnas={columnas}
        data={datos}
      />
      <Button variant="contained" color="warning" sx={{ margin: 3 }} onClick={handleClick} startIcon={<ReplyIcon />}>
        Atras
      </Button>
    </>
  );
}
