import {  useRoutes } from 'react-router-dom';
import LoginPageAdmin from '../../../components/auth/login/LoginPageAdmin';


import RegisterPageAdmin from '../../../components/auth/RegisterPageAdmin';


// ----------------------------------------------------------------------

export default function AdminPublicRoutes() {
  const routes = useRoutes([
    {
      path: 'registro',
      element: <RegisterPageAdmin />,
    },
    {
      path: 'login',
      element: <LoginPageAdmin />,
    },
  ]);

  return routes;
}
// ea-auth-sy/registro