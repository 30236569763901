import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creditos: [],
  loading: true,
  informe: [],
  historicoClienteEditable: null,
  loadingHistoricoClienteEditable: true,
};

export const historicosSlice = createSlice({
  name: 'historicos',
  initialState,
  reducers: {
    cargarHistoricos: (state, action) => {
      state.creditos = action.payload;
      state.loading = false;
    },
    cargarInforme: (state, action) => {
      state.informe = action.payload;
    },
    cargarHistoricoClienteEditable: (state, action) => {
      state.historicoClienteEditable = action.payload;
      state.loadingHistoricoClienteEditable = false;
    },
  },
});

export const { cargarHistoricos, cargarInforme, cargarHistoricoClienteEditable } = historicosSlice.actions;
