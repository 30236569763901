import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { PATH_NAME, TITLES } from '../constants';

import TableList from '../components/table-list';

export function GestorPagosManuales() {
  const location = useLocation();
  const isGestorPagosManuales = location?.pathname.includes(PATH_NAME.gestor_de_pagos_manuales);
  return (
    <>
      <Helmet>
        <title> {isGestorPagosManuales ? TITLES.gestor_de_pagos_manuales : TITLES.default} </title>
      </Helmet>

      <TableList titulo="Gestión de Pagos Manuales" />
    </>
  );
}
