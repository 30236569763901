/* eslint-disable camelcase */
import Swal from 'sweetalert2';
import clienteAxios from '../../../helper/axios';
import { cargarInformeCL } from './bienestarSlice';

export const solicitudInforme = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.post(
      'credito/informe',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );
    Swal.fire(respuesta.data.mensaje, 'Permanece atento a tu aplicación', respuesta.data.success);
    dispatch(cargarInformeCL(respuesta.data.data));
  } catch (error) {
    console.log('Error:', error);
    throw Error(error?.response?.data?.msg);
  }
};

export const consultarInforme = () => async (dispatch) => {
  try {
    const respuesta = await clienteAxios.get(
      'credito/informe',

      {
        headers: {
          'Content-Type': 'application/json',
          'x-token': localStorage.getItem('token') || '',
        },
      }
    );

    dispatch(cargarInformeCL(respuesta.data.data.reporte));
  } catch (error) {
    console.log('Error:  ', error);
    throw Error(error?.response?.data?.msg);
  }
};
