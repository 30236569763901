/* eslint-disable react/prop-types */
import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Swal from 'sweetalert2';

export const Empleado = ({ preguntasEmpleados, setPreguntasEmpleados, activeStep, setActiveStep, steps }) => {
  const handleNext = (e) => {
    e.preventDefault();

    if (
      (preguntasEmpleados.nombre_empresa === null,
      preguntasEmpleados.tipo_contrato === null,
      preguntasEmpleados.ciudad_trabajo === null,
      preguntasEmpleados.cargo === null,
      // preguntasEmpleados.telefono_trabajo === null,
      preguntasEmpleados.direccion_trabajo === null,
      // preguntasEmpleados.fecha_de_ingreso_empresa === null,
      preguntasEmpleados.eps === null)
    ) {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      return;
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const setInformacion = ({ target }) => {
    setPreguntasEmpleados({
      ...preguntasEmpleados,
      [target.name]: target.value,
    });
  };
  return (
    <div className="animate__animated animate__fadeIn">
      <Typography variant="h6" component="h2">
        Informacion Laboral de empleados
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            name="nombre_empresa"
            value={preguntasEmpleados.nombre_empresa}
            label="Nombre de la Empresa"
            fullWidth
            onChange={setInformacion}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField name="cargo" value={preguntasEmpleados.cargo} label="Cargo" fullWidth onChange={setInformacion} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            type="text"
            name="tipo_contrato"
            value={preguntasEmpleados.tipo_contrato}
            onChange={setInformacion}
            label="Tipo de Contrato"
            select
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          >
            <MenuItem value="indefinido">Indefinido</MenuItem>
            <MenuItem value="fijo">Fijo</MenuItem>
            <MenuItem value="porServicios">Prestacion de Servicios</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="ciudad_trabajo"
            value={preguntasEmpleados.ciudad_trabajo}
            label="Ciudad donde Trabaja"
            fullWidth
            onChange={setInformacion}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <TextField
            name="telefono_trabajo"
            value={preguntasEmpleados.telefono_trabajo}
            label="Telefono donde Trabaja"
            fullWidth
            type="number"
            onChange={setInformacion}
          />
        </Grid> */}
        <Grid item xs={12} md={4}>
          <TextField
            name="direccion_trabajo"
            value={preguntasEmpleados.direccion_trabajo}
            label="Dirección donde Trabaja"
            fullWidth
            onChange={setInformacion}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <DemoContainer components={['DatePicker', 'DatePicker']} sx={{ padding: 0 }}>
            <DatePicker
              name="fecha_de_ingreso_empresa"
              value={preguntasEmpleados.fecha_de_ingreso_empresa}
              label="Fecha de Ingreso"
              fullWidth
              sx={{ width: '100%' }}
              onChange={(newValue) =>
                setPreguntasEmpleados({
                  ...preguntasEmpleados,
                  fecha_de_ingreso_empresa: newValue,
                })
              }
            />
          </DemoContainer>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <TextField name="eps" label="EPS" value={preguntasEmpleados.eps} fullWidth onChange={setInformacion} />
        </Grid>
      </Grid>
      <Box sx={{ margin: 2 }}>
        {activeStep !== 0 && (
          <Button sx={{ marginRight: 2 }} onClick={handleBack}>
            Anterior
          </Button>
        )}
        <Button type="submit" variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Guardar' : 'Siguiente'}
        </Button>
      </Box>
    </div>
  );
};
